import React, { useRef,useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Assets from "../../assets/images";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import $ from "jquery";
import { Constant } from "../../Constants";

function BannerForm() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const url = window.location.href;
    function handleKeyPress(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    }
  
    function handleChange(event) {
      setPhoneNumber(event.target.value);
    }
  const location = useLocation();

  const currentUrl = location.pathname;
const form = useRef();
const RedirectUrl = function(url){
  window.location.href = "/thankyou";
}
const sendEmail = (e) => {
  e.preventDefault();
  $("button.btnPrimary"). attr("disabled", true);
  emailjs.sendForm('service_mpjgvt5', 'template_jw2zrfk', form.current, '960vdqz35TukiHxIX')
    .then((result) => {
     
      $("button.btnPrimary"). attr("disabled", false);
      $('.formFields input,.formFields textarea').val('');
      // Swal.fire({
      //  icon: "success",
      //  title: "We know the world is full of choices. Thank you for choosing us! ",
      //  text : `Be the author of the next best-selling book! {Constant.CompanyName}, just a click away!`
      // })
      RedirectUrl();
    }, (error) => {
     
      $("button.btnPrimary"). attr("disabled", false);
      Swal.fire({
        icon: "error",
        title: "Ooops, something went wrong",
        text: error.text,
      })
    });
};
  return (
    <div className="BannerformWrap">
        <div className="bannerTitle">
            <span>Free Expert Advice</span>
        </div>
        <div className="bannerFormWrap">
            <div className="bannerWraptile">
                <span className="sepTitle">Exclusive Discount</span>
                <span className="offertitle">50% OFF On Our Services</span>
                <div className="BannerFields">
                <form ref={form} onSubmit={sendEmail}>
                    <div className="formFields">
                    <img src={Assets.nameicon} alt="Icon" />
                      <input type="text" placeholder="Enter Your Name" name='user_name' required/>
                    </div>
                    <div className="formFields">
                    <img src={Assets.emailicon} alt="Icon" />
                      <input type="email" placeholder="Enter Your Email" name='user_email' required/>
                    </div>
                    <div className="formFields">
                    <img src={Assets.phoneicon} alt="Icon" />
                      <input type="text" placeholder="Your Phone Number" name='user_phone' value={phoneNumber}
      onKeyPress={handleKeyPress}
      onChange={handleChange} required/>
                    </div>
                    <div className="formFields FieldButton">
              <button type="submit" className="btnPrimary">
                <span>
                  Submit <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>      
            </div>
            <div className="hidden-fields">
                  <input type="hidden" name="fullpageurl" value={url} />
									<input type="hidden" name="companyinfo" value={Constant.CompanyName} />
								</div>
                    </form>
            </div>
            </div>
        </div>
    </div>
  );
}

export default BannerForm;
