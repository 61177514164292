import React from "react";
import { useState, useEffect } from "react";
import Assets from "../../assets/images";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faChevronDown,
  faPhone,
  faLongArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../../Constants";
import { Link } from "react-router-dom";
import $ from "jquery";


function Header({ showInnerBanner = false, children = <></>,className = "ghostbanner" }) {
  var schemalogo = '{"@context": "https://schema.org", "@type": "Organization", "url": "https://bellevuepublishers.com/", "logo": "../../assets/images/android-chrome-256x256.png" }';
  const [navClick, setNavClick] = useState();
  useEffect(() => {
    $('.chat, .chatt').click(function () {
      javascript:void(window.Tawk_API.toggle())
  });
  },[]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navClick]);
  useEffect(() => {
    $(".openDropdown").on("click", function () {
      $('ul.dropDown').hide();
      $(this).prev().show();
    });
    $(".mobileMenuIcon").on("click", function () {
      $('.headerMenuMain').addClass('showMenu');
    });
    $(".headerMenuWrap ul li a, .closeMenu").on("click", function () {
      $('.headerMenuMain').removeClass('showMenu');
    });
    setTimeout(function(){ 
      $('#loader-wrapper').addClass('downloader');
      }, 200);
      setTimeout(function(){ 
        $('.loading-bg-black').addClass('downloader');
        }, 700);
  }, []);
  return (
    <>
      <div id="loader-wrapper">
          <div className="loading-bg-primary"></div>
        <div className="loading-bg-black"></div>
      </div>
      <header>
        <div className="headerMain">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2 logoMainWrap">
                <div className="logoMain">
                  <Link to={`/`}>
                    {" "}
                    <img src={Assets.logowhite} alt="Logo" width="182" height="68" />            
                  </Link>
                  <div className="mobileMenuIcon">
                       <img src={Assets.menuIcon} alt="MenuIcon" />
                  </div>
                </div>
              </div>
              <div className="col-md-7 headerMenuMain">
                <div className="closeMenu mobileMenuIcon">
                  <img src={Assets.closeMenu} alt="Close Menu" />
                </div>
                <div className="headerMenuWrap">
                  <ul>
                    <li>
                    <Link to={`/about-us/`}  onClick={() => setNavClick(!navClick)}>About Us</Link>
                    </li>
                    <li><Link to={`/book-publishing/`}  onClick={() => setNavClick(!navClick)}>Book Publishing	</Link></li>
                    <li className="hasChild parentMenu">
                     <span>Services<FontAwesomeIcon icon={faChevronDown} />
                        <ul className="dropDown">
                          <li><Link to={`/ghostwriting-services/`}  onClick={() => setNavClick(!navClick)}>Ghostwriting	</Link></li>
                          <li><Link to={`/ebook-writing/`}  onClick={() => setNavClick(!navClick)}>Ebook Writers	</Link></li>
                          <li><Link to={`/memoir-writing/`}  onClick={() => setNavClick(!navClick)}>Memoir Writing	</Link></li>
                          <li><Link to={`/biography-writing/`}  onClick={() => setNavClick(!navClick)}>Biography Writing</Link></li>
                          <li><Link to={`/business-book-writing/`}  onClick={() => setNavClick(!navClick)}>Business Book Writing	</Link></li>
                          <li><Link to={`/non-fiction-writing/`}  onClick={() => setNavClick(!navClick)}>Non-Fiction </Link></li>
                          <li className="hasChild parentMenu">
                            <span><Link to={`/fiction-writing/`}  onClick={() => setNavClick(!navClick)}>Fiction<FontAwesomeIcon icon={faChevronDown} /></Link>
                                <ul className="dropDown">
                                  <li><Link to={`/fiction-writing/`}  onClick={() => setNavClick(!navClick)}>Fiction Writing		</Link></li>
                                  <li><Link to={`/action-writing/`}  onClick={() => setNavClick(!navClick)}>Action Adventure		</Link></li>
                                  <li><Link to={`/fantasy-writing/`}  onClick={() => setNavClick(!navClick)}>Fantasy Writing		</Link></li>
                                  <li><Link to={`/science-fiction-writing/`}  onClick={() => setNavClick(!navClick)}>Sci-Fi Writing		</Link></li>
                                  <li><Link to={`/horror-book-writing/`}  onClick={() => setNavClick(!navClick)}>Horror Writing		</Link></li>
                                  <li><Link to={`/drama-writing/`}  onClick={() => setNavClick(!navClick)}>Drama Writing		</Link></li>
                                  <li><Link to={`/adventure-writing/`}  onClick={() => setNavClick(!navClick)}>Adventure Writing 	</Link></li>	
                                  <li><Link to={`/suspense-thriller-writing/`}  onClick={() => setNavClick(!navClick)}>Suspense/Thriller Writing 		</Link></li>
                                  <li><Link to={`/romance-writing/`}  onClick={() => setNavClick(!navClick)}>Romance Writing 	</Link></li>	
                                  <li><Link to={`/mystery-writing/`}  onClick={() => setNavClick(!navClick)}>Mystery Writing 	</Link></li>
                                </ul>
                                <div className="openDropdown">
                                  <FontAwesomeIcon icon={faChevronDown} />
                                </div>
                            </span>
                            </li>
                        </ul>
                        <div className="openDropdown">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                     </span>
                    </li>
                    
                    <li className="hasChild parentMenu">
                     <span>Editing & Formatting<FontAwesomeIcon icon={faChevronDown} />
                        <ul className="dropDown">
                          <li><Link to={`/book-editing-services/`}  onClick={() => setNavClick(!navClick)}>Book Editing	</Link></li>
                          <li><Link to={`/book-formatting-services/`}  onClick={() => setNavClick(!navClick)}>Book Formatting	</Link></li>
                        </ul>
                        <div className="openDropdown">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                     </span>
                    </li>
                    <li className="hasChild parentMenu">
                     <span>Marketing Services<FontAwesomeIcon icon={faChevronDown} />
                        <ul className="dropDown">
                          
                          
                          <li><Link to={`/book-marketing/`}  onClick={() => setNavClick(!navClick)}>Book Marketing 	</Link></li>
                          <li><Link to={`/audiobook-narrators/`}  onClick={() => setNavClick(!navClick)}>Audio Books	</Link></li>
                          <li><Link to={`/author-website-design/`}  onClick={() => setNavClick(!navClick)}>Author Web Design</Link></li>
                        </ul>
                        <div className="openDropdown">
                          <FontAwesomeIcon icon={faChevronDown} />
                        </div>
                     </span>
                    </li>
                    <li>
                    <Link to={`/contact-us/`}  onClick={() => setNavClick(!navClick)}>CONTACT</Link>
                    </li>
                    <li>
                    <Link to={`/blog/`}  onClick={() => setNavClick(!navClick)}>Blog</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 ctaRight">
                <div className="HederCtaRight">
                  <div className="headerCta">
                    <a href="javascript:;" className="openPopup">
                      Request A Quote{" "}
                      <FontAwesomeIcon icon={faLongArrowRight} />
                    </a>
                  </div>
                  <div className="HeaderCtaPhoneEmail">
                  <a href={Constant.PhoneNumberLink}>
                      <FontAwesomeIcon icon={faPhone} /> {Constant.PhoneNumber}
                    </a>
                    <a href={Constant.EmailLink}>
                      <FontAwesomeIcon icon={faEnvelope} />
                      {Constant.Email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {showInnerBanner ? (
        <section className="sec1 innerBanner ghostbanner">
          {children}
        </section>
      ) : null}
    </>
  );
}

export default Header;
