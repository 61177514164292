import React from "react";
import "./styles.css";
import ButtonPrimary from "../../components/Buttons";
import Assets from "../../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../../Constants";
function Process({Title = "How We Create a Masterpiece", Text = "Let us take you on a step-by-step guide to our processes; we work through a chronology and practice timely submissions that are tweaked to your liking before the final publishing stage. " }) {
  return (
    <section className="sec7 processMain whiteCta">
    <div className="container">
      <div className="row">
        <div className="col-md-12 processTitle whiteTitle">
          <span className="smallHeading lineBar">Process We Follow</span>
          <span className="heading2">{Title}</span>
          <p>{Text}</p>
        </div>
        {/* <div className="col-md-12 Processimage">
          <div className="proImag proDesktop">
          <img src={Assets.proImg} alt="Book Image" />
          </div>
          <div className="proImag proMobile">
          <img src={Assets.proImgRes} alt="Book Image" />
          </div>
        </div> */}
        <div className="col-md-12 ctaWrap">
          <div className="aboutCta">
          <ButtonPrimary/>
            <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
              <FontAwesomeIcon icon={faPhone} />
              <span className="smallTitleLine">Call Us</span>
              {Constant.PhoneNumber}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default Process;
