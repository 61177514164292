import React, { useEffect, useState, useRef } from "react";

import { Link, useLocation,useNavigate } from "react-router-dom";
import "./styles.css";
import { Constant } from "../../Constants";
import $ from "jquery";
import Assets from "../../assets/images";

import LandingPageHeader1 from "../../components/LandingPageHeader1";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLongArrowAltRight,
  faStar,
  faArrowRight,
  faComment
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ButtonPrimary from "../../components/LandingPageButtons";
import LandingPagePopup1 from "../../components/LandingPagePopup1";
import LandingPageFooter1 from "../../components/LandingPageFooter1";
import LandingBannerForm from "../../components/LandingPageBannerForm";
function LandingPage1() {
  
  const url = window.location.href;
  useEffect(() => {
    $('.chat, .chatt, #livechat').click(function () {
      javascript:void(window.Tawk_API.toggle())
    });
  }, []);
  useEffect(() => {
    setTimeout(function() { 
      $('.popupMain').addClass("show");
      setTimeout(function(){ 
        $('.popupBox').addClass('show');
        }, 10);
     }, 15000);
    $(".openPopup").on("click", function () {
      $('.popupMain').addClass("show");
      setTimeout(function(){ 
        $('.popupBox').addClass('show');
        }, 10);
    });
    $(".popupClose").on("click", function () {
      $('.popupMain').removeClass("show");
      $('.popupBox').removeClass("show");
    });
  }, []);

  return (
      <>
	<div className="landingpage2">
		<LandingPageHeader1 />

<section className="banner_sec">

<div className="container">

	<div className="row align-items-center">

		<div className="col-md-8">

			<div className="banner_inner">

				<p className="bannertext">Bellevue Cookbook Publishing services</p>

				<h1>Where recipes become <br />published masterpieces</h1>
				<img className="logo_img" src={Assets.nlogo } />
			</div>

			<div className="banner_btn">
            <ButtonPrimary Text="GET STARTED" className="btn btn-primary popup_button  openPopup"/>
                <ButtonPrimary Text="Live Chat" className="btn-dark global_buton color-red chat"/>
				{/* <button type="button" className="btn btn-primary popup_button" data-bs-toggle="modal"
					data-bs-target="#exampleModal">GET STARTED</button>

				<button id="livechat" type="button" className="btn-dark global_buton color-red"><a
						href="javascript: void(0);"
						>Live Chat</a></button> */}

			</div>

		</div>

		<div className="col-md-4">

			<div className="banner_form">
			<LandingBannerForm />
				

			</div>

		</div>

	</div>

</div>

</section>

<section className="about_sec">

    <div className="container">

        <div className="row align-items-center">

            <div className="col-md-8">

                <div className="global_inner text-center">

                    <h2>Share Your Culinary Adventures</h2>
 <br /><h3>Publish flavorful stories with our cookbook publishing services.</h3>

                    <p>Discover recipes at its finest with Bellevue Publishers’ exceptional cookbook publishing service. Engage in a world of creativity where each recipe becomes a delightful tale. From the artful design to the finest formatting, every detail is meticulously tailored to elevate your culinary masterpiece. Choose Bellevue Publishers, where passion for food meets the art of self publishing, creating a beautiful journey to savor forever.</p>

                </div>
                <div className="service_points">

                    <ul>

                        <li>

                            <img src={Assets.about11webp} />

                            <h5>Timeless Appeal</h5>

                        </li>

                        <li>

                            <img src={Assets.about22webp} />

                            <h5>Increased Sales</h5>

                        </li>

                        <li>

                            <img src={Assets.about33webp} />

                            <h5>Engaging Content</h5>

                        </li>

                        <li>

                            <img src={Assets.about44webp} />

                            <h5>Vibrant Images</h5>

                        </li>

                    </ul>

                </div>

            </div>
            <div className="col-md-4">

                <div className="about_img">
                    <img className="about_imgs" src={Assets.courtny} />

                </div>

            </div>


        </div>

    </div>

</section>

<section className="talk_sec">

    <div className="container">

        <div className="row align-items-center">

            <div className="col-md-12">

                <div className="global_inner">

                    <h2>Publish your culinary dreams with Bellevue Publishers</h2>

                    <p>Acquire our unmatched expertise in crafting and publishing cookbooks. We ensure that your unique flavors and culinary adventures take center stage on platforms like Amazon self publishing.</p>

                </div>

                <div className="banner_btn">
                <ButtonPrimary Text="GET STARTED" className="btn btn-primary popup_button  openPopup"/>
                <ButtonPrimary Text="Live Chat" className="btn-dark global_buton color-red chat"/>
                    {/* <button type="button" className="btn btn-primary popup_button" data-bs-toggle="modal"
                        data-bs-target="#exampleModal">GET STARTED</button>

                    <button id="livechat" type="button" className="btn-dark global_buton color-red"><a className="livechat"
                            href="javascript: void(0);"
                           >Live Chat</a></button> */}

                </div>

            </div>


        </div>

    </div>

</section>

<section className="book_sec">

    <div className="container">

        <div className="row align-items-center">

            <div className="col-md-12">

                <div className="global_inner text-center">

                    <h2 className="trck">Celebrate the joy of cooking <br />with our Treasure Trove of Cookbooks</h2>

                    <p className="trck">Embark on flavorful journeys with Bellevue Publishers’ cookbooks. We make delicious recipes meet captivating images and presentations to become masterpieces.</p>

                </div>

            </div>

        </div>

        <div className="row mt-5">

            <div className="col-md-4">

                <div className="book_inner">

                    <img className="porfollios" src={Assets.landingPage_cookbookp1} />

                </div>

            </div>

            <div className="col-md-4">

                <div className="book_inner">

                    <img className="porfollios" src={Assets.landingPage_cookbookp2} />

                </div>

            </div>

            <div className="col-md-4">

                <div className="book_inner">

                    <img className="porfollios" src={Assets.landingPage_cookbookp3} />

                </div>

            </div>

        </div>

        <div className="row mt-5">

            <div className="col-md-4">

                <div className="book_inner">

                    <img className="porfollios" src={Assets.landingPage_cookbookp4} />

                </div>

            </div>

            <div className="col-md-4">

                <div className="book_inner">

                    <img className="porfollios" src={Assets.landingPage_cookbookp5} />

                </div>

            </div>

            <div className="col-md-4">

                <div className="book_inner">

                    <img className="porfollios" src={Assets.landingPage_cookbookp6} />

                </div>

            </div>

        </div>

        <div className="row">

            <div className="col-md-12">

                <div className="banner_btn text-center">
                <ButtonPrimary Text="GET STARTED" className="btn btn-primary popup_button  openPopup"/>
                <ButtonPrimary Text="Live Chat" className="btn-dark global_buton color-red chat"/>
                    {/* <button type="button" className="btn btn-primary popup_button" data-bs-toggle="modal"
                        data-bs-target="#exampleModal">GET STARTED</button>

                    <button type="button" className="btn-dark global_buton color-red"><a href="javascript: void(0);"
                            >Live Chat</a></button> */}

                </div>

            </div>

        </div>

    </div>

</section>

<section id="mobile_sec" className="testimonial_secs">

    <div className="container">

        <div className="row">

            <div className="col-md-12">

                <div className="global_inner text-center">

                    <h2 className="why">Celebrate the joy of cooking with <br />our Treasure Trove of Cookbooks</h2>

                    <p className="why">Embark on flavorful journeys with Bellevue Publishers’ cookbooks. We make delicious recipes meet captivating images and presentations to become masterpieces.</p>

                </div>

            </div>

        </div>

        <div className="row testimonial_slider align-items-end">

            <div className="col-md-4 first">

                <div className="testimonial_iner">

                    <img className="mb_slider" src={Assets.abook1webp} />

                </div>

            </div>

            <div className="col-md-4 big_iner_testi">

                <div className="testimonial_iner">

                    <img className="mb_slider" src={Assets.abook2webp} />

                </div>

            </div>

            <div className="col-md-4 last">

                <div className="testimonial_iner">

                    <img className="mb_slider" src={Assets.abook3webp} />

                </div>

            </div>

            <div className="col-md-4 last">

                <div className="testimonial_iner">

                    <img className="mb_slider" src={Assets.abook4webp} />

                </div>

            </div>

            <div className="col-md-4 last">

                <div className="testimonial_iner">

                    <img className="mb_slider" src={Assets.abook5webp} />

                </div>

            </div>

            <div className="col-md-4 last">

                <div className="testimonial_iner">

                    <img className="mb_slider" src={Assets.abook6webp} />

                </div>

            </div>

        </div>



    </div>


</section>

<section className="newsabout_sec">

    <div className="container">

        <div className="row align-items-center">

            <div className="col-md-8">

                <div className="global_inner text-center">

                    <h2 className="why2"> Why Bellevue Publishers’ <br />Cookbook Publishing Services</h2>

                    <p className="why2">Unlock cookbook excellence with Bellevue Publishers! Vibrant images, unlimited revisions, and professional formatting ensure culinary perfection. Your masterpiece flawlessly captured and presented! Join us now.</p>

                </div>
                <div className="service_points">

                    <ul>

                        <li>

                            <img src={Assets.serviceIcon1} />

                            <h5>Unlimited revisions</h5>

                        </li>

                        <li>

                            <img src={Assets.serviceIcon2} />

                            <h5>Professional Formatting</h5>

                        </li>

                        <li>

                            <img src={Assets.serviceIcon3} />

                            <h5>Timeless Appeal</h5>

                        </li>

                      

                    
                        <li>

                            <img src={Assets.serviceIcon4} />

                            <h5>Increased Sales</h5>

                        </li>

                        <li>

                            <img src={Assets.serviceIcon5} />

                            <h5>Vibrant images</h5>

                        </li>

                        <li>

                            <img src={Assets.serviceIcon6} />

                            <h5>Convenient self publishing process</h5>

                        </li>


                    </ul>

                </div>

            </div>

          

            <div className="col-md-4">

                <div className="about_img">

                    <img className="Publishing" src={Assets.landingPage_cookbookwhy} />

                </div>

            </div>
        </div>

    </div>

</section>

<section className="proces_sec">

    <div className="container">

        <div className="row">

            <div className="col-md-12">

                <div className="global_inner">

                    <h2 className="process">Bellevue Publishers’ <br />Convenient Publishing Process
                    </h2>

                    <p className="process">The roadmap of our custom cookbook self publishing services is </p>

                </div>

            </div>

        </div>

        <div className="row mb-5">

            <div className="col-md-3 mt-5">

                <div className="proces_iner">

                    <div className="proces_img">

                        <img className="pro" src={Assets.landingPage_cookbookserviceIcon1} />

                    </div>

                    <div className="proces_content">

                        <h3>Manuscript Submission</h3>

                        <p>Meet our project manager for tailored project scope after order placement and manuscript review.</p>

                    </div>

                </div>

            </div>

            <div className="col-md-3 mt-5">

                <div className="proces_iner">

                    <div className="proces_img">

                        <img className="pro" src={Assets.landingPage_cookbookserviceIcon2} />

                    </div>

                    <div className="proces_content">

                        <h3>Project Execution</h3>

                        <p>Experts prepare your manuscript after receiving approval on the project scope. Our team transform your recipes according to the standards of renowned publishing platforms.</p>

                    </div>

                </div>

            </div>

            <div className="col-md-3 mt-5">

                <div className="proces_iner">

                    <div className="proces_img">

                        <img className="pro" src={Assets.landingPage_cookbookserviceIcon3} />

                    </div>

                    <div className="proces_content">

                        <h3>Feedback and Approval</h3>

                        <p>We involve you throughout the process, seeking approval at each step before proceeding to the next process.</p>

                    </div>

                </div>

            </div>

            <div className="col-md-3 mt-5">

                <div className="proces_iner">

                    <div className="proces_img">

                        <img className="pro" src={Assets.landingPage_cookbookserviceIcon4} />

                    </div>

                    <div className="proces_content">

                        <h3>Publishing</h3>

                        <p>Once approved, your book undergoes professional formatting with stunning covers, bringing your idea to life.</p>

                    </div>

                </div>

            </div>

        </div>

    </div>

</section>

<section className="testihdg testimonial_sec">
<div className="container">
<div className="row">
<div className="col-md-5">
    <div className="testih">
    <h3>TESTIMONIAL</h3>
    <h1>What Our Clients Have to Say About Us</h1>
    <p>Bellevue Book Publishers brought my imagination to life! Their top-notch publishing services
                            ensure my children’s book becomes a captivating masterpiece.</p>
    </div>
	<div className="contents_sec offwhite">
                    <div className="cntent">
                        <h1>Emily Smith</h1>
                        <p>I am amazed by the seamless publishing process with Bellevue Publishers! They valued my inputs and turned my recipes into a beautiful cookbook. I’m proud to see my book on famous platforms!</p>
                    </div>
                    <ul className="testi">
                        <li> <img src={Assets.test3} alt="" /> </li>
                     <li>   <h3 className="clientname">Emily Smith<br /><span className="testi_sec">Director</span></h3></li>
                    </ul>
                </div>
</div>
<div className="col-md-7">
    <div className="testibtn">
    <ButtonPrimary Text="Live Chat" className="btn-dark global_buton color-red chat"/>
    {/* <button type="button" className="btn-dark global_buton color-red" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <a href="javascript:void(0);">Get Started</a></button> */}
    </div>
	<br /><br />
	<div className="contents_sec">
                    <div className="cntent">
                        <h1>Sarah Williams</h1>
                        <p>I highly recommend Bellevue Publishers for cookbook publishing. Their supportive team guided me throughout, ensuring my ideas shined. Seeing my book professionally published is beyond satisfying. Thank you, Bellevue!</p>
                    </div>
                    <ul className="testi">
                        <li> <img src={Assets.test2} alt="" /> </li>
                     <li>   <h3 className="clientname">Sarah Williams<br /><span className="testi_sec">Manager</span></h3></li>
                    </ul>
                </div>
</div>
</div>
</div>

</section>
{/* 
<section className="testimonial_sec">

    <div className="container">

        <div className="row">

            <div className="col-md-6">
                <div className="contents_sec">
                    <div className="cntent">
                        <h1>Awesome Services!</h1>
                        <p>Bellevue Book Publishers brought my imagination to life! Their top-notch publishing services
                            ensure my children’s book becomes a captivating masterpiece.</p>
                    </div>
                    <ul className="testi">
                        <li> <img src={Assets.starwebp} alt="" /> </li>
                     <li>   <h3 className="clientname">Title<br /><span className="testi_sec">sub time </span></h3></li>
                    </ul>
                </div>
            </div>
            <div className="col-md-6">
                <div className="contents_sec">
                    <div className="cntent">
                        <h1>Awesome Services!</h1>
                        <p>Bellevue Book Publishers brought my imagination to life! Their top-notch publishing services
                            ensure my children’s book becomes a captivating masterpiece.</p>
                    </div>
                    <ul className="testi">
                        <li> <img src={Assets.starwebp} alt="" /> </li>
                     <li>   <h3 className="clientname">Title<br /><span className="testi_sec">sub time </span></h3></li>
                    </ul>
                </div>
            </div>

         

        </div>

    </div>


</section> */}

<div className="order_sec">

    <div className="container">

        <div className="row">

            <div className="col-md-12">

                <div className="global_inner">

                    <h2 className="experts">Elevate Your Culinary Journey - Publish with Bellevue Now!</h2>

                    <p className="right_p">Don’t Miss Out on Cookbook Excellence - Join Bellevue Publishers!</p>
                    <ButtonPrimary id="get" Text="GET STARTED" className="btn-dark global_buton color-red openPopup"/>
                    {/* <button id="get" type="button" className="btn-dark global_buton color-red" data-bs-toggle="modal"
                        data-bs-target="#exampleModal"><a href="javascript:void(0);">Get Started</a></button> */}

                </div>

               <div className="order_button">
                <ButtonPrimary Text="Live Chat" className="btn-dark global_buton color-red chat"/>
					{/* <button type="button" className="btn-dark global_buton color-red" data-bs-toggle="modal" data-bs-target="#exampleModal"><a href="javascript:void(0);">Get Started</a></button> */}

				</div>

            </div>

        </div>

    </div>
    <LandingPagePopup1 />
</div>
		<LandingPageFooter1 />
	</div>
    </>
  );
}
export default LandingPage1;


