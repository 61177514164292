import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Assets from '../../assets/images';
import { Link } from "react-router-dom";
import { Constant } from '../../Constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import jQuery from "jquery";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import FooterForm from "../LandingPageFooterForm";


function LandingPageFooter1() {
  const [phoneNumber, setPhoneNumber] = useState('');
  
  function handleKeyPress(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  function handleChange(event) {
    setPhoneNumber(event.target.value);
  }
  const url = window.location.href;
  const location = useLocation();
  const currentUrl = location.pathname;
  const form = useRef();
  const RedirectUrl = function(url){
    window.location.href = "/thankyou";
  }
const sendEmailPopup = (e) => {
  e.preventDefault();
  $("button.btnPrimary"). attr("disabled", true);
  emailjs.sendForm('service_mpjgvt5', 'template_jw2zrfk', form.current, '960vdqz35TukiHxIX')
    .then((result) => {
     
     $('.popupMain').removeClass("show");
     $('.popupBox').removeClass("show");
      $("button.btnPrimary"). attr("disabled", false);
      $('.formFields input,.formFields textarea').val('');
      // Swal.fire({
      //  icon: "success",
      //   title: "We know the world is full of choices. Thank you for choosing us! ",
      //   text : `Be the author of the next best-selling book! {Constant.CompanyName}, just a click away!`
      // })
      RedirectUrl();
    }, (error) => {
     
      $("button.btnPrimary"). attr("disabled", false);
      Swal.fire({
        icon: "error",
        title: "Ooops, something went wrong",
        text: error.text,
      })
    });
};
  
  useEffect(() => {
    $(".Popupshow").on("click", function () {
      $('.popupMain').addClass("show");
      setTimeout(function(){ 
        $('.popupBox').addClass('show');
        }, 10);
    });
    $(".popupClose").on("click", function () {
      $('.popupMain').removeClass("show");
      $('.popupBox').removeClass("show");
    });
  }, []);
  return (
    <>
  <div className="landingpage2">

<footer className="main_footer">

<div className="container">

    <div className="row align-items-center">

        <div className="col-md-5">

            <div className="footer_inner location">

                <h3>Address</h3>

                <p> 2400 Vance Ln, Rollingwood, TX 78746, USA</p>

                <h3>Phone</h3>

                <p>(203) 920-4659</p>

                <h3>Email</h3>

                <p> info@bellevuepublishers.com </p>

            </div>

        </div>

        <div className="col-md-7">

            <div className="footer_inner">

                <h3>Get In Touch</h3>

                <FooterForm />

            </div>

        </div>

    </div>

</div>

</footer>

<section className="bottom_foter">

    <div className="container">

        <div className="row">

            <div className="col-md-6">

                <div className="inner_footer">

                    <p>© 2023 - All Rights Reserved, Bellevue Publishers.</p>

                </div>

            </div>

            <div className="col-md-6">

                <div className="inner_footer">

                    <p><a href="https://bellevuepublishers.com/terms-condition/">Terms & Conditions</a> | <a href="https://bellevuepublishers.com/privacy-policy/">Privacy Policy</a></p>

                    

                </div>

            </div>

        </div>

    </div>

</section>


  </div>
   </>
  );
}

export default LandingPageFooter1;
