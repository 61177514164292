import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyCUquVDiqJv7HbjO3xJ57Bf4EAMvP4ds1M",
  authDomain: "bellevuepublishers-9af1e.firebaseapp.com",
  projectId: "bellevuepublishers-9af1e",
  storageBucket: "bellevuepublishers-9af1e.appspot.com",
  messagingSenderId: "556674237488",
  appId: "1:556674237488:web:ad6e5d14b024dcf9dd59f5"
});

const fb = firebase;

export default fb;