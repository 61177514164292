import React from "react"; 

import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";
function TermsCondition() {
  return (
    <>
      <Helmet>
      <title>Terms Condition - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner AboutUsBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-12 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Terms & Conditions
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      <section className="sec3 aboutSec2Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 innerPageDefaultContent bookContentmain">
              <h1><strong>Terms and Conditions</strong></h1>
            <h3><strong>Introduction</strong></h3>
<p>Thank you for visiting Bellevue Publishers.</p>
<p>These Terms and Conditions serve as an agreement between you, the user of this website, and Bellevue Publishers.</p>
<p>The Terms and Conditions govern the use of our website, bellevuepublishers.com. They work together with our Privacy Policy, which explains how we collect, protect, and use the information obtained through our website.</p>
<p>In this agreement, the terms’ we,’ 'us,’ ‘our,’ and ‘agreement’ refer to Bellevue Publishers, while ‘you,’ ‘user,’ or ‘yours’ refer to anyone visiting bellevuepublishers.com.</p>
<p>We encourage you to carefully read and accept the terms outlined in this agreement before using our service. If you do not agree to these terms, we kindly ask you to refrain from using our service. If you have any concerns or questions, please contact us at info@bellevuepublishers.com, and we will work towards finding a suitable solution. These “Terms and Conditions” apply to all visitors of Bellevue Publishers who wish to access our services.</p>
<h3><strong>Subscriptions</strong></h3>
<p>By accepting the terms of this agreement, you may subscribe to receive newsletters, marketing materials, promotional offers, or other informative content via mail or digital means. If you wish to unsubscribe from any of our communications, please email us at info@bellevuepublishers.com.</p>
<h3><strong>Purchase Policy</strong></h3>
<p>When subscribing to our services, Bellevue Publishers may require you to provide certain details, including personal information and credit or debit card details. It is important to ensure that the information you provide to Bellevue Publishers is accurate and complete.</p>
<p>Bellevue Publishers may utilize third-party payment service providers to facilitate payment processing. By submitting your information to Bellevue Publishers, you consent to share the relevant information with these third-party payment processors.</p>
<p>Bellevue Publishers reserves the right to refuse or cancel any order at any time due to service unavailability, pricing errors, or any other mistakes in the order placement process. We may also refuse or cancel orders if fraudulent or unauthorized activities are detected.</p>
<h3><strong>Terms of Promotions</strong></h3>
<p>Bellevue Publishers may run online contests and promotional offers to enhance our services. The terms and conditions of these promotions or contests may differ from those stated in this agreement. We advise users to carefully review the specific terms and conditions of each promotion. In the event of any contradiction between the promotional rules and this agreement, this agreement will prevail.</p>
<p>&nbsp;</p>
<h2>Returns</h2>
<p>Due to the nature of the products we produce, we do not accept returns on fulfilled orders.<br />However, if changes are required to meet quality standards, such as editing and proofreading services, and to ensure compliance with provided requirements, we have a revision process in place. This process is designed to assist customers and prioritize their satisfaction.</p>
<p>If the fulfilled product does not meet the specified requirements stated at the time of order placement, we will conduct an extensive internal review and either revise or recreate the content at no additional cost.</p>
<h2>Revisions</h2>
<p>Each package has its own revision policy, as described in the service description of each specific package. We offer revisions within 5 to 10 days after the order status changes to "Waiting for Customer Approval" in our Order Management Platform. The revision period will depend on the chosen package.</p>
<p>Revisions come into play once the order has been presented for review and the status is updated to "Waiting for Customer Approval." At this stage, customers can request revisions by directly contacting their assigned Project Manager via email, ensuring the original requirements have been met for the entire product.</p>
<p>Customers are always welcome to make personal changes to the content they receive from us after the order has been fulfilled but before publishing. The content is delivered in Word format, and customers retain the rights to it.</p>
<p>Refunds at Bellevue Publisher are handled on a case-by-case basis. We take every measure to ensure that our clients and their needs are effectively addressed.<br />Our teams will collaborate with you to resolve any issues to the best of our ability.<br />Conditions for a refund may include but are not limited to, the following clauses.</p>
<h2>Ineligibility of Refund Claims</h2>
<p>Minor Errors or Technicalities: Refunds will not be issued for late or delayed delivery resulting from minor errors or technicalities, such as grammatical errors, typing errors, word count compensation, or the addition of references.</p>
<p><strong>Client-Related Delays: </strong>We cannot be held responsible for delays caused by clients, and refunds will not be provided in such cases.</p>
<p><strong>Low Word Count:</strong> Refunds will not be accepted solely based on low word count.</p>
<p><strong>Lack of Concern Submission:</strong> Refunds will not be accepted if the client has not submitted a concern regarding the delivered work.</p>
<p><strong>Additional Requirements or Outrageous Demands:</strong> If delays occur due to the client's addition of new requirements or any outrageous demands that were not communicated at the time of order placement, refunds will not be accepted.</p>
<p><strong>Exclusion of Tips:</strong> No refund claim will be accepted for tips provided to our writers or team members.</p>
<h2>Change of Mind</h2>
<p><strong>Before Work Initiation:</strong> If the client wishes to request a refund before our writers and editors have started working on the project, they may do so within one business day after placing the order. The request must be made in writing via email.<br /><strong>After-Work Initiation:</strong> Refund claims made after the project has started will only be considered if the client has utilized at least three revisions. In such cases, a partial refund may be issued to fairly compensate our internal teams.</p>
<h2>Late Delivery</h2>
<p>At Bellevue Publisher, we guarantee on-time deliveries in all cases. However, if a project is not delivered on time for unforeseen or unfortunate reasons and the client has made at least three attempts to contact our service, a 50% refund will be processed. The client must provide documented evidence that the late delivery was due to a fault on our end.</p>
<p><strong>Client-Related Delays: </strong>Refunds for late deliveries will not be provided if the delay is caused by a fault at the client's end.</p>
<p><strong>Mutual Agreement:</strong> All refund cases will be settled through mutual agreement between the client and Bellevue Publisher.</p>
<p><strong>Partial Refunds:</strong> In certain cases, partial refunds or future discounts may be offered to the client as a form of compensation.</p>
<h2>Inadequate Delivery</h2>
<p>We are dedicated to ensuring that our clients receive high-quality work that meets their goals. We encourage clients to provide feedback, and we assign, reassign, and rewrite the work to achieve complete satisfaction. However, a refund request will only be accepted if the client has requested at least three revisions in documented form and has communicated their concerns to our support/project management team on at least three occasions within a two-week period.</p>
<p>During pre-sales or post-sales stages, all ongoing communication, progress updates, and delivery-related matters should take place through our project management online tool, teamwork, official email, or by calling the assigned numbers. Please be cautious of any calls or emails received from random numbers or email addresses claiming to be associated with our company. Bellevue Publisher will not be responsible for any scams or fraudulent activities originating from such sources.</p>
<h3><strong>Website Content</strong></h3>
<p>All content on our website, including descriptions, images, and text, is the exclusive property of Bellevue Publishers. The website content may not be used without prior written permission from Bellevue Publishers. Users are strictly prohibited from distributing, altering, transferring, downloading, or reposting any of the website content, in whole or in part, for commercial purposes or personal gain without explicit consent.</p>
<h3><strong>Restrictions</strong></h3>
<p>Users are required to adhere to the following restrictions when using the services provided by Bellevue Publishers:</p>
<ul>
    <li>Users must not engage in any activities that violate international or national regulations.</li>
    <li>Users must not exploit or harm underage children or minors by exposing them to inappropriate content.</li>
    <li>Users must not transfer, secure, or send any form of unsolicited advertisement or promotional material, such as junk mail or spam.</li>
    <li>Users must not impersonate individuals, organizations, company employees, or any other individuals.</li>
    <li>Users must not infringe upon the rights of others.</li>
    <li>Users must not engage in threatening, fraudulent, illegal, harmful, or associated activities.</li>
    <li>Users must not prevent other users or individuals from accessing or connecting with Bellevue Publishers.</li>
    <li>Users must not engage in any activities that may offend or harm Bellevue Publishers, its services, or its users.</li>
    <li>Users must not engage in any activities that may expose Bellevue Publishers to liability.</li>
</ul>
<h3><strong>Additionally, users are prohibited from:</strong></h3>
<ul>
    <li>Using robots, spiders, or any other automatic devices or processes to access, copy, or monitor Bellevue Publishers website content.</li>
    <li>Using any device, software program, or tool that hinders Bellevue Publishers from providing its full range of services.</li>
    <li>Introducing viruses, Trojan horses, worms, logic bombs, or any other materials that aim to harm or disrupt the operations of Bellevue Publishers.</li>
    <li>Attempting to gain unauthorized access to any part of Bellevue Publishers, including its servers, computers, or databases.</li>
    <li>Conducting denial-of-service attacks or distributed denial-of-service attacks.</li>
    <li>Submitting false low ratings for the company.</li>
    <li>Damaging Bellevue Publishers reputation through falsified ratings or claims.</li>
    <li>Interfering with the normal operations of Bellevue Publishers.</li>
</ul>
<h3><strong>Analytics</strong></h3>
<p>To monitor and analyze the performance of Bellevue Publishers, third-party service providers, including but not limited to Google Analytics, may be employed.</p>
<h3><strong>Age of Consent</strong></h3>
<p>Bellevue Publishers services are exclusively provided to individuals who have reached the legal age as determined by their state of residence. By utilizing our services, you confirm that you meet the minimum age of consent required by your state and possess the full capacity to enter into this agreement. If you do not meet the age of consent, you are prohibited from using Bellevue Publishers services.</p>
<h3><strong>Intellectual Property</strong></h3>
<p>All content provided by users remains their intellectual property. The content, features, services, and functionality displayed on Bellevue Publishers website are the intellectual property of the service and its licensors. Bellevue Publishers is protected by copyright, trademark, and other national and international laws. Users are not permitted to use Bellevue Publishers services without obtaining written consent from Bellevue Publishers.</p>
<h3><strong>Copyright Policy</strong></h3>
<p>At Bellevue Publishers, we highly respect the rights of intellectual property owners. We take copyright infringements seriously and promptly address any valid claims regarding such infringements. If you believe your copyright has been violated or are an authorized representative of the copyright owner, please contact us with the concern. Kindly provide a detailed description of the issue. However, please be aware that submitting a false claim with the intent to harm or disrupt the services of Bellevue Publishers may result in liability for damages, including attorney costs, in the case of misrepresentation or wrongful claims.</p>
<h3><strong>Feedback and Error Reports</strong></h3>
<p>We value your feedback at Bellevue Publishers. Please feel free to share your experience by emailing us at info@bellevuepublishers.com or submitting reviews through a third-party service website or tool. We appreciate any suggestions for improving our service quality and welcome information about any problems, complaints, or issues you may have encountered during your collaboration with Bellevue Publishers.</p>
<p>&nbsp;</p>
<h3><strong>External Linking</strong></h3>
<p>Bellevue Publishers may provide links to third-party websites or services not owned or operated by Bellevue Publishers. We want to emphasize that we are not responsible for these third-party websites' content, privacy policies, or activities. We encourage users to carefully review the Terms and Conditions and Privacy Policy of any websites linked to Bellevue Publishers services after visiting them. By agreeing to this policy, you acknowledge that Bellevue Publishers cannot be held directly or indirectly liable for any harm, damage, or loss—real or alleged—resulting from using externally linked websites.</p>
<h3><strong>Warranty Disclaimer</strong></h3>
<p>Bellevue Publishers provides its services on an “As-Is” and “As-Available” basis. We do not make any direct or implied representations or warranties regarding the operations of our services, the shared content, or the materials provided as part of the service. Any information, materials, or content obtained through Bellevue Publishers are acquired at your own risk.</p>
<p>Bellevue Publishers, including any individuals associated with our services, disclaims any warranties or representations concerning the service's state, security, reliability, accuracy, or availability without limiting the foregoing. We explicitly disclaim all warranties of any kind, whether expressed, implied, statutory, or otherwise, including but not limited to merchantability, non-infringement, and fitness for a particular purpose.</p>
<p>The above disclaimer does not affect any warranties that are mandated and applicable under the relevant laws.</p>
<h3><strong>Limitation of Liability</strong></h3>
<p>Except as required by law, Bellevue Publishers and its officers, directors, employees, and affiliated individuals shall not be held liable for any direct or indirect, punitive, special, incidental, or consequential damages arising from the use of our services. In the event that Bellevue Publishers is found liable, any costs, including attorney's fees, arbitration, litigation, or trial on appeal, shall be the responsibility of the claimant.</p>
<p>If liability is imposed on Bellevue Publishers, it shall be limited to the amount paid for the service, and no additional damages, including punitive or consequential damages, shall be awarded. However, certain jurisdictions may not allow the exclusion of punitive, consequential, or incidental damages, so the above limitation may not apply to you.</p>
<h3><strong>Account Termination</strong></h3>
<p>Bellevue Publishers reserves the right to suspend or terminate your account on our website at any time and without prior notice or liability, under our sole discretion and for any reason, including but not limited to a breach of our terms.</p>
<p>If you wish to discontinue or terminate your account with Bellevue Publishers, you can do so by simply ceasing to use our services.</p>
<p>All provisions of these terms and conditions shall survive termination of the Bellevue Publishers' services.</p>
<h3><strong>Governing Law</strong></h3>
<p>These Terms and Conditions shall be governed by the laws of the United States. Any failure on the part of Bellevue Publishers to enforce any right or provision of these terms shall not constitute a waiver of such rights. If any provision of these terms is deemed invalid by a court of competent jurisdiction, the remaining provisions shall remain in full effect. These terms constitute the entire agreement between Bellevue Publishers and its users, superseding any prior agreements.</p>
<h3><strong>Service Modifications</strong></h3>
<p>Bellevue Publishers reserves the right to withdraw, amend, or modify any aspect of our services or the materials provided through the Services, at our sole discretion and without notice. We shall not be liable for any unavailability of the services for any period. Bellevue Publishers may also restrict access to certain components of the service or the entire service.</p>
<h3><strong>Updates to Terms and Conditions</strong></h3>
<p>Bellevue Publishers reserves the right to revise, update, or amend the terms and conditions on this website at any time, at our sole discretion and without prior notice. Users are advised to review the terms and conditions page regularly to stay informed about any changes to the agreement. The "in effect" date at the beginning of the page indicates the most recent modification date.</p>
<p>By continuing to use Bellevue Publishers' services, you acknowledge and agree to the updated terms and conditions. If you do not agree with the updated terms, you may choose to refrain from using Bellevue Publishers' services.</p>
<h3><strong>Waiver of Terms and Services</strong></h3>
<p>No waiver of any provision of these terms shall be construed as a waiver of any other provision or the rights of Bellevue Publishers. The failure to assert a right or provision under these terms shall not constitute a waiver of that right or provision.</p>
<p>If any provision of these terms is found to be invalid, illegal, or unenforceable by a court or other competent authority, that provision shall be limited or eliminated to the minimum extent necessary, and the remaining provisions shall remain in full force and effect.</p>
<h3><strong>Acknowledgment</strong></h3>
<p>By visiting and using the services of Bellevue Publishers, you agree to be bound by the aforementioned terms and conditions.</p>
             </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default TermsCondition;
