import React, { useEffect } from "react"; 
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faChevronDown,
  faPhone,
  faLongArrowRight,
  faStar,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Helmet } from "react-helmet";
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Girlquote from "../../components/Girlquote";
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { useRef } from 'react';

function BiographyWriting() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);  
  return (
    <>
      <Helmet>
        <title>Biography Writing Services | Best Biography Writers for Hire</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner bioBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Capture the Essence of Lives with Our Biography Writing Services. 
                  </h1>
                  <p>Bellevue Publishers helps you bring remarkable stories to life, immortalizing the achievements, struggles, and triumphs of individuals. Connect with us to create captivating biographies that resonate with readers and inspire future generations.</p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Unveil Lives, Inspire Readers: Unforgettable Biographies by Bellevue Publishers</h4>
                <p>
                When your audience reads a biography, they want to read a well-researched manuscript that doesn’t stray away from the subject. Biographies are often written by people who are close to the subject; our biography writers help you compile a structured manuscript that is filled with personal info, memoirs, diaries, and much more. A well-researched biography is always unforgettable and provides a lot of insights into the subject’s life. </p>

                <p>A biography is a collection of a person’s life; it is different from a self-narration which is an autobiography. Biographies offer an untampered look at human behaviors, emotions, and life events. They are not only relatable but leave a lasting impact on the audience. Biographies are written professionally by biography writers who are well-experienced to provide a seamless flow to manuscripts that cover the trajectory of an individual’s life. Biographies are not just for famous people but for those who want to pay tribute to beautiful people and beautiful stories. 
                </p>
                <p>{Constant.CompanyName} believe in sharing stories with a global audience that would inspire, make aware, and act as a tell-all for your audience. Our expert biography writers do in-depth research and help you write biographies that would otherwise take a lifetime to create. </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.bioHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Inspire others with a professionally written biography. Let's bring your story to life" Text="Connect with us now to get the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Choose Bellevue Publishers <br></br>for Biography Writing Services
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.biowriting1} loop={true} />
                    </div>
                    <div className="contentChoose">
                      <span>Extensive Research </span>
                      <p>Our ghostwriters conduct thorough research on the subject, ensuring each biography is a heartfelt tribute.</p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.biowriting2}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Character Development </span>
                      <p>
                      Our biographer writing service excels in capturing the subject's essence and presenting their story from their perspective.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.biowriting3}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Skilled Bio Writers</span>
                      <p>
                      Our biographers know how to structure a compelling narrative, incorporating scholarly references, historical context, and intellectual depth.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.biowriting4}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Comprehensive Biography Writing </span>
                      <p>
                      With expertise across various ghostwriting genres, our professional biography writers will make your book a bestseller.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                  <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <section className="sec12 TestiSliderMain">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 whyChooseLeft">
          <div className="abtLeft">
            <span className="smallTitleLine">Testimonials</span>
            <h4>
            What Our Clients Say About Us?
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-12 blogSlider">
        <Swiper
          slidesPerView={3}
          spaceBetween={120}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          centeredSlides={false}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={false}
          breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">JS </span>
            <div className="nameStars">
              <span className="clientNameTesti">John Smith</span>
              <div className="ratingStar">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Working with Bellevue Publishers was a game-changer for my biography. They captured my essence flawlessly, and the final result exceeded my expectations.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">EJ</span>
            <div className="nameStars">
              <span className="clientNameTesti">Emily Johnson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers brought my father's remarkable life story to life. The attention to detail and the emotional depth in the biography were truly outstanding.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">RA</span>
            <div className="nameStars">
              <span className="clientNameTesti">Robert Anderson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I am grateful to Bellevue Publishers for their exceptional biography writing services. They transformed my experiences into a compelling narrative that resonated with readers.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">SD</span>
            <div className="nameStars">
              <span className="clientNameTesti">Samantha Davis</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>The team at Bellevue Publishers has an incredible talent for storytelling. They captured the essence of my journey, creating a biography that touched hearts.</p>
          </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">MT</span>
            <div className="nameStars">
              <span className="clientNameTesti">Michael Thompson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Choosing Bellevue Publishers for my biography was the best decision I made. They expertly weaved together historical context, personal anecdotes, and captivating storytelling.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">SW</span>
            <div className="nameStars">
              <span className="clientNameTesti">Sarah Wilson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I highly recommend Bellevue Publishers for their professionalism and dedication to capturing the true essence of a person's life in a biography.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">DB</span>
            <div className="nameStars">
              <span className="clientNameTesti">David Brown</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Working with Bellevue Publishers was a seamless and rewarding experience. They expertly crafted my life story, delivering a biography that exceeded my expectations.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">OR</span>
            <div className="nameStars">
              <span className="clientNameTesti">Olivia Roberts</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers brought my grandmother's incredible journey to life through their biography writing services. Their attention to detail and passion for storytelling truly shone through.</p>
          </div>
            </div>
          </SwiperSlide>
          
        </Swiper>

        </div>
      </div>
    </div>
  </section>
      <Blogs/>
      <Ctaorange Title="Inspire others with a professionally written biography. Let's bring your story to life" Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">FAQs</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How does the biography writing process work?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our team of professional writers conducts extensive research, interviews, and collaborates closely with you to gather all the necessary information and create a compelling biography that reflects your life story. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I provide my own photographs and documents for the biography?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Absolutely! We encourage clients to share any relevant photographs, documents, or memorabilia that can enrich the biography. Our writers can incorporate these materials into the narrative, adding depth and authenticity to the story.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What if I want to make changes or revisions to the biography?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We understand the importance of getting your story right. We offer multiple rounds of revisions, allowing you to review and provide feedback on the biography. Our goal is to ensure your complete satisfaction with the final product.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does the biography writing process take?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The timeline can vary depending on the complexity of the project and the availability of information. Generally, the process takes several weeks to a few months. We work diligently to deliver a high-quality biography within a reasonable timeframe. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is my personal information and story kept confidential?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Absolutely. At Bellevue Publishers, we prioritize the confidentiality and privacy of our clients. We have strict measures in place to ensure the security of your personal information and the confidentiality of your story throughout the biography writing process. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl  pageTitle = "Begin Your Journey to Success"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default BiographyWriting;
