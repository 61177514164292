import React, { useEffect } from "react"; import { Constant } from "../../Constants";
import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

function Disclaimer() {

  return (
    <>
      <Helmet>
        <title>Disclaimer - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner AboutUsBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-12 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Disclaimer
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      <section className="sec3 aboutSec2Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 innerPageDefaultContent bookContentmain">
            <h1><strong>The Bellevue Publishers Disclaimer</strong></h1>
<p>The information provided on bellevuepublishers.com is intended for general informational purposes only. We do not make any representations or warranties, either expressed or implied, regarding the adequacy, validity, reliability, availability, accuracy, or comprehensiveness of the information featured on the website.</p>
<p>Bellevue Publishers, under no circumstances, shall be held liable for any loss or damage incurred by any individual or entity directly or indirectly through the use of this website. Your visitation, engagement, and provision of any information to Bellevue Publishers are solely at your own risk.</p>
<h3><strong>External Linking Disclaimer</strong></h3>
<p>Bellevue Publishers may include links to third-party websites or pages through external linking or banner advertisements. These websites may be owned by Bellevue Publishers or its subsidiaries. The information, content, activities, and behaviors of these third-party websites are not the responsibility of Bellevue Publishers. The external links have not been investigated, monitored, or evaluated by us. We do not warrant, endorse, guarantee, or assume any responsibility for the information, content, or activities on the third-party websites linked to Bellevue Publishers. If a user clicks on an external link and is redirected to a third-party website, they must read and comply with the policies of that website. We do not take any responsibility for monitoring transactions between users and third-party websites.</p>
<h3><strong>Testimonials Disclaimer</strong></h3>
<p>Bellevue Publishers may feature customer testimonials that highlight clients' interactions with our services. These testimonials reflect the experiences and opinions of specific users and may not represent the complete experience of all users visiting our website. Bellevue Publishers does not claim or imply that all users will have similar experiences.</p>
<p>All testimonials on Bellevue Publishers are submitted as text, audio, or video reviews. Our team approves the content before it is posted. The testimonials featured on Bellevue Publishers represent the clients' words, with minor edits made to enhance grammatical and syntactical accuracy. Some testimonials may be edited for brevity, focusing on relevant information. The reviews featured on Bellevue Publishers are solely based on users' choices and do not necessarily reflect our views and opinions.</p>
<h3><strong>Errors and Omissions Disclaimer</strong></h3>
<p>While Bellevue Publishers has made significant efforts to ensure that all the information provided on the website is sourced from reliable and credible sources, we are not responsible for any errors, omissions, or discrepancies in the information provided. All information on Bellevue Publishers is provided on an "As-Is" basis. We do not guarantee the completeness, accuracy, or timeliness of the information featured on Bellevue Publishers. The information presented on our website does not guarantee any specific outcomes or results. Bellevue Publishers does not provide any warranties, whether expressed or implied, including but not limited to performance, merchantability, or the attainment of specific goals.</p>
<p>&nbsp;</p>
<p>Under no circumstances shall Bellevue Publishers, its partners, affiliates, agents, or employees be liable for any decisions or actions taken based on the information featured on this website or for any consequential, special, or incidental damages incurred directly or indirectly through visiting the website, even if the user has been advised of the possibility of such damages.</p>
<h3><strong>Logos and Trademark Disclaimer</strong></h3>
<p>The logos and trademarks displayed on Bellevue Publishers that originate from third-party sources are the trademarks and logos of their respective owners. The inclusion of these trademarks and logos does not imply or establish approval</p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default Disclaimer;
