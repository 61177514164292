import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Assets from '../../assets/images';
import './styles.css';
import { Link } from "react-router-dom";
import { Constant } from '../../Constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import Popup from "../Popup";
function Footer() {
  const [phoneNumber, setPhoneNumber] = useState('');
  
  function handleKeyPress(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  function handleChange(event) {
    setPhoneNumber(event.target.value);
  }

  const location = useLocation();
  const currentUrl = location.pathname;
  const form = useRef();
  const RedirectUrl = function(url){
    window.location.href = "/thankyou";
  }
const sendEmailPopup = (e) => {
  e.preventDefault();
  $("button.btnPrimary"). attr("disabled", true);
  emailjs.sendForm('service_mpjgvt5', 'template_jw2zrfk', form.current, '960vdqz35TukiHxIX')
    .then((result) => {
     
     $('.popupMain').removeClass("show");
     $('.popupBox').removeClass("show");
      $("button.btnPrimary"). attr("disabled", false);
      $('.formFields input,.formFields textarea').val('');
      // Swal.fire({
      //  icon: "success",
      //   title: "We know the world is full of choices. Thank you for choosing us! ",
      //   text : `Be the author of the next best-selling book! {Constant.CompanyName}, just a click away!`
      // })
      RedirectUrl();
    }, (error) => {
     
      $("button.btnPrimary"). attr("disabled", false);
      Swal.fire({
        icon: "error",
        title: "Ooops, something went wrong",
        text: error.text,
      })
    });
};
  const [navClick, setNavClick] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navClick]);
  useEffect(() => {
    $(".openPopup").on("click", function () {
      $('.popupMain').addClass("show");
      setTimeout(function(){ 
        $('.popupBox').addClass('show');
        }, 10);
    });
    $(".popupClose").on("click", function () {
      $('.popupMain').removeClass("show");
      $('.popupBox').removeClass("show");
    });
  }, []);
  return (
    <>
   <footer>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-md-4'>
              <div className='footerLeft'>
                <span className='footerTitle'>Crafting Stories, 
                  Now Just a Click 
                    Away </span>
                <ul className='footerContactDetails'>
                    <li><a href={Constant.EmailLink}>{Constant.Email}</a></li>
                    <li><a href={Constant.PhoneNumberLink}>{Constant.PhoneNumber}</a></li>
                </ul>
                <ul className='socialFooter'>
                  <li><a href={Constant.FacebookLink} target="_blank"><span>Facebook</span></a></li>
                  <li><a href={Constant.LinkedinLink} target="_blank"><span>Linkedin</span></a></li>
                  <li><a href={Constant.TwitterLink} target="_blank"><span>Twitter</span></a></li>
                </ul>
              </div>
            </div>
            <div className='col-md-8'>
              <div className='footerRightTop'>
                <div className='footerRightWrap'>
                  <ul className='FooterMenu padingRight'>
                        <li><Link to={`/author-website-design/`}  onClick={() => setNavClick(!navClick)}>Author Website Design</Link></li>
                        <li><Link to={`/ebook-writing/`}  onClick={() => setNavClick(!navClick)}>E-Book Writers</Link></li>
                        <li><Link to={`/business-book-writing/`}  onClick={() => setNavClick(!navClick)}>Business Book </Link></li>
                        <li><Link to={`/audiobook-narrators/`}  onClick={() => setNavClick(!navClick)}>Audiobook</Link></li>
                      </ul>
                </div>
                <div className='footerRightWrap'>
                <span className='FooterMenuTitle'><Link to={`/ghostwriting-services/`}  onClick={() => setNavClick(!navClick)}>Ghostwriting</Link></span>
                  <ul className='FooterMenu lightMenu'>
                        <li><Link to={`/fiction-writing/`}  onClick={() => setNavClick(!navClick)}>Fiction </Link></li>
                        <li><Link to={`/non-fiction-writing/`}  onClick={() => setNavClick(!navClick)}>Non-Fiction </Link></li>
                        <li><Link to={`/biography-writing/`}  onClick={() => setNavClick(!navClick)}>Biography Writing </Link></li>
                        <li><Link to={`/memoir-writing/`}  onClick={() => setNavClick(!navClick)}>Memoir Writing </Link></li>
                      </ul>
                </div>
                <div className='footerRightWrap'>
                <span className='FooterMenuTitle'><Link to={`/book-editing-services/`}  onClick={() => setNavClick(!navClick)}>Book Editing</Link></span>
                  <ul className='FooterMenu lightMenu'>
                        <li><Link to={`/book-formatting-services/`}  onClick={() => setNavClick(!navClick)}>Formatting</Link></li>
                      </ul>
                      <ul className='FooterMenu padingRight'>
                      <li><Link to={`/book-publishing/`}  onClick={() => setNavClick(!navClick)}>Book Publishing  </Link></li>
                      <li><Link to={`/book-marketing/`}  onClick={() => setNavClick(!navClick)}>Book Marketing </Link></li>
                      </ul>
                </div>
              </div>
              <div className='copyRighgtBottom'>
                <ul>
                  {/* <li><Link to={`/terms-refund/`}  onClick={() => setNavClick(!navClick)}>Terms & Refund</Link></li> */}
                  <li><Link to={`/privacy-policy/`}  onClick={() => setNavClick(!navClick)}>Privacy Policy</Link></li>
                  <li><Link to={`/disclaimer/`}  onClick={() => setNavClick(!navClick)}>Disclaimer</Link></li>
                  <li><Link to={`/terms-condition/`}  onClick={() => setNavClick(!navClick)}>Terms & Conditions</Link></li>
                </ul>
                <div className='copyRightContent'>
                <p><FontAwesomeIcon icon={faMapMarker} /> {Constant.Address}</p>
                </div>
                <div className='copyRightContent'>
                  <p>© 2022-2023 {Constant.CompanyName}. All Rights Reserved</p>
                  <img src={Assets.dmca} alt="DMCA Images" /> 
                </div>
              </div>
            </div>
        </div>
    </div>
   </footer>
    <Popup/>
   </>
  );
}

export default Footer;
