import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { Link, useLocation,useNavigate  } from "react-router-dom";
import $ from "jquery";
import Footer from "../../components/Footer";
import FooterForm from "../../components/FooterForm";

import { collection, getDocs, query, where } from "firebase/firestore";
import fb from '../../firebaseconfig'

const db = fb.firestore()
const PackageList = db.collection('packages').orderBy("price", "asc");


const PackageView = () => {

    const { id } = useParams();
    
    const [blog, setblog] = useState([]);   
    useEffect(() => {
      // Subscribe to query with onSnapshot
      const unsubscribe = PackageList.onSnapshot(querySnapshot => {
        // Get all documents from collection - with IDs
        const datalist = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        
        datalist.forEach(function(currentValue, index, arr){
          if(currentValue.Slug == id){
            setblog(currentValue);
          }
          
        });
        // Update state
        
      });
      

      // Detach listener
      return unsubscribe;
    }, []);

    return (
      <div >
  <Header/>
  <section className="sec1 innerBanner contactUsBanner">
    <div className="container">
        <div className="row">
          <div className="col-md-12 bannertextLeft">
            <div className="bannerTextInner">
              <h1>
              {blog.title} @ {blog.price}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="sec2 bookContent Aboutsec1Content">
    <div className="container">
      <div className="row">
        <div className="col-md-12 bookContentmain"></div>
        <div className="row">
        
          <div key={blog.id} className='col-md-12'>
            <div className="blogMainBox">
              <div className="blogContent">
              <div dangerouslySetInnerHTML={{__html: blog.body}} />
               
              </div>
            </div>
          </div>         
        </div>
        </div>
    </div>
  </section>
  <FooterForm/>
  <Footer />
</div>
    );
};

export default PackageView;

