import React from "react";
import "./styles.css";
import Assets from "../../assets/images";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

function CtaGirl({pageTitle = "The All-in-One Problem Solver for Ghostwriting Services!"}) {
  return (
    <div className="col-md-4 faqimageright">
    <div className="faqctaimage">
      <span className="ctatilefaq">{pageTitle}</span>
      <div className="faqgirlimg">
          <img src={Assets.faqimageGirl} alt="Platform Images" />
      </div>
    </div>
  </div>
  );
}

export default CtaGirl;
