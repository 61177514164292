import React, { useState, useEffect }from 'react'
import { Link, redirect, useLocation,useNavigate } from "react-router-dom";
import fb from '../../firebaseconfig'
import Header from "../../components/Header";

import $ from "jquery";
import Footer from "../../components/Footer";
import FooterForm from "../../components/FooterForm";

import "./styles.css";
const db = fb.firestore()
const PackagesData = db.collection('packages').orderBy("orderby", "asc");

const DeleteBlog = (id)=> {
  PackagesData.doc(id).delete().then(() => {
      alert("Document successfully deleted!");
  }).catch((error) => {
      console.error("Error removing document: ", error);
  });
};

const RedirectUrl = function(url){
  window.location.href = "/pricing/"+url;
}
const Packages = () => {
  
    const [blogslist, setpackages] = useState([]);

    useEffect(() => {
        // Subscribe to query with onSnapshot
        const unsubscribe = PackagesData.limit(100).onSnapshot(querySnapshot => {
          // Get all documents from collection - with IDs
          const data = querySnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
          }));
          
          // Update state
          setpackages(data);
        });
        setTimeout(() => {
          $( ".feature-list" ).each(function( index ) {
           
          var x=4;
         $(this).closest(".feature-list").find('li:lt('+x+')').show();
        
          $('.loadMore').click(function () {
            $(this).parent().find('.showLess').show();
             $(this).hide();
            
          var size_li =  $(this).closest(".feature-list").find("li").length;
            
              x= size_li;
             $(this).closest(".feature-list").find('li:lt('+x+')').show();
          });
          $('.showLess').click(function () {
             $(this).parent().find('.loadMore').show();
            $(this).hide();
           
              x=4;
              $(this).closest('.feature-list').find("li").not(':lt('+x+')').hide();
          });
      });
        }, "3000");
        
        // Detach listener
        return unsubscribe;
      }, []);
         
    return (
        <div >
      <Header/>
      <section className="sec1 innerBanner contactUsBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-12 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Budget-Friendly End-to-End Publishing Services
                  </h1>
                  <p>Discover affordable and all-inclusive publishing solutions tailored to your needs. Our expert team delivers top-quality results within your budget. From editing to cover design and printing to distribution - we’ve got you covered so you can confidently achieve your publishing goals. </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec2 bookContent Aboutsec1Content">
        <div className="container">
        <div className="content">
	<div className="product-card-container fos container-fluid">
	   <div className="wrapper">
		  <div className="grid">
            {blogslist.length > 0 ?   
            blogslist.map(blog=> (
              <div className="product-card fos">
              <div className="image"></div>
              <h3 className="title">{blog.title}</h3>
              <div className="price-content">
                 <div className="price-content fos">
                  <p className="price-wrapper">
                   <span className="amount">{blog.price}</span>
                  
                  </p>
                 </div>
                 <div className="button-set">
                  <button className="btn btn-md btn-default add-to-cart-button fos" aria-label="Configure your SSD DS-32 server" onClick={()=> {RedirectUrl(blog.id)}} >
                   Package Details
                   </button>
                 </div>
                 <div className="feature-list">
                 <div dangerouslySetInnerHTML={{__html: blog.Lists}} />
                 <div className="loadMore">Read more</div>
                 <div className="showLess">Show less</div>
                 </div>
              </div>
              </div>
              
                   
            ))
            :
            <>
            <h3>No Packages Found</h3>
            </>
          }
		  </div>
    
      </div>
      <div className="bundlepackage">
      <table className="table table-bordered">
<thead>
<tr>
<th>
<p className="mainheading">Services</p>
</th>
<th>
<p className="heading1">Basic Publishing Package&nbsp;</p>
<p className="price">$1000</p>
</th>
<th>
<p className="heading1">Standard Publishing Package</p>
<p className="price">$2000</p>
</th>
<th>
<p className="heading1">Professional Publishing Package</p>
<p className="price">$3000</p>
</th>
<th>
<p className="heading1">Expert Publishing Package</p>
<p className="price">$5000</p>
</th>
</tr>
</thead>
<tbody>
   <tr>
      <td>Promoted Publishing</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>12-months contract</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Guaranteed Listing as Bestseller</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>SEO Marketing</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Keyword Optimization</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Social Media Marketing</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Physical Distribution Worldwide</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Bestselling Platforms</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Free 3D Layout &amp; Cover Designs</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Illustrations</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>End-to-End services</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Digital Print Ready File.</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Author's Copies</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Content Marketing</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Trending Hashtags</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Reviews on top-rated&nbsp;</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Hollywood Feedback &amp; Reviews</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Social Media Profile Creation</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Influencer Marketing&nbsp;</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Author's Website</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Video Book Trailer</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Amazon Certified Tag on profile</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Author Central</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Dedicated Online Dashboard</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Author’s Interview</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Introductory Video</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>5-Star Ratings</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Genre-based Rankings</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>100% Ownership Rights</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>ISBN and Barcode</td>
      <td>
         <p className="color-red">✖</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Dedicated Project Manager</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Free Consultation</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>100% Satisfaction Guaranteed</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Quick Turnaround Time</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
   <tr>
      <td>Print-on-Demand &nbsp;</td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
      <td>
         <p className="color-green">✔</p>
      </td>
   </tr>
</tbody>
</table></div>
	   </div>
	</div>
 
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </div>
    );
  };

export default Packages;
