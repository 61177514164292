import React from 'react';
import Home from "./routes/Home";
import "bootstrap/dist/css/bootstrap.min.css";
//https://reactrouter.com/en/main/start/tutorial
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import GhostWriting from "./routes/GhostWriting";
import Fiction from "./routes/Fiction";
import Drama from "./routes/Drama"; 
import Childern from "./routes/Childern";
import Crime from "./routes/Crime";
import Suspenses from "./routes/Suspense";
import Romance from "./routes/Romance";
import Horror from "./routes/Horror";
import Action from "./routes/Action";
import Scifi from "./routes/Scifi";
import Adventure from "./routes/Adventure";
import Audiobook from "./routes/Audiobook";
import AuthorWebDesign from "./routes/AuthorWebDesign";
import BiographyWriting from "./routes/BiographyWriting";
import BusinessBook from "./routes/BusinessBook";
import EBookWriting from "./routes/EBookWriting";
import MemoireWriting from "./routes/MemoireWriting";
import NonFiction from "./routes/NonFiction";
import BookMarketing from "./routes/BookMarketing";
import BookEditing from "./routes/BookEditing";
import BookFormatting from "./routes/BookFormatting";
import AboutUs from "./routes/AboutUs";
import ContactUs from "./routes/ContactUs";
import BookPublishing from "./routes/BookPublishing";
import Fantasy from "./routes/Fantasy";
import Disclaimer from "./routes/Disclaimer";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import TermsCondition from "./routes/TermsCondition";
import TermsRefund from "./routes/TermsRefund";
import "./assets/css/responsive.css";
import NotFound from "./routes/NotFound";
//import Checkout from "./routes/Checkout";
import BookPrinting from './routes/BookPrinting';
import Packages from './routes/Packages';
import PackageView from './routes/PackageView';
import LandingPage1 from './routes/LandingPage1';
import ThankYou from './routes/ThankYou';
// blog 
import Blogs from './routes/Blog';
import HowToFindTheFinestQualityGhostwritingServices1 from './routes/howToFindTheFinestQualityGhostwritingServices'
import TipsForWritingABookThatGetsNoticed from './routes/10-tips-for-writing-a-book-that-gets-noticed'
import AStepByStepGuideToWritingABookFromStartToFinish from "./routes/aStepByStepGuideToWritingABookFromStartToFinish"
import CreativeTipsToAvoidProcrastinationWhileWritingABook from './routes/10-creative-tips-to-avoid-procrastination-while-writing-a-book'
import HowToWriteABlogIn10SimpleSteps from './routes/how-to-write-a-blog-in-10-simple-steps'
import NovelsThatAreConsideredTheGreatestBooksEverWritten from './routes/6-novels-that-are-considered-the-greatest-books-ever-written'
import ACompleteGuideToWriteHorrorFictionNovelIn8EasySteps from './routes/a-complete-guide-to-write-horror-fiction-novel-in-8-easy-steps'
import BeginnersGuideToCraftingACompellingCrimeFictionNovel from './routes/a-beginners-guide-to-crafting-a-compelling-crime-fiction-novel'
import HowToWriteAnAutobiographyIn5SimpleSteps from './routes/how-to-write-an-autobiography-in-5-simple-steps'
import AllYouNeedToKnowAboutThe5MostInterestingKdpSelfPublishingTools from './routes/all-you-need-to-know-about-the-5-most-interesting-kdp-self-publishing-tools'
import HowToFindOutTheBestBookPublishersToCaterAllYourPublishingNeeds from './routes/how-to-find-out-the-best-book-publishers-to-cater-all-your-publishing-needs'
import HowToCreateWinningBookPublishingStrategy from './routes/how-to-create-winning-book-publishing-strategy'// import CreateBlog from './routes/CreateBlog'
import Top6TipsForWritingANovelEveryoneWantsToRead from './routes/top-6-tips-for-writing-a-novel-everyone-wants-to-read'
import TheSimplestWayToGetAnIsbnNumberAsASelfPublishedAuthor from './routes/the-simplest-way-to-get-an-isbn-number-as-a-self-published-author'
import HowToWriteABookIn5SimpleSteps from "./routes/how-to-write-a-book-in-5-simple-steps"
import HowToSelfPublishYourBookBellevuePublishers from "./routes/how-to-self-publish-your-book-bellevue-publishers"



const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/ghostwriting-services",
    element: <GhostWriting />,
  },
  {
    path: "/fiction-writing",
    element: <Fiction/>,
  },
  {
    path: "/drama-writing",
    element: <Drama/>,
  },
  {
    path: "/childern-writing",
    element: <Childern/>,
  },
  {
    path: "/mystery-writing",
    element: <Crime/>,
  },
  {
    path: "/suspense-thriller-writing",
    element: <Suspenses/>,
  },
  {
    path: "/romance-writing",
    element: <Romance/>,
  },  
  {
    path: "/horror-book-writing",
    element: <Horror/>,
  },
  {
    path: "/action-writing",
    element: <Action/>,
  },
  {
    path: "/science-fiction-writing",
    element: <Scifi/>,
  },
  {
    path: "/adventure-writing",
    element: <Adventure/>,
  },
  {
    path: "/audiobook-narrators",
    element: <Audiobook/>,
  },
  {
    path: "/author-website-design",
    element: <AuthorWebDesign/>,
  },
  {
    path: "/biography-writing",
    element: <BiographyWriting/>,
  },
  {
    path: "/business-book-writing",
    element: <BusinessBook/>,
  },
  {
    path: "/ebook-writing",
    element: <EBookWriting/>,
  },
  {
    path: "/memoir-writing",
    element: <MemoireWriting/>,
  },
  {
    path: "/non-fiction-writing",
    element: <NonFiction/>,
  },
  {
    path: "/book-marketing",
    element: <BookMarketing/>,
  },
  {
    path: "/book-editing-services",
    element: <BookEditing/>,
  },
  {
    path: "/book-formatting-services",
    element: <BookFormatting/>,
  },
  {
    path: "/book-publishing",
    element: <BookPublishing/>,
  },
  {
    path: "/about-us",
    element: <AboutUs/>,
  },
  {
    path: "/contact-us",
    element: <ContactUs/>,
  },
  {
    path: "/fantasy-writing",
    element: <Fantasy/>,
  },
  {
    path: "/disclaimer",
    element: <Disclaimer/>,
  },
    {
    path: "/privacy-policy",
    element: <PrivacyPolicy/>,
  },
  {
    path: "/terms-condition",
    element: <TermsCondition/>,
  },
  {
    path: "/terms-refund",
    element: <TermsRefund/>,
  },
  {
    path: "/book-printing",
    element: <BookPrinting/>,
  },
  // {
  //   path: "/payments/",
  //   element: <Checkout/>,
  // },
  {
    path: "/thankyou",
    element: <ThankYou />,
  },
  
  {
    path: "/pricing",
    element: <Packages/>,
  },
  {
    path: "/pricing/:id",
    element: <PackageView/>,
  },
  {
    path: "/cookbook/publishing",
    element: <LandingPage1/>,
  },
  {
    path: "/blog",
    element: <Blogs/>,
  },
  {
    path: "/blog/how-to-self-publish-your-book-bellevue-publishers",
    element: <HowToSelfPublishYourBookBellevuePublishers />,
  },
  {
    path: "/blog/how-to-write-a-book-in-5-simple-steps",
    element: <HowToWriteABookIn5SimpleSteps />,
  },
  {
    path: "/blog/the-simplest-way-to-get-an-isbn-number-as-a-self-published-author",
    element: <TheSimplestWayToGetAnIsbnNumberAsASelfPublishedAuthor />,
  },
  {
    path: "/blog/top-6-tips-for-writing-a-novel-everyone-wants-to-read",
    element: <Top6TipsForWritingANovelEveryoneWantsToRead />,
  },
  {
    path: "/blog/how-to-create-winning-book-publishing-strategy",
    element: <HowToCreateWinningBookPublishingStrategy />,
  },
  {
    path: "/blog/how-to-find-out-the-best-book-publishers-to-cater-all-your-publishing-needs",
    element: <HowToFindOutTheBestBookPublishersToCaterAllYourPublishingNeeds />,
  },
  {
    path: "/blog/all-you-need-to-know-about-the-5-most-interesting-kdp-self-publishing-tools",
    element: <AllYouNeedToKnowAboutThe5MostInterestingKdpSelfPublishingTools />,
  },
  {
    path: "/blog/how-to-write-an-autobiography-in-5-simple-steps",
    element: <HowToWriteAnAutobiographyIn5SimpleSteps />,
  },
  {
    path: "/blog/a-beginners-guide-to-crafting-a-compelling-crime-fiction-novel",
    element: <BeginnersGuideToCraftingACompellingCrimeFictionNovel />,
  },
  {
    path: "/blog/a-complete-guide-to-write-horror-fiction-novel-in-8-easy-steps",
    element: <ACompleteGuideToWriteHorrorFictionNovelIn8EasySteps />,
  },
  {
    path: "/blog/10-creative-tips-to-avoid-procrastination-while-writing-a-book",
    element: <CreativeTipsToAvoidProcrastinationWhileWritingABook />,
  },
  {
    path: "/blog/6-novels-that-are-considered-the-greatest-books-ever-written",
    element: <NovelsThatAreConsideredTheGreatestBooksEverWritten />,
  },
  {
    path: "/blog/how-to-write-a-blog-in-10-simple-steps",
    element: <HowToWriteABlogIn10SimpleSteps />,
  },
  {
    path: "/blog/a-step-by-step-guide-to-writing-a-book-from-start-to-finish",
    element: <AStepByStepGuideToWritingABookFromStartToFinish />,
  },
  {
    path: "/blog/how-to-find-the-finest-quality-ghostwriting-services",
    element: <HowToFindTheFinestQualityGhostwritingServices1 />,
  },
  {
    path: "/blog/10-tips-for-writing-a-book-that-gets-noticed",
    element: <TipsForWritingABookThatGetsNoticed />,
  },
]);

function App() {
  return (
    <div className="wrapper">
      <div id="content">
        <React.StrictMode>
          <RouterProvider router={router} />
        </React.StrictMode>
      </div>
    </div>
  );
}

export default App;
