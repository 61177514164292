import React from "react";

import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Helmet } from "react-helmet";

function ThankYou() {
  return (
    <>
      <Helmet>
      <title>Thank You - Bellevue Publishers</title>
      </Helmet>
      <Header />
      <section className="sec1 innerBanner AboutUsBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bannertextLeft">
              <div className="bannerTextInner">
                <h1>Thank You for Signing Up!</h1>
                <p>
                One Of Our Representatives Will Get Back To You Shortly.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="sec2 bookContent Aboutsec1Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bookContentmain">
              <h3 className="heading2">Thank you for you message.</h3>
              <p>
              Our Team will contact you soon.{" "}
              </p>
            </div>
          </div>
        </div>
      </section> */}
     
      {/* <FooterForm /> */}
      <Footer />
    </>
  );
}
export default ThankYou;
