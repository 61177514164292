import React, { useEffect,useState } from "react"; import { Constant } from "../../Constants";
import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
function PrivacyPolicy() {
  
  return (
    <>
      <Helmet>
      <title>Privacy Policy - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner AboutUsBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-12 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Privacy Policy
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      <section className="sec3 aboutSec2Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 innerPageDefaultContent bookContentmain">
             <h1><strong>Privacy Policy</strong></h1>
            <h3 className="heading2"><strong>Introduction</strong></h3>
<p>Welcome to Bellevue Publishers. We highly value your privacy and are committed to protecting your personal information. Our Privacy Policy is regularly updated to align with current practices and legal requirements and is governed by the Terms and Conditions that apply to our website.</p>
<p>Throughout this Privacy Policy, the terms “we,” “us,” “our,” and “service” refer to Bellevue Publishers’s website, located at www.bellevuepublisher.com. By visiting our website, you acknowledge and accept the terms outlined in this Privacy Policy, which governs data collection, sharing, and disclosure after your visit.</p>
<p>The data we collect during your visit is used to enhance our services and provide an improved user experience. By utilizing our service, you consent to the collection and usage of data as described in this policy.</p>
 <h3 className="heading2"><strong>Terminology</strong></h3>
<p>In this Privacy Policy, the following terms are used:</p>
<p><strong>“Service”</strong> refers to Bellevue Publishers.</p>
<p><strong>“Personal Data”</strong> refers to information that can identify an individual, including but not limited to name, address, email, contact number, and bank details.</p>
<p><strong>“Usage Data”</strong> refers to automated data collected during visits to Bellevue Publishers, which may include, but is not limited to, the duration of the visit.</p>
<p><strong>“Cookies”</strong> are small data blocks stored by a web browser that enable servers to recognize a user’s return to a specific webpage.</p>
<p><strong>“Data Controller”</strong> refers to the legal entity or individual(s) responsible for determining the purpose and methods of collecting and processing personal data. Bellevue Publishers acts as the data controller for your data according to the terms of this Privacy Policy.</p>
<p><strong>“Data Processor”</strong> refers to the legal entity or individual(s) that processes data on behalf of the Data Controller to achieve more effective results. Bellevue Publishers may engage the services of Data Processors to enhance your experience on our website.</p>
<p><strong>“Data Subject”</strong> refers to the individual whose data is being collected.</p>
<p><strong>“User”</strong> refers to individuals using our service.</p>
 <h3 className="heading2"><strong>Information Collection and Use</strong></h3>
<p>Bellevue Publishers collects various types of data to provide an enhanced service. The following types of information are gathered:</p>
<p>&nbsp;</p>
<p><strong>Personal Data</strong></p>
<p>Bellevue Publishers may collect and store your Personal Data, which includes, but is not limited to, the following:</p>
<ul>
    <li>Your full name</li>
    <li>Your email address</li>
    <li>Your phone number</li>
    <li>Your complete address</li>
    <li>Cookies and usage data</li>
</ul>
<p>Bellevue Publishers may collect your Personal Data through direct contact, newsletters, marketing or promotional materials, and other means. While you have the option to withhold this information, doing so may limit the extent of services we can provide.</p>
<p><strong>Usage Data</strong></p>
<p>When you visit Bellevue Publishers, your browser may automatically collect “Usage Data.” This information may include, but is not limited to, the following:</p>
<ul>
    <li>Your device’s IP address</li>
    <li>The date and time of your visit</li>
    <li>The duration of your visit to Bellevue Publishers and its pages</li>
    <li>Other diagnostic identifiers</li>
</ul>
<p><strong>Location Data</strong></p>
<p>Bellevue Publishers may collect and store information about your visit’s location with your explicit consent. This enables us to offer personalized services tailored to your specific location.</p>
<p><strong>Cookies Data</strong></p>
<p>Bellevue Publishers employs cookies or similar technologies such as beacons, tags, and scripts to analyze your interactions with our website. You have the option to disable cookies in your browser settings to prevent this type of data collection. However, please note that doing so may limit your access to certain components of our service.</p>
<p>Bellevue Publishers may collect the following:</p>
<ul>
    <li>Session Cookies</li>
    <li>Preference Cookies</li>
    <li>Security Cookies</li>
    <li>Advertising Cookies</li>
    <li>Other Data</li>
</ul>
<p>Bellevue Publishers may also collect additional information necessary to provide our services. This may include data such as your age, gender, place of birth, passport details, citizenship, registration, address, contact information, educational documents, qualifications, professional training, employment agreements, non-disclosure agreements (NDAs), marital status, social security number, taxpayer identification, office location, and other mandatory information.</p>
 <h3 className="heading2"><strong>Purpose of Data Collection</strong></h3>
<ul>
    <li>Bellevue Publishers collects data for various purposes, including:</li>
    <li>Enhancing the quality of our service</li>
    <li>Providing updates on service changes</li>
    <li>Improving customer service</li>
    <li>Conducting detailed analysis to enhance the customer experience</li>
    <li>Monitoring service usage</li>
    <li>Identifying and resolving technical issues</li>
    <li>Achieving other objectives outlined in this Privacy Policy</li>
    <li>Ensuring compliance with service agreements and informing users about the terms and conditions</li>
    <li>Maintaining records related to billing and collections</li>
    <li>Providing updates on your account or subscription to our service</li>
    <li>Sharing news, discount offers, and other information about Bellevue Publishers’s services</li>
    <li>Fulfilling any other objectives with your consent</li>
</ul>
 <h3 className="heading2"><strong>Data Storage</strong></h3>
<p>Bellevue Publishers will collect and store your data for the necessary duration to fulfill the purposes stated in this Privacy Policy. We ensure that your personal data is securely stored in accordance with legal requirements and state policies.</p>
<p>Your usage data will also be stored temporarily for internal analysis. However, in cases where legal obligations arise, we may be required to retain usage data for a longer period.</p>
 <h3 className="heading2"><strong>Data Transfer</strong></h3>
<p>Bellevue Publishers takes measures to protect your data from unauthorized access. We employ robust security controls to ensure that your data is treated securely and not transferred to any organization without your consent. If data transfer is necessary, such as in a merger or when relying on a third-party payment provider, we ensure that the transfer occurs with strict security measures in place.</p>
 <h3 className="heading2"><strong>Disclosure of Data</strong></h3>
<p>Your personal data may be disclosed in the following circumstances:</p>
<p><strong>Law Enforcement</strong></p>
<p>We may be legally obligated to disclose your personal information to law enforcement authorities.</p>
<p><strong>Mergers and Business Acquisitions</strong></p>
<p>In the event of a merger or asset sale involving Bellevue Publishers, your personal data may be transferred.</p>
<p>&nbsp;</p>
<p><strong>Other Situations</strong></p>
<p>Your data may also be disclosed to third-party services in the following cases:</p>
<ul>
    <li>When required by Bellevue Publishers’s subsidiaries and affiliates</li>
    <li>When necessary for contractors or other third-party service providers who support our services</li>
    <li>When serving the purposes for which you provided the information and with your consent</li>
</ul>
 <h3 className="heading2"><strong>Data Security</strong></h3>
<p>Bellevue Publishers prioritizes the utmost security measures for collecting, storing, and transferring data.</p>
 <h3 className="heading2"><strong>Third-Party Services</strong></h3>
<p>Bellevue Publishers may engage trusted third-party companies and resources to enhance various aspects of our service, such as website optimization and payment processing. These third-party entities will only receive relevant data necessary to fulfill their assigned responsibilities, and we strictly prohibit any unauthorized use or disclosure of data.</p>
 <h3 className="heading2"><strong>Analytics</strong></h3>
<p>Bellevue Publishers may utilize the services of reputable third-party providers, including Google Analytics, to evaluate and improve our services.</p>
 <h3 className="heading2"><strong>CI/CD Tools</strong></h3>
<p>Bellevue Publishers may employ third-party services to automate and streamline service development procedures.</p>
 <h3 className="heading2"><strong>Behavioral Remarketing</strong></h3>
<p>Bellevue Publishers may utilize remarketing techniques to promote our services on other websites after you have visited our platform. This may involve leveraging third-party vendors and cookies to deliver tailored advertisements based on your browsing behavior on our website.</p>
 <h3 className="heading2"><strong>Payment</strong></h3>
<p>Bellevue Publishers may offer paid services within our platform. In such cases, we collaborate with trusted third-party payment processors who adhere to their own Privacy Policies. These payment processing services comply with the regulations set by PCI-DSS and PCI Security Standards Council.</p>
 <h3 className="heading2"><strong>External Linking</strong></h3>
<p>Bellevue Publishers may provide links to external websites for your convenience. However, we encourage you to review the Privacy Policies of these third-party websites, as Bellevue Publishers does not assume responsibility for their content, behavior, or activities.</p>
<p>&nbsp;</p>
 <h3 className="heading2"><strong>Age of Consent</strong></h3>
<p>Bellevue Publishers strictly collects Personal Information only from individuals who have reached the age of consent as defined by the relevant state laws. We do not knowingly collect information from children below the age of consent. If we discover that a user has provided such information without our knowledge, we take immediate action to cancel their subscriptions and delete all associated records.</p>
 <h3 className="heading2"><strong>Amendments and Updates</strong></h3>
<p>Bellevue Publishers reserves the right to update, modify, or revise our Privacy Policy at any time without prior notice. We encourage you to regularly review the Privacy Policy page on our website (www.bellevuepublisher.com) to stay informed about any changes. The effective date at the top of our Privacy Policy page indicates the most recent modification.</p> 
             </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default PrivacyPolicy;
