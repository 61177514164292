import React from "react";
import "./styles.css";
import Assets from "../../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import $ from "jquery";
import { Constant } from "../../Constants";
function Testimonials() {
  return (
    <section className="sec12 TestiSliderMain">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 whyChooseLeft">
          <div className="abtLeft">
            <span className="smallTitleLine">Testimonials</span>
            <h4>
            Don't take our word for it; see for yourself!
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-12 blogSlider">
        <Swiper
          slidesPerView={3}
          spaceBetween={120}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          centeredSlides={false}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={false}
          breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">DB </span>
            <div className="nameStars">
              <span className="clientNameTesti">Dove Barnham </span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I must say, Bellevue Publishers wasn’t my first choice when it comes to ghostwriting. I had a very bad experience with my previous book-writing agency. But things took a turn when I stumbled across the Bellevue Publishers website. They helped me through my journey, and their professionalism and commitment to my project really made this experience worthwhile.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">CA</span>
            <div className="nameStars">
              <span className="clientNameTesti">Constantine Aubergine </span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I used to write short suspense thrillers, but I realized writing horror stories is a different ball game altogether. After my consultation session with them, I decided to opt for Bellevue Publishers. The service and time management are great here.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">RG</span>
            <div className="nameStars">
              <span className="clientNameTesti">Ron Guzman </span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I wrote several rough drafts and sent the manuscript for multiple revisions. Still, Bellevue Publishers always helped me out with no complaints! Great work, guys!</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">CR</span>
            <div className="nameStars">
              <span className="clientNameTesti">Christina Rose </span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>The Mystery is hard to write, even for authors who are not rookies. Bellevue Publishers helped me with all the nitty gritty details of fiction and created a piece of heart! This collaboration will always remain special to me!</p>
          </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">LB</span>
            <div className="nameStars">
              <span className="clientNameTesti">Lilly Anne Bowman </span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I want my fiction book to be filled with action; through Bellevue Publishers and their action writing services, I was able to find a good mix of these two genres. Thanks to the team and the project manager for making this process smooth.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">DR</span>
            <div className="nameStars">
              <span className="clientNameTesti">Dorothy K. Richard </span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Through the sci-fi cinema and movies of Christopher Nolan, I found myself deeply fascinated with the sci-fi genre and wanted to write something of my own. The ghostwriting services from Bellevue Publishers made it so easy for me to write the first book that now I’m planning to write a series of them!</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">DE</span>
            <div className="nameStars">
              <span className="clientNameTesti">Derek Elvis </span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Working with Bellevue Publishers was a game changer for my business book. Their team of writers truly understood the message and tone I was going for, and their attention to detail was exceptional. The final product was exactly what I asked for, and I couldn’t be happier with the end results. </p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">JL</span>
            <div className="nameStars">
              <span className="clientNameTesti">Janet Laurel  </span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I recently used Bellevue Publishers for my romance novel, and I am extremely satisfied with the results. The team of writers was professional, responsive, and easy to work with through the entire process. </p>
          </div>
            </div>
          </SwiperSlide>
          
        </Swiper>

        </div>
      </div>
    </div>
  </section>
  );
}

export default Testimonials;
