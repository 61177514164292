import React, { useEffect } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLongArrowAltRight,
  faStar,
  faComment,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Navigation, Autoplay, EffectFade } from "swiper";
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Process from "../../components/Process";
import Girlquote from "../../components/Girlquote";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import GridFictionSec from "../../components/GridFictionSec";
import Portfolio from "../../components/Portfolio";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function BookPublishing() {
  useEffect(() => {
    $("[data-targetit]").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      var target = $(this).data("targetit");
      $("." + target)
        .siblings('[class^="box-"]')
        .hide();
      $("." + target).fadeIn(100);
    });
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  const navigate = useNavigate();

//navigate(0)
     
  return (
    <>
      <Helmet>
        <title>Book and eBook Publishing Services for Authors - Bellevue</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner bookpublishingBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Publish Your Book with Confidence and Professionalism
                  </h1>
                  <p>
                  Discover a Wide Range of Publishing Services Tailored to Your Needs. From Manuscript Editing to Cover Design and Distribution, We've Got You Covered. Join Our Community of Successful Authors and Bring Your Book to Life. Contact Us Today and Take the First Step Towards Publishing Success.
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency bookPubsec3">
        <div className="container">
          <div className="row">
          <div className="col-md-6 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Experience Excellence in Publishing with Bellevue Publishers.</h4>
                <p>
                Embark on a Journey of Publishing Excellence with Bellevue Publishers. We offer a comprehensive range of services to help you transform your manuscript into a professionally published book. From expert editing and captivating cover design to strategic marketing and global distribution, we have the tools and expertise to make your publishing dreams a reality. Join our community of successful authors and experience the satisfaction of seeing your book in the hands of readers worldwide. Contact us today to begin your publishing adventure.
                </p>
                <p>According to recent studies, self-published authors who utilize professional publishing services witness a significant increase in book sales by up to 32%. Our expert team ensures high-quality editing, eye-catching cover design, effective marketing strategies, and global distribution, maximizing your chances of success in the competitive publishing industry. Ignite your publishing journey today!</p>
                <p>Choose Bellevue Publishers for Unmatched Book Publishing Services. With a track record of success, our team of experienced professionals provides comprehensive support at every step of your publishing journey. From meticulous editing to captivating cover design and effective marketing strategies, we ensure your book stands out in the market. Trust our expertise and industry knowledge for a seamless publishing experience that exceeds your expectations.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 reliable-agencyRight">
              <img
                        src={Assets.booksectionanimation}
                        
                      />
            </div>
          </div>
        </div>
      </section>
      <section className="ghostTabs">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostabsrow">
                    <span>Unleash Your Creativity with Our Book Publishing Experts</span>
                </div>
                <div className="col-md-6 ghostabsmain">
                    <div className="custom-tab-list-ghost">
                    <ul>
                    <li data-targetit="box-bookpro" className="active">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookpublishingtab1}  /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Cover Design </span>
                            <p>Our team of talented designers and illustrators can bring your vision to life and create stunning book covers that capture your ideas. </p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-authweb" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookpublishingtab2}  /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Book Promotion </span>
                            <p>Promoting your book is crucial for success as an author. We provide effective marketing strategies to help you reach your target audience. </p>
                         </div>
                        </a>
                    </li>
                    <li data-targetit="box-bookfr" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookpublishingtab3}  /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Editorial Review </span>
                            <p>Our skilled editors offer meticulous proofreading and editing services to polish your manuscript and ensure its readiness for publishing.</p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-proofed" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookpublishingtab4}  /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">High-Quality Production Services</span>
                            <p>From formatting to printing, we handle every aspect of production to deliver top-notch quality books to readers worldwide. </p>
                        </div>
                        </a>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="col-md-6 ghostdetails">
                {/* <div className="box-bookpro showfirst">
                <div className="tabCont">
                  <p>
                  Because your manuscript is the foundation of your book, take the time to write and thoroughly revise it. Check that your story or message is clear and engaging, and that your writing is polished and free of errors. You might want to hire an editor to help you improve your writing. From trim size, designs, high-resolution photographs, font choices and many more.   </p>
                  <p>Research and select the right publisher; there are many publishers out there, so do your research to find the ones that are compatible with the genre and target audience of your book. Look for publishers with a good reputation, strong distribution channels, and a successful track record in your field of interest. This is where {Constant.CompanyName} come in, where you can find track records of reputable clients and make your decision after a complete and thorough research!   </p>
                  <p>Make a professional book proposal: A book proposal is a document that outlines the key aspects of your book, such as the synopsis, target audience, marketing strategy, and author bio. A well-written book proposal can help you stand out to publishers, increasing your chances of landing a book deal.

                  </p>
                </div>
              </div> */}
              <div className="box-authweb showfirst">
                <div className="tabCont">
                  <p>
                  <b>Craft a Solid Foundation for Your Book Manuscript:</b> Take the necessary time to write and revise your manuscript, ensuring clarity, engagement, and polished writing. Consider hiring an editor to enhance your work. From choosing the right trim size and designs to incorporating high-resolution photographs and selecting appropriate fonts, we pay attention to every detail that brings your book to life.</p>
                </div>
              </div>
              <div className="box-bookfr">
                <div className="tabCont">
                  <p><b>Discover the Perfect Publisher for Your Book:</b> Thoroughly research and select a publisher that aligns with your book's genre and target audience. Seek out reputable publishers with strong distribution networks and a proven track record in your field. At The Universal Writers, we provide a platform where you can explore the track records of our esteemed clients, empowering you to make an informed decision after conducting comprehensive and diligent research.</p>
                </div>
              </div>
              <div className="box-proofed">
                <div className="tabCont">
                  <p><b>Craft an Impressive Book Proposal for Success:</b> Develop a comprehensive book proposal that highlights essential elements of your manuscript, including the synopsis, target audience, marketing strategy, and author bio. A meticulously crafted book proposal sets you apart from the crowd, capturing the attention of publishers and enhancing your prospects of securing a book deal. Our team at The Universal Writers can guide you in creating a compelling book proposal that maximizes your chances of success in the publishing industry.</p>
                </div>
              </div>
                </div>
            </div>
        </div>
      </section>
      <section className="sec15 scroreBig">
        <div className="container">
          <div className="row">
            <div className="col-md-6 scrowHas">
              <span className="scoreTitle"><b>Unleash Incredible Savings <br></br>Get Lucrative Deals!</b></span>
              <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Choose Us as Your Publishing Experts?
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.bookpublishing1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Self-Publishing Made Easy </span>
                      <p>
                      In the digital era, we guide authors through the seamless process of self-publishing, selecting the perfect platforms, formats, and niches for your books.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookpublishing2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Comprehensive Pre-Publishing Services </span>
                      <p>
                      From source verification to citation, typesetting, copyediting, and formatting, we handle every aspect of the publishing process with meticulous care.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookpublishing3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Affordable and Effective Marketing </span>
                      <p>
                      Our dedicated book publishers prioritize organic leads and sales, implementing targeted marketing strategies to boost engagement and maximize sales through social media channels.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookpublishing4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Expert Acquisition and Editing</span>
                      <p>
                      Our experienced team assesses your manuscript's potential, staying updated on market trends and ensuring every step aligns with industry standards for aspiring authors.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Ctafirst Title="Streamline Your Book Publishing Journey" Text="Connect with us now to get the best deals!"/>
      <section className="sec9 philMain ProContent">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ProContentLeft">
              <div className="abtLeft">
                <h4>Get Your Books Published Worldwide and Establish Your Authorship!</h4>
                <p>
                Our team of publishing experts goes above and beyond to make sure that every detail of your book is carefully taken care of. At Bellevue Publishers, we're experts at handling many formats so your books work on a wide range of platforms and devices. This increases your audience and makes it possible for more readers to be impacted by your writing. We provide all-encompassing assistance for self-publishing your book.
                </p>
                <p>You may concentrate on polishing your writings while we take care of the details. With a plethora of expertise working on several books, our outstanding book publishers have a deep understanding of your target market and niche.</p>
                <div className="makesContent">
                  <ul>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Self-publishing assistance </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Paperback editions </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Customized book covers </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Editorial reviews </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Expertise in multi-platform compatibility </li>
                  </ul>
                </div>
                <div className="ctaWrap">
                  <div className="aboutCta">
                    <ButtonPrimary />
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 proconImageLeft">
              <img src={Assets.proImage} alt="Platform Images" />
            </div>
          </div>
        </div>
      </section>
      <GridFictionSec/>
      <Process/>
      <Ctaorange Title="Streamline Your Book Publishing Journey With Our All-Inclusive Services." Text="Contact us today to secure the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">FAQs about Book Publishing </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do I need a literary agent to get published?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    While having a literary agent can provide numerous benefits, it is not a requirement for getting published. Many authors choose to pursue self-publishing or work directly with publishers.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does the book publishing process typically take?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>The timeline for book publishing can vary depending on several factors, including the complexity of the manuscript, the editing and revision process, and the publishing company's workflow. On average, it can take anywhere from several months to a year or more.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are the costs involved in publishing a book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The costs of publishing a book can vary depending on the publishing route you choose. Traditional publishing typically covers editing, cover design, printing, and distribution costs. Self-publishing may require upfront investments for editing, cover design, marketing, and distribution services.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I protect my copyright as an author?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Copyright protection is automatically granted to authors as soon as their work is created. However, it is advisable to register your copyright with the relevant authorities to have a stronger legal standing in case of infringement.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the difference between traditional publishing and self-publishing?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Traditional publishing involves submitting your manuscript to publishing houses, which handle editing, design, printing, distribution, and marketing. Self-publishing gives you more control as you oversee the entire process, from editing to cover design, marketing, and choosing distribution channels.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Contact us today, and let us be your trusted partner"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default BookPublishing;
