import React, { useEffect } from "react"; 
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Girlquote from "../../components/Girlquote";
import Testimonials from "../../components/Testimonials";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function BookFormatting() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
  }, []);
  const navigate = useNavigate();

//navigate(0)
     
  return (
    <>
      <Helmet>
        <title>Book & eBook Formatting Services | Book Formatter for Hire </title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner bookFormattingBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Stay ahead of the formatting game with our cutting-edge techniques.
                  </h1>
                  <p>
                  Discover the power of professional book formatting that adapts to the latest trends, engages readers, and boosts your success.
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Format your words. Inspire the world.</h4>
                <p>
                When it comes to the art of book publishing, formatting reigns supreme. A well-formatted book can take your readers on a journey, captivating them with each page turn. It’s the difference between a bestseller and a forgotten manuscript. Bellevue Formatting knows that each book is unique and deserves to be treated as such. Their meticulous attention to detail and artistic eye brings out the essence of your work, creating a truly immersive experience for your readers. With Bellevue Formatting, your book will not only look professional, but it will stand out among the crowd, leaving a lasting impression on all who read it.
                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.bookformattingHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Give your book a professional edge with our expert formatting services" Text="Connect with us now to get the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Choose Our Book Formatting Services?
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.bookformattingservices1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Publishing Manuscripts</span>
                      <p>
                      {Constant.CompanyName} provide an affordable means of book formatting; our team of experts not only helps you identify incompetent formatting errors but also helps you edit and proofread with self-publishing book formatting standards. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookformattingservices2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Compatible Files</span>
                      <p>
                      Print-ready PDF files, Epub, and Mobi-compatible formats to provide compatibility for users on multiple platforms and devices. Our team of editors formats books of all genres, like poetry, cookbooks, a compilation of short stories, and much more. 
                      </p>
                    </div>
                  </div>

                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookformattingservices3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Layouts and Structures</span>
                      <p>
                      Unique interior book layouts for an enjoyable read. Appropriate Table of Content that conveniently aligns with all the chapters for the readers.
                      </p>
                    </div>
                  </div>

                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookformattingservices4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Accurate Front Matter</span>
                      <p>
                      The matter is laid-out to perfection from Title Page, Back Matter, and Copyright Pages. Citations and references are crafted with footnotes, endnotes, and proper citations.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <section className="sec9 philMain ProContent servingMainbox">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ProContentLeft">
              <div className="abtLeft">
                <h4>Spring Into Savings<br></br>
Get 20% Off Sitewide! </h4>
                <p>
                Dive into the world of affordable publishing deals, all book-inclusive services and launch your career as a pro author!
                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                    <ButtonPrimary />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 proconImageLeft">
              <img src={Assets.girlBgCircle} alt="Platform Images" />
            </div>
          </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Give your book a professional edge with our expert formatting services" Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Having Difficulty Formatting a Book? Let Us Guide You </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Why should your book be formatted correctly? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    A question often asked is whether formatting is only for aesthetic purposes. To answer, yes but to think that it is only for aesthetic purposes is wrong. A professionally published book is always easy to read. It is suited to grab the reader’s attention from the get-go. Formatted books give the readers a captivating book that separates them from other books. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are the key features of a formatted book? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Book formatting services follow some standardized practices. A manuscript is based on structure and layout; you have well-designed book formatting if both are perfected. There are some key features of formatting; 
                    <ul>
                      <li>12-point font size in Times New Roman; although different fonts can be used in a manuscript, it should look cohesive and clean. Some editors also prefer using different fonts for headings and titles.  </li>
                      <li>Correct margin lines on all sides so that you don’t lose any content in bleeding; the margins should, at the very least, be 1-inch.  </li>
                      <li>Page size is also a great differentiator between e-books, in which the normal size is 8.5x11 inches. </li> 
                      <li>Indentation is also key in book formatting, and all the paragraphs should be indented to appear uniform. </li>
                    </ul>

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can a self-publishing author take TAT’s services? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, we entertain all authors who are ready to self-publish their books or help those who want a traditional approach to publishing. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the cost of eBook and book formatting? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Digital or print-ready files can cost up to $400 to $2500, which can differ through revisions that have an external cost. {Constant.CompanyName} is a ghostwriting service that offers budget-friendly packages to cater to all your niche-specific requirements. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl  pageTitle = "Book Formatting Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default BookFormatting;
