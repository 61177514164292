import React, { useEffect,useState } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import { Link, useLocation,useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { useRef } from 'react';
import { Helmet } from "react-helmet";

function Childern() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Childern Book Writing Services - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner childernBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Top Children’s Book Ghostwriters in USA
                  </h1>
                  <p>
                  Get the best children’s book ghostwriters from The Author Tribe for excellent quality work. Contact us now for further details. 
                  </p>
                  <div className="bannerList">
                    <ul>
                      <li><FontAwesomeIcon icon={faCheckCircle} /> Trusted by 500+ Authors Globally</li>
                      <li><FontAwesomeIcon icon={faCheckCircle} /> 100% Satisfaction Guarateed</li>
                    </ul>
                  </div>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>All-Encompassing Fiction a Ghostwriting Services For You</h4>
                <p>
                We provide quality Fiction Ghostwriting Services to people of all ages. Whether you want a light-hearted fun to read or a serious business piece, we can write it for you. From children's books to adult novels, from romance to science fiction, we can create a book that will bring joy and happiness to your readers. Novelists, journalists, screenwriters and producers are just a few of the professionals who trust our work to provide them with quality writing services. If you have an idea that is just waiting to be told, or if you want to turn your story into a best-seller, let the team at The Author Tribe help you out.</p>

                <p>Screenwriters and producers are just a few of the professionals who trust our work to provide them with quality writing services. If you have an idea that is just waiting to be told, or if you want to turn your story into a best-seller, let the team at The Author Tribe help you out.
                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.childernBookHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Have a plot in your mind but
need words to refine your story? " Text="We are here to help you with our Expert Writers!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Hire The Author Tribe <br></br>
                        Ghostwriting Agency?
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.childern1} loop={true} />
                    </div>
                    <div className="contentChoose">
                      <span>Unique Writing Styles</span>
                      <p>
                      Our creative writing style adapts your story and plot by heart and creates exceptionally well-written content and writing tone according to your requirement.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.childern2}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Best Marketing and Aesthetic Designs</span>
                      <p>
                       Our design team creates an enticing cover page for your book and has smart strategies to promote your book to the right audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.childern3}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Privacy Protection </span>
                      <p>
                      Your personal information and project details are secured with us as a part of our contract and social and moral values.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.childern4}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Customer Support</span>
                      <p>
                      You are just one click away from contacting us. We are available 24/7 to resolve your problem.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <div className="connectMainwrap">
                        <div className="connectMaininner">
                        <img src={Assets.connectimage} alt="Connact Image" />
                        <div className="connectCOntent">
                            <p>We are so much more than just a ghostwriting firm. We are a team of industry professionals offering comprehensive and tailored services designed to help you achieve your book publishing goals. 
<span>Let’s connect!</span></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Have a plot in your mind 
but need words to refine 
your story? " Text="We are here to help you with our 
Expert Writers!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Frequently Asked Questions</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much do ghostwriters of the Author Tribe charge for a book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on what type of service you select, our expert ghostwriting services have a range of prices. The price would be roughly the same for fiction, non-fiction, memoirs, and biographies. The pricing depends on your project's duration, preferences, and priority level. You can fill out the form above to receive a free estimate and learn the service's precise cost.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is ghostwriting an illegal service?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Depending on what type of service you select, our expert ghostwriting services have a range of prices. The price would be roughly the same for fiction, non-fiction, memoirs, and biographies. The pricing depends on your project's duration, preferences, and priority level. You can fill out the form above to receive a free estimate and learn the service's precise cost.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                         How to hire a ghostwriter?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on what type of service you select, our expert ghostwriting services have a range of prices. The price would be roughly the same for fiction, non-fiction, memoirs, and biographies. The pricing depends on your project's duration, preferences, and priority level. You can fill out the form above to receive a free estimate and learn the service's precise cost.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do you become a ghostwriter?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on what type of service you select, our expert ghostwriting services have a range of prices. The price would be roughly the same for fiction, non-fiction, memoirs, and biographies. The pricing depends on your project's duration, preferences, and priority level. You can fill out the form above to receive a free estimate and learn the service's precise cost.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl  pageTitle = "Childern Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Childern;
