import React, { useEffect,useState } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLongArrowAltRight,
  faStar,
  faComment,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import { Link, useLocation,useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { useRef } from 'react';
import { Helmet } from "react-helmet";

function Crime() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Mystery Book Writing Services | Hire Mystery Book Writers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner crimeBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Choose Our Mind-Bending Mystery Writing Services and Puzzle Your Audience 
                  </h1>
                  <p>
                  A good mystery keeps the readers on edge till the end of the story; our expert mystery writers hook readers with complex and mind-gauging mysteries where once they pick up the book, they cannot let it down! 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Get the Most Professional Mystery Writers in the USA </h4>
                <p>
                Penning down a good mystery requires a lot of expertise and brains; a good mystery revolves around multiple layers and is never boring! At {Constant.CompanyName}, we have a wide range of creative and top-of-the-line authors who are well-equipped to write mind-bending mysteries, be it short stories or novels; our writers know how to satisfy readers who are fans of the mystery genre. The complex nature of the mystery genre can sometimes get confusing, which can make readers very confused; a professional writer knows how to write a creative and mind-boggling manuscript without giving readers a headache. </p>

                <p>Indulge your audience in a world of riveting mysteries that unravel as the story unfolds, and our top-tier writers plan creative plots and a comprehensive storyline that is imprinted on the reader’s mind as soon as they read the first few pages. Feature our most skilled writers for your books and become the next best-selling author! 
                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.crimeBookHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Thinking about writing a mystery novel? Hire our mystery book writers! " Text="Connect with us now to get the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Our Professional Mystery Writers <br></br>are on Top of the Game
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.mysterywriting1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Exciting and Unique Plotlines </span>
                      <p>
                      When it comes to writing unique storylines, our writers are always coming up with fresh ideas to work on! The exceptional collaboration between the client’s idea and or writers’ freshness is what makes our agency so special and worth your time! 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.mysterywriting2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Ongoing Support </span>
                      <p>
                      We do not just forget about our clients after the completion of the project, but we offer ongoing support and take feedback on how your book is coming along and if you need any help on your journey! 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.mysterywriting3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Choose the Most Affordable Services </span>
                      <p>
                      Do you think well-reputed ghostwriting and book publishing services will cost you an arm and a leg? Let us change your mind because Bellevue Publishers ensures that we provide quality services at affordable prices, and it’s all because of our love for the craft we share with our clients.
                      </p>
                    </div>
                  </div>

                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.mysterywriting4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Unravel Multiple Tones and Atmospheres </span>
                      <p>
                      Through our creative outlets, our writers can explore different genres and tap into a world that you want to create for your readers! At, {Constant.CompanyName}, we take leverage from the academics and experience acquired from our best-selling line of published books! 
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <div className="connectMainwrap">
                        <div className="connectMaininner">
                        <img src={Assets.connectimage} alt="Connact Image" />
                        <div className="connectCOntent">
                            <p>We are so much more than just a ghostwriting firm. We are a team of industry professionals offering comprehensive and tailored services designed to help you achieve your book publishing goals. 
<span>Let’s connect!</span></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Thinking about writing a mystery novel? Hire our mystery book writers! " Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Have a Question? Ask away </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Are mystery novels interesting or boring? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    A good mystery novel is never boring in building surroundings and depth, and a mystery novel can be detailed, but an expert would know how to garner the interest of the reader and not bore him with too many unnecessary details and descriptions. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the most important thing to write about in a mystery story?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Probably the most crucial part of crafting a mystery novel is building a worthwhile storyline that builds the reader’s curiosity. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much does a mystery writing service cost? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on the book's length, any book service can cost anywhere between $20 to $1000 or more. {Constant.CompanyName} have an affordable range where we make sure to provide flexibility on our rates with no compromise on quality. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can you write a series of my book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our mystery book writers focus on fulfilling clients' needs, so designing a layout for either one or a series of the novel is never a problem for our experts! 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Mystery Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
        <FooterForm/>
      <Footer />
    </>
  );
}
export default Crime;
