import React, { useEffect } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import { Link, useLocation,useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Process from "../../components/Process";
import Girlquote from "../../components/Girlquote";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Portfolio from "../../components/Portfolio";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function Fiction() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  return (
    <>
    <Helmet>
       <title>Fiction Writing Services | Fiction Ghostwriters for Hire</title>
    </Helmet>
      <Header/>
      <section className="sec1 innerBanner fictionBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Exceptional Fiction Ghostwriting Services Like No-Other
                  </h1>
                  <p>
                  Bellevue Publishers is known for breathing life into your amazing stories and allowing you to captivate readers with our top-notch fiction writing service. Get exceptional fiction pieces from the best writers out there. You can choose any genre you want your book to be written in, be it science fiction, sci-fi, fantasy, romance and many more. 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider title="Publishing Your Book On Various Top Platforms"/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Bringing Your Stories To Life With Quality Fiction Writing Service </h4>
                <p>
                Several companies provide fiction writing services, but you can be sure that none can match the quality of Bellevue Publishers. We have some of the best fiction writers at our disposal who know how to craft amazing regardless of the genre, be it romance, fantasy, horror, or science fiction. Bellevue Publishers and quality fiction writing services provide its clients with exceptional editing and proofreading services to ensure that the final manuscript is perfect when delivered. 
                </p>
                <p>The following elements mostly characterize an excellent fiction story: A well-written plot with a clear structure, intriguing twists and turns, and a satisfying ending. Characters who are memorable and relatable, who drive the plot and elicit emotions in the reader. A colorful and well-described setting adds to the story's atmosphere and tone. A distinct and thought-provoking topic that gives the story depth and significance. A distinct and compelling writing style that captures the reader's attention and sets the tone for the story. An intriguing conflict that builds tension and keeps the reader interested. Well-written dialogue that advances the plot and exposes character.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.handBook} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Dive into the fascinating world of fiction writing and explore unique ideas. " Text="Get in touch with us now and get the best possible deals! "/>
      <section className="fictionInnerCate">
        <div className="container">
            <div className="row">
                <div className="col-md-12 fictionInnerCaterow">
                    <span>Now Is The Time To Enter The Realm Of Literary Fiction Classics</span>
                    <p>Bellevue Publishers will assist you by writing fascinating fictional stories with the help of our fiction ghostwriters for hire. View the different categories Bellevue masters for our clients! </p>
                </div>
                <div className="col-md-12 fictioncatboxes">
                  <div className="row">
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                        <Link to={`/drama-writing/`} >
                        <div className="ficImage">
                          <img src={Assets.fictionimg1} alt="Fiction Images" />
                        </div>
                        <div className="ficTitle">
                          <span>Drama</span>
                        </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                        <Link to={`/fantasy-writing/`} >
                          <div className="ficImage">
                            <img src={Assets.fictionimg10} alt="Fiction Images" />
                          </div>
                          <div className="ficTitle">
                            <span>Fantasy</span>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                      <Link to={`/mystery-writing/`} >
                        <div className="ficImage">
                          <img src={Assets.fictionimg3} alt="Fiction Images" />
                        </div>
                        <div className="ficTitle">
                          <span>Crime/Mystery</span>
                        </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                      <Link to={`/suspense-thriller-writing/`} >
                        <div className="ficImage">
                          <img src={Assets.fictionimg4} alt="Fiction Images" />
                        </div>
                        <div className="ficTitle">
                          <span>Suspense/Thriller</span>
                        </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                      <Link to={`/romance-writing/`} >
                        <div className="ficImage">
                          <img src={Assets.fictionimg5} alt="Fiction Images" />
                        </div>
                        <div className="ficTitle">
                          <span>Romance</span>
                        </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                      <Link to={`/horror-book-writing/`} >
                        <div className="ficImage">
                          <img src={Assets.fictionimg6} alt="Fiction Images" />
                        </div>
                        <div className="ficTitle">
                          <span>Horror</span>
                        </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                      <Link to={`/action-writing/`} >
                        <div className="ficImage">
                          <img src={Assets.fictionimg7} alt="Fiction Images" />
                        </div>
                        <div className="ficTitle">
                          <span>Action</span>
                        </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                      <Link to={`/science-fiction-writing/`} >
                        <div className="ficImage">
                          <img src={Assets.fictionimg8} alt="Fiction Images" />
                        </div>
                        <div className="ficTitle">
                          <span>Sci-Fi</span>
                        </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4 fictionboxwrap">
                      <div className="fictionboxinner">
                      <Link to={`/adventure-writing/`} >
                        <div className="ficImage">
                          <img src={Assets.fictionimg9} alt="Fiction Images" />
                        </div>
                        <div className="ficTitle">
                          <span>Adventure</span>
                        </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 ctaWrap">
              <div className="aboutCta">
              <ButtonPrimary/>
                <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="smallTitleLine">Call Us</span>
                  {Constant.PhoneNumber}
                </a>
              </div>
            </div>
            </div>
        </div>
      </section>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Bellevue Publishers The Best Option For Fictional Writing Services
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.fictionwriting1} loop={true} />
                    </div>
                    <div className="contentChoose">
                      <span>Creative Plots </span>
                      <p>
                      Fiction writing is a form of writing that greatly relies on the writer's creativity. Our writers are experts at using their imagination, allowing them to create new worlds, characters and situations. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.fictionwriting2}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Set the Right Setting</span>
                      <p>
                      Your stories must entice the reader's emotions, be it through humor, suspense, drama or romance. With these, your fiction piece will have the foundation to captivate readers in a positive way.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.fictionwriting3}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Top-Rated Ghostwriters </span>
                      <p>
                      Fiction writing is difficult because it requires plot, character, dialogue, and description. Bellevue Publishers has the best ghostwriters at our disposal, who are masters of this art and are perfect for your next fiction writing project. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.fictionwriting4}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Target the Right Audience </span>
                      <p>
                      Our professional writers know how to captivate readers with their fascinating wordplay and a story that, in some way, connects the readers to your book. This can be achieved with factors such as tone, pacing and style that our writers are masters at. 
                      </p>
                    </div>
                  </div>
              
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Ctaorange Title="Enter the thrilling realm of fictional writing and experiment with new ideas!" Text="Get in touch with us and get the best deals now!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Some of the most FAQs</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What types of fiction writing services do you offer?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The services provided by fiction writing service providers vary, but they often include custom-written fiction as well as editing and proofreading. Some providers may specialize in a particular genre, such as romance, science fiction, fantasy, or horror. But Bellevue specializes in a range of genres, which helps us stand out from the rest. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I place an order for fiction ghostwriting services?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>To place an order for fiction ghostwriting services, you will first fill out a form on our website or contact our support team. After that, you will provide the details of your project, including the genre, word count, and deadline.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What happens after I place an order for fiction writing services?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Once you place your order for fiction writing services, Bellevue Publishers will assign a dedicated project manager to your project. They will work with the writers and you to understand your vision for the story and write your book according to the guidelines you have provided. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does it take to complete a fiction ghostwriting process?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The time it takes to complete your fiction ghostwriting project can vary depending on the complexity of the project and the number of pages of your book. You should expect the completed book within weeks, depending on the writing process and word count. </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much does it cost for fiction ghostwriting services?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The cost of using fiction writing services can vary depending on the complexity of the project and the word count. You should expect to pay anywhere from a few hundred to several thousand dollars for a custom-written fiction piece.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I request revisions to the fiction piece I receive?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, we allow you to request revisions to the piece you receive. It would help if you worked with the writer to ensure that the final product meets your expectations.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Your One-Stop Solution For Your Fiction Writing Projects!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Fiction;
