import React, { useEffect } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Girlquote from "../../components/Girlquote";
import Testimonials from "../../components/Testimonials";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function Romance() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  
  return (
    <>
      <Helmet>
        <title>Romance Book & eBook Writing Services - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner romanceBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Bloom Your Fantasies with Our Romance Writing Services
                  </h1>
                  <p>
                  Is love in the air? Let us help you explore and flourish your tales of budding romances or coming-of-age romance stories. Our romance writing services broaden the horizons of feel-good romantic stories that are suited perfectly for an audience that loves a little dose of love! 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Feature Exceptional Romance Novel Writers to Craft Stories of Budding Romance</h4>
                <p>
                Finding professional writers for a niche can be a strenuous task; romance is a category that is built for readers that want to read stories that can move them through numerous emotions; romance is a multi-faceted genre that takes you on a journey of grief, heartbreak, connection, distance, and most importantly the intimacy and warmth associated with romance.
</p>
                <p>Romance is the only genre where readers know they will read a love story. Romance novels are a saturated genre; every story that you can read has already been written. It is our job as professionals to write a novel that has all the ingredients of a sweet romance. Bellevue Publishers, have the expertise to stir up your emotions and write a book that is truly transformative in nature. Not just any romance novel, but one that excites and leaves you in awe. Our best lot of romance novel writers excel in making specific guidelines and designing either a central love story between a couple or a story that results in heartbreak or a happy ending.
                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.Romance} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Is there some romance brewing? Let our romance writers draft your books! " Text="Connect with us now to get the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Our Love Gurus Make <br></br>the Best Romance Novel Writers 
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.romancewriting1} alt="Icon"   />
                    </div>
                    <div className="contentChoose">
                      <span>No Genre Is Impossible For Bellevue Publishers </span>
                      <p>
                      We have established that the romance genre is vast, but it doesn’t stop Bellevue Publishers, from mastering scripts that are customized to the client’s preference. Through years of experience, our seasoned professionals know exactly what it takes to set a manuscript apart.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.romancewriting2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Creative Brainstorming Sessions</span>
                      <p>
                      Already have a story in mind? Let our expert romance novel writers take you on a creative journey of designing, drafting and editing your manuscript to international publishing standards! Our ghost book writers don’t just write books, and they help you share a piece of art that can be appreciated by many! 
                      </p>
                    </div>
                  </div>

                </div>
                <div className="col-md-12 whychooeIconBox">
                <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.romancewriting3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Timely Deliveries </span>
                      <p>
                      Tired of miscommunication and not meeting deadlines? We at Bellevue Publishers, take strict measures to ensure we meet our deadlines at all costs. We make sure we complete tasks on a timely basis and encourage a culture of punctuality in our organization.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.romancewriting4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Free Consultation</span>
                      <p>
                      Sometimes deciding to put your story in front of the world can make you a little nervous. Bellevue Publishers, offer free consultancy services to bring clarity and transparency so you can make better decisions!
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Is there some romance brewing? Let our romance writers draft your books! " Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Don’t Know The Nitty Gritty of Romance Writing Services? Start here. </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are romance writing services? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Romance is a genre liked and loved by many; an agency like {Constant.CompanyName} helps you craft, edit, and format a piece f literature that fulfils publishing standards and helps you exercise your passion for writing. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What makes a romance novel unique? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Any novel can be unique, but because romance is a genre liked and written by a majority of people; you can expect the plotlines to be overused at times. Our romance novel writers build creative characters, help the audience feel connected to them and merge the stories with great climaxes to woo the audience! 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the cost of ghost book writers? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on the services required, ghost book writers can cost around $50 - $1000. The cost is varied depending on the length, genre and services required for the book. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Where can I find the best romance writing services in the US? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Although there are many online ghost book writing companies in the US, you can search and find the one closest to you. Many agencies work remotely but are both professional and reliable. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I write a good romance story? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    For any story, you need a central plot, and this can be through characters or a common theme where readers can find some connection. You need to find a niche and stick to it; too many different structures can often ruin the story and make readers lose interest. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Romance Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
     <FooterForm/>
      <Footer />
    </>
  );
}
export default Romance;
