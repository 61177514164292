import React, { useEffect } from "react"; 
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faChevronDown,
  faPhone,
  faLongArrowRight,
  faStar,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function AuthorWebDesign() {
 
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  const navigate = useNavigate()

//navigate(0)
     
  return (
    <>
      <Helmet>
        <title>Author Website Design Services | Author Website Designer</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner authorBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Connect With Our Experts For Customized Web Design Solutions.
                  </h1>
                  <p>
                  Transform your online presence with a stunning website that reflects your unique style. Partner with us for expertly crafted designs that captivate readers and boost your author brand. Craft a captivating website that highlights your work and engages your audience. 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Unlock the Potential of Your Author Brand with Professional Web Design</h4>
                <p>
                At Bellevue Publishers, we understand the power of a well-designed author website. With our expertise in web design, we create visually stunning and user-friendly websites that elevate your online presence and drive book sales. Recent studies have shown that authors with professionally designed websites experience a 30% increase in website traffic and a 20% boost in book sales. A visually appealing website with intuitive navigation not only captivates readers but also builds trust and credibility. With our mobile-responsive designs, your website will look impeccable on any device, ensuring a seamless user experience. Our tailored web design services showcase your author brand, highlight your books, and provide a platform for engaging with readers. Don't miss out on the opportunities that a well-crafted website can bring to your writing career. Partner with Bellevue Publishers and step into the digital realm with confidence.
                </p>

                <p>
                Our team of skilled web designers combines creativity and technical expertise to create stunning author websites. We understand the importance of a captivating online platform for showcasing your work. With our attention to detail, user-friendly navigation, and mobile responsiveness, we ensure an exceptional web design experience that helps you connect with your audience and establish a strong author brand. Trust us for professional, tailored web design solutions that make you stand out in the digital world.
                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.authorHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Craft an Exceptional Author Website for Your Ebooks with Bellevue Publishers." Text="Don't miss out on the best deals - connect with us today!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Bellevue Publishers is Your Top Choice<br></br> for Professional Author Website Design
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.authorwebsitedesign1}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Search Engine Optimization: </span>
                      <p>
                      Boost your online presence with our SEO-optimized author websites that rank higher in search results, driving more traffic to your work.
                      </p>
                    </div>
                  </div>

                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.authorwebsitedesign2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Customized Design Options: </span>
                      <p>
                      Experience the freedom of choice with our wide range of custom design options, ensuring your author website stands out and captures your unique style.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.authorwebsitedesign3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>User-Friendly and Intuitive: </span>
                      <p>
                      Deliver an exceptional user experience with our intuitive and easy-to-navigate websites, allowing readers to explore your content effortlessly.
                      </p>
                    </div>
                  </div>

                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.authorwebsitedesign4}  />
                    </div>
                    <div className="contentChoose">
                      <span>Expertise in Author Website Design: </span>
                      <p>
                      Rely on our seasoned team of experts who possess in-depth knowledge and a track record of designing captivating author websites that highlight your talent.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <div className="connectMainwrap">
                        <div className="connectMaininner">
                        <img src={Assets.connectimage} alt="Connact Image" />
                        <div className="connectCOntent">
                        <p>We provide tailored services to accomplish your goals, ensuring your contentment and gratification. We do not just write stories; we bring life to them.
<span>Let’s connect!</span></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <section className="sec12 TestiSliderMain">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 whyChooseLeft">
              <div className="abtLeft">
                <span className="smallTitleLine">Testimonials</span>
                <h4>
                Testimonials for Author Web Design:
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
          <div className="col-md-12 blogSlider">
            <Swiper
              slidesPerView={3}
              spaceBetween={120}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
              }}
              centeredSlides={false}
              loop={true}
              loopFillGroupWithBlank={true}
              navigation={false}
              breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">SH </span>
                <div className="nameStars">
                  <span className="clientNameTesti">Sarah H.</span>
                  <div className="ratingStar">
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>Working with Bellevue Publishers was a game-changer for my author website. They created a stunning design that perfectly captured my brand and helped me connect with readers. I couldn't be happier!</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">JR</span>
                <div className="nameStars">
                  <span className="clientNameTesti">John R.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>The team at Bellevue Publishers truly understood my vision and brought it to life. Their attention to detail and creative approach made my author website a true masterpiece. Highly recommended!</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">RA</span>
                <div className="nameStars">
                  <span className="clientNameTesti">Emily M.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>Bellevue Publishers transformed my outdated author website into a modern and visually appealing platform. The user-friendly design has made it easier for my readers to engage with my content.</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">DL</span>
                <div className="nameStars">
                  <span className="clientNameTesti">David L.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>I was amazed by the professionalism and expertise of Bellevue Publishers. They crafted a custom-author website that exceeded my expectations. It has become a valuable asset for my writing career.</p>
              </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">SK</span>
                <div className="nameStars">
                  <span className="clientNameTesti">Samantha K.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>Choosing Bellevue Publishers for my author web design was a wise decision. Their team took the time to understand my goals and delivered an exceptional website that showcases my books beautifully.</p>
              </div>
                </div>
              </SwiperSlide>
              
              
            </Swiper>

            </div>
          </div>
        </div>
      </section>
      <Blogs/>
      <Ctaorange Title="Design a unique and engaging author’s website for your ebooks!" Text="Connect with us now to get the best deals! "/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Frequently Asked Questions</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What makes {Constant.CompanyName} different from other author website design services? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our team has extensive experience in designing professional author websites, and we offer a range of custom design options to ensure that your website is tailored to your specific needs. We also provide ongoing support and maintenance to ensure that your website is always up-to-date and functioning smoothly.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I see some examples of author websites designed by {Constant.CompanyName}? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Yes, we have a portfolio of past author website design projects that you can view to see the quality of our work.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does it take to design an author website? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The time it takes to design an author website can vary depending on the complexity of the project. Our team will work with you to set a realistic timeline and ensure that your website is delivered on-time.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much does it cost to design an author website? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The cost of designing an author website can vary depending on the complexity of the project and the specific design options you choose. Our team will provide a detailed quote and work with you to create a budget that meets your needs.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Start your journey to success with Bellevue Publishers"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default AuthorWebDesign;
