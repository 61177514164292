import React, { useEffect, useState } from "react";
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLongArrowAltRight,
  faStar,
  faArrowRight,
  faComment
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import { Navigation} from "swiper";
import { Link, useLocation,useNavigate } from "react-router-dom";
import $ from "jquery";
import "./styles.css";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import Process from "../../components/Process";

import Blogs from "../../components/Blogs";
import Portfolio from "../../components/Portfolio";
import FooterForm from "../../components/FooterForm";
import Typewriter from 'typewriter-effect/dist/core';
import { useRef } from 'react';
import { Helmet } from "react-helmet";
function Home() {
  const navigate = useNavigate();

//navigate(0) 
  const [navClick, setNavClick] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navClick]);
  useEffect(() => {
    $("[data-targetit]").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      var target = $(this).data("targetit");
      $("." + target)
        .siblings('[class^="box-"]')
        .hide();
      $("." + target).fadeIn(100);
    });
    $(".swiper-button-prev-has").on("click", function () {
      $(".swiper-button-prev").click();
    });
    $(".swiper-button-next-has").on("click", function () {
      $(".swiper-button-next").click();
    });
    $(".prevArrow").on("click", function () {
      $(".testLeftImage .swiper-button-prev").click();
    });
    $(".nextArrow").on("click", function () {
      $(".testLeftImage .swiper-button-next").click();
    });
    new Typewriter('#typewriter', {
      strings: ['Ghostwriting', 'Editing', 'Publishing', 'Marketing'],
      autoStart: true,
      loop: true,
    });
  }, []);

  return (
      <>
      <Helmet>
         <title>Book & eBook Ghostwriters for Hire - Bellevue Publishers</title>
     </Helmet>
      <Header />
      <section className="sec1 BannerHome">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 bannerContent">
              <h1>Elevate Your Literary Success with the Best Book Publishing Company</h1>
              <p>
              Bring your story to life with our all-in-one book services. Make your mark on the world with our team of expert book publishers to guide you through the writing process, from ideation to publication. Let us help you share your unique voice with the world. Hire book publisher at Bellevue now and start your literary journey today.
              </p>
              <div className="btnBanner">
                <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                <a href="javascript:;"  className="btnSecondary chat">
                  <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                </a>
               
              </div>
            </div>
          </div>
          <div className="row criclerow">
            <div className="sliderCircle">
              <div className="circle">
                <ul>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio10} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio2} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio3} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio4} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio5} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio6} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio7} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio8} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio9} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio10} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio11} alt="Book Image" />
                  </li>
                  <li>
                    {" "}
                    <img src={Assets.newportfolio12} alt="Book Image" />
                  </li>
                </ul>
              </div>
            </div>
            <div className="BookTitle">
              <span id="typewriter">Ghostwriting</span>
              <div className="aboutCta">
                <a href="javascript:;" className="btnYellow openPopup">
                  Request A Quote  <FontAwesomeIcon icon={faArrowRight} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec2 abtSection">
        <div className="container">
          <div className="row">
            <div className="col-md-6 abtLeft">
              <span className="smallTitleLine">{Constant.CompanyName}</span>
              <h2>
                Brilliant Minds Publish Here
              </h2>
              <div className="cupImage">
                <img src={Assets.aboutcup} alt="Cup Image" />
              </div>
            </div>
            <div className="col-md-6 abtRight">
              <p>
              At Bellevue Publishers, we believe that everyone has a story to tell. That’s why we offer comprehensive book services that cater to all your needs - whether you’re a first-time author or an experienced writer. From crafting a compelling narrative to designing a beautiful cover, we’ve got you covered at all angles. Our expert teams are dedicated to bringing your vision to life with personalized guidance and support at every step of the way. So why wait? Unleash your inner writer and start your literary journey with our book publishing company today! 
              </p>
              <div className="aboutCta">
              <ButtonPrimary/>
                <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="smallTitleLine">Call Us</span>
                 {Constant.PhoneNumber}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec3 abtSection coreServices">
        <div className="container">
          <div className="row">
            <div className="col-md-6 abtLeft">
              <span className="smallTitleLine">OUR CORE SERVICES</span>
              <h2>
              Orchestrating Excellence: Our Strategic Methodology as the Best Book Publishing Company
              </h2>
            </div>
            <div className="col-md-6 abtRight">
              <p>
              At our book publishing company, we have a carefully designed approach to achieve excellence. We don’t just rush through tasks randomly. Instead, we focus on quality and follow a strategic plan that resonates with our clients. It has been the key to our unmatched success in the online publishing industry.
              </p>
              <div className="aboutCta">
              <ButtonPrimary/>
                <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="smallTitleLine">Call Us</span>
                  {Constant.PhoneNumber}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 port-tabs">
              <div className="custom-tab-list">
                <ul>
                  <li data-targetit="box-ghostwriting" className="active">
                    <a href="javascript:;">
                      <span>01</span> Transparency
                    </a>
                  </li>
                  <li data-targetit="box-editing" className="">
                    <a href="javascript:;">
                      <span>02</span> 24/7 Support
                    </a>
                  </li>
                  <li data-targetit="box-publishing" className="">
                    <a href="javascript:;">
                      <span>03</span> Lightning-Fast Results
                    </a>
                  </li>
                  <li data-targetit="box-marketing" className="">
                    <a href="javascript:;">
                      <span>04</span> Guaranteed Privacy
                    </a>
                  </li>
                  <li data-targetit="box-consultation" className="">
                    <a href="javascript:;">
                      <span>05</span> An Extensive Team
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 port-content">
              <div className="box-ghostwriting showfirst">
                <div className="tabCont">
                  <p>
                  Transparency is the cornerstone of our book service philosophy. We believe in full disclosure at every step of the process.</p>
                 
                </div>
              </div>
              <div className="box-editing">
                <div className="tabCont">
                  <p>
                  We’re always on standby to provide unwavering support whenever needed, leaving you free to focus on your vision. 
                  </p>
                 
                </div>
              </div>
              <div className="box-publishing">
                <div className="tabCont">
                  <p>
                  We don’t just meet deadlines - we exceed them. Choose Bellevue Publishers and experience the thrill of rapid results without compromising on excellence. 
                  </p>
                  
                </div>
              </div>
              <div className="box-marketing">
                <div className="tabCont">
                  <p>
                  We treat privacy as a sacred covenant and take our clients’ confidentiality seriously. Our commitment to guaranteed privacy is an ironclad principle we live and breathe daily. 
                  </p>
                 
                </div>
              </div>
              <div className="box-consultation">
                <div className="tabCont">
                  <p>We have an extensive roster of wordsmiths from all walks of life. Join our tribe and experience the power of a truly extensive book publisher team.</p>
                 
                </div>
              </div>
              {/* <div className="box-consultation">
                <div className="tabCont">
                  <span className="heading3">
                  Marketing: Get Your Work Noticed
                  </span>
                  <p>
                  Getting your work noticed in today's crowded marketplace can be a challenge. That's why we offer comprehensive marketing services to help you reach your target audience and promote your work effectively. From social media campaigns to email marketing and book promotions, our marketing experts will help you get the exposure you need to succeed.</p>
                  <a href="javascript:;">MORE ABOUT Book Review</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="sec4 servicesMainboxes">
        <div className="container">
          <div className="row">
            <div className="col-md-12 serviceTitle">
              <span className="heading2">Our Simple and Tested Roadmap to Success</span>
              <p>We cherish our clients and hold their words dear!</p>
            </div>
            <div className="col-md-4 servicebox">
              <div className="serviceBoxinner">
              {" "}
                <img src={Assets.serviceBoxinner1} alt="Book Image" />
                <span className="servTitle">Order Placement</span>
              </div>
            </div>
            <div className="col-md-4 servicebox">
              <div className="serviceBoxinner">
              <img src={Assets.serviceBoxinner2} alt="Book Image" />
                <span className="servTitle">Draft Submission</span>
              </div>
            </div>
            <div className="col-md-4 servicebox">
              <div className="serviceBoxinner">
              <img src={Assets.serviceBoxinner3} alt="Book Image" />
                <span className="servTitle">Designing Project Scope</span>
              </div>
            </div>
            <div className="col-md-4 servicebox">
              <div className="serviceBoxinner">
              <img src={Assets.serviceBoxinner4} alt="Book Image" />
                <span className="servTitle">Editing and Formatting</span>
              </div>
            </div>
            <div className="col-md-4 servicebox">
              <div className="serviceBoxinner">
              <img src={Assets.serviceBoxinner5} alt="Book Image" />
                <span className="servTitle">Publishing</span>
              </div>
            </div>
            <div className="col-md-12 ctaWrap">
              <div className="aboutCta">
              <ButtonPrimary/>
                <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="smallTitleLine">Call Us</span>
                  {Constant.PhoneNumber}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec4 servicesMainboxesContent">
        <div className="container">
          <div className="row">
            <div className="col-md-12 serviceTitle">
              <h3 className="heading2">
              Embark on Your Literary Journey with Our Comprehensive Book Writing Services in the US{" "}
              </h3>
            </div>
            <div className="col-md-6 contentMain contentLeft">
              <p>
              As you weigh the decision to engage an online book publishing company, why not start with a complimentary consultation from us? We’ll alleviate all your concerns, helping you better understand what it entails to hire our top-tier professional book writers, hand-selected by industry experts, to offer you premium services.{" "}
              </p>

              <p>
              We are the one-stop shop for all your literary needs, incorporating writing, editing, and publishing into our comprehensive suite of services. With cost-effective pricing and masterful authors who dive deep into every project, we assure you of a service that leaves no stone unturned. Through our premier book writing solutions, we uphold high standards in fiction and non-fiction writing and guarantee your voice reaches its intended audience.{" "}
              </p>
            </div>
            <div className="col-md-6 contentMain contentRight">
              <p>
              Our team, aptly named Bellevue Publishers, commits to crafting content that verges on being a magnum opus. We take pride in the quality we deliver and promise exceptional service at every step. From your inaugural consultation to the completion of your project, our dedicated team guides you, ensuring absolute transparency.{" "}
              </p>

              <p>
              Upon receiving your initial inquiry, our book publishing company connect with you to discuss your project in-depth. Our wide array of book writing services spans various genres and niches – whatever you envision, and we can bring it to life!{" "}
              </p>

              <p>
              Bellevue Publishers have established a deeply ingrained workflow, from our sales team to our publishers, delivering bespoke support at every level. Our authors, boasting numerous best-selling titles, are committed to fostering unparalleled brilliance and premium-quality content.{" "}
              </p>
            </div>
            <div className="col-md-12 ctaWrap">
              <div className="aboutCta">
              <ButtonPrimary/>
                <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="smallTitleLine">Call Us</span>
                  {Constant.PhoneNumber}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BrandSlider/>
      <section className="sec6 bookContent bookContebtHome">
        <div className="container">
          <div className="row">
            <div className="col-md-6 bookContentmain">
              <h3 className="heading2">
              Unleashing the Power of Quality Book Writing Services{" "}
              </h3>
              <p>
              Procuring professional book ghostwriting services that are both budget-conscious and high-grade can be a daunting task, and it often appears like a trade-off between one and the other. However, at Bellevue Publishers, we ensure you never have to sacrifice. We hold your requirements in high esteem, preserving the essence of your narrative and delivering a product that meets your exact specifications. Our strategy includes the creation of custom chapter outlines, a consistent narrative flow, and meticulously crafted chapters that align with your vision. We’re dedicated to creating a plan that’s uniquely tailored to your project.{" "}
              </p>
              <h3 className="heading2 mt-5">Craft Your Legacy with Us!</h3>
              <p>
              As trailblazers and the most trusted eBook ghostwriting services across the United States, Bellevue Publishers don’t just write stories; we breathe life into your concepts. We adopt a results-oriented approach to maximize your book’s sales and profitability, providing a robust platform to transform your writing aspirations into a lucrative business model. With us, you can be assured of absolute confidentiality; your book carries your name.{" "}
              </p>

              <p>
              We believe in complete transparency, ensuring that our pricing structure is clear, with no concealed costs, resulting in a straightforward process. Forget the hassle of scouting for professional book writers; we bring you the best in the industry, serving their expertise at your convenience. Hire ghostwriter best suitable for your genre and make your mark in the literary world.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <Process Title="How We Create a Masterpiece" Text="We offer our services with comprehensive yet convenient processes so you can be with us on every step. Moreover, we work through a chronology and practice timely submissions that are tweaked to your liking before the final publishing stage."/>
      <section className="sec9 philMain">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 philImageLeft">
              <img src={Assets.philLeft} alt="Platform Images" />
            </div>
            <div className="col-md-6 philContentRight">
              <div className="abtLeft">
                <span className="smallTitleLine">OUR PHILOSOPHY</span>
                <h4>Our Philosophical Approach</h4>
                <p>
                At Bellevue Publishers, we profoundly value your creativity and innovative thoughts. We invite you to explore and share your thought journey with our professional book writers, allowing them to bring your concepts to life within your manuscript. Our team of skilled authors demonstrate meticulous attention to detail and seeks to comprehend your vision as they carefully craft your manuscripts. 
                </p>
                <div className="makesTitle">
                  <span>What Makes Us Different</span>
                </div>
                <div className="makesContent">
                  <ul>
                    <li>
                      {" "}
                      <FontAwesomeIcon icon={faLongArrowAltRight} />{" "}
                      Multi-device compatibility for iPads, Android, and Kindle{" "}
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faLongArrowAltRight} /> Affordable book writing services{" "}
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faLongArrowAltRight} /> Custom Book Covers{" "}
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faLongArrowAltRight} /> Multiple Reviews
                    </li>
                  </ul>
                </div>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec10 whyChosseUs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 whyChooseLeft">
              <div className="abtLeft">
                <span className="smallTitleLine">Why Choose Us</span>
                <h4>
                Our Sales-Driven Book Ghostwriting Services assure success and enhance results
                </h4>
                <p>
                The writers and professionals at Bellevue Publishers embrace the notion of well-crafted procedures to develop your manuscripts from scratch. We have the audacity to curate your book and work on different genres to generate proactive results.{" "}
                </p>
                <div className="trustpilotGoogleBox">
                  <div className="reviewBTn">
                  <a href={Constant.TrustPilotLink} target="_blank">
                      <img src={Assets.trustpilot} alt="Trustpilot Images" />
                    </a>
                  </div>
                  <div className="reviewBTn">
                  <a href={Constant.GoogleReviewsLink} target="_blank">
                      <img src={Assets.google} alt="Google Images" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 whychooseRight">
              <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.homewhychooseicon1} alt="Book Image" />
                    </div>
                    <div className="contentChoose">
                      <span>Proven Record</span>
                      <p>
                      We have a proven track record of publishing numerous books with different genres, which is a testament to our expertise and skillset in book services.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.homewhychooseicon2} alt="Book Image" />
                    </div>
                    <div className="contentChoose">
                      <span>Exclusive E-Book Writing Services</span>
                      <p>
                      We have a nice blend of fresh and veteran professional book writers enriched with the ideology of writing proper book-writing content. We focus on the notion of originality and give regard to your ideas too, but maintain a perfect balance of fresh content.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.homewhychooseicon3} alt="Book Image" />
                    </div>
                    <div className="contentChoose">
                      <span>Ongoing Support</span>
                      <p>
                      We value your feedback and provide our clients with state-of-the-art customer support. The back-and-forth communication and proper coordination with our clients are a seal of excellence of our viable ongoing support.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.homewhychooseicon4} alt="Book Image" />
                    </div>
                    <div className="contentChoose">
                      <span>Standardized Book Publishing</span>
                      <p>
                      We prioritize maintaining the standards of book publishing and formatting to make sure the books are similar to professional best sellers. Our expert marketing experts do have the audacity to work with the clients.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="letsconnect">
                      <p>
                      We provide tailored services to accomplish your goals, ensuring your contentment and gratification. We do not just write stories; we bring life to them.{" "}
                        <b>Let’s connect!</b>
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner ctaWhyChoose">
                    <div className="ctaWrap">
                      <div className="aboutCta">
                      <ButtonPrimary/>
                        <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                          <FontAwesomeIcon icon={faPhone} />
                          <span className="smallTitleLine">Call Us</span>
                          {Constant.PhoneNumber}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec11 testimonialMain">
        <div className="container">
          <div className="row">
            <div className="col-md-3 testLeft">
            <div className="pointereventnone">
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                autoplay={{
                delay: 8000,
                disableOnInteraction: false,
                }}
                navigation={true}
                effect={"fade"}
                modules={[Navigation]}
                centeredSlides={false}
                loop={true}
                loopFillGroupWithBlank={true}
                className="mySwiper testLeftImage"
              >
                <SwiperSlide>
                  <img src={Assets.test2} alt="Test Images" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Assets.test1} alt="Test Images" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Assets.test3} alt="Test Images" />
                </SwiperSlide>
              </Swiper>
              </div>
              <div className="trustpilotGoogleBox">
                <div className="reviewBTn">
                  <a href={Constant.TrustPilotLink} target="_blank">
                    <img src={Assets.trustColor} alt="Trustpilot Images" />
                  </a>
                </div>
                <div className="reviewBTn">
                <a href={Constant.GoogleReviewsLink} target="_blank">
                    <img src={Assets.google} alt="Google Images" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 testCenter">
              <div className="abtLeft">
                <span className="smallTitleLine">What Our Clients Say About Us</span>
                <h4>A glimpse of what our clients wrote about us!</h4>
              </div>
              <div className="testSliderContent">
              <div className="pointereventnone">
                <Swiper
                slidesPerView={1}
                spaceBetween={0}
                autoplay={{
                delay: 8000,
                disableOnInteraction: false,
                }}
                navigation={true}
                effect={"fade"}
                modules={[Navigation]}
                centeredSlides={false}
                loop={true}
                loopFillGroupWithBlank={true}
                className="mySwiper testLeftImage"
                >
                  <SwiperSlide>
                    <div className="mainCOntentSlider">
                      <div className="testCon">
                        <p>
                        Voila! Super satisfied and happy to receive my edited copy. I asked for line editing and the guys managed developmental editing in the same price. 
                        </p>
                      </div>
                      <div className="testImageName">
                        <img src={Assets.test1} alt="Test Images" />
                        <div className="testName">
                          <span className="nameClient">Stacey Jeff </span>
                          <span className="ServicClient">Editing</span>
                          <div className="ratingStar">
                                                       <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="mainCOntentSlider">
                      <div className="testCon">
                        <p>
                        Self-help books can be a pain, but woah, I was super excited about the initial draft and I selected you guys for all the processes. My wife also loved the book and different family members are planning to write their autobiographies too 😊  Bellevue Publishers
                       </p>
                      </div>
                      <div className="testImageName">
                        <img src={Assets.test3} alt="Test Images" />
                        <div className="testName">
                          <span className="nameClient">Simon Peters </span>
                          <span className="ServicClient">Ghostwriting</span>
                          <div className="ratingStar">
                          <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="mainCOntentSlider">
                      <div className="testCon">
                        <p>
                        I was initially worried about writing the book from scratch,  Bellevue Publishers but I was confident that my ideas could help me out one day. I searched for a formidable platform that could help me out in writing the book from the scratch. Bellevue Publishers is not just a publishing medium, but they have helped me out in writing the book and they have formatted it too.
                        </p>
                      </div>
                      <div className="testImageName">
                        <img src={Assets.test2} alt="Test Images" />
                        <div className="testName">
                          <span className="nameClient">Bruce Williams</span>
                          <span className="ServicClient">Publishing & Printing</span>
                          <div className="ratingStar">
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div className="prevArrow">
                    <img src={Assets.testarrow} alt="Test Arrow" />
                    <span>Prev</span>
                </div>
                <div className="nextArrow">
                    <img src={Assets.testarrow} alt="Test Arrow" />
                    <span>Next</span>
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-3 testRight">
            <div className="pointereventnone">
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                autoplay={{
                delay: 8000,
                disableOnInteraction: false,
                }}
                navigation={true}
                effect={"fade"}
                modules={[Navigation]}
                centeredSlides={false}
                loop={true}
                loopFillGroupWithBlank={true}
                className="mySwiper testLeftImage"
              >
                <SwiperSlide>
                  <img src={Assets.test3} alt="Test Images" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Assets.test2} alt="Test Images" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={Assets.test1} alt="Test Images" />
                </SwiperSlide>
              </Swiper>
            </div>
            </div>
          </div>
        </div>
        <div className="servicesMarque">
            <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>     
              <li>Editing</li>        
              <li>Publishing</li>       
              <li>Marketing</li>        
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>          
      </section>
      <Blogs/>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Home;


