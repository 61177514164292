import React from "react"; 

import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { Helmet } from "react-helmet";
function TermsRefund() {
  return (
    <>
      <Helmet>
       <title>Terms Refund - Bellevue Publishers</title>  
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner AboutUsBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-12 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Terms & Refund
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      <section className="sec3 aboutSec2Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 innerPageDefaultContent bookContentmain">
            <p>The use of this website and/or our services signifies your agreement to the following terms and conditions:</p>
<ul>
    <li>If you do not agree with any of these terms and conditions, please refrain from using this website or our services.</li>
    <li>If you are below the legal age of consent in your jurisdiction, you are not permitted to access or use this website or our services.</li>
    <li>You acknowledge and agree that you must be of legal age to purchase any of our products or services available on this website.</li>
    <li>By submitting an order and/or payment, you acknowledge that you have read and understood these terms and conditions. You also agree to be legally bound by these terms and conditions, which constitute the entire agreement between you (referred to as "Client") and Bellevue Publishers.</li>
</ul>
<h3><strong>Definition of Terms</strong></h3>
<p>"Website" refers to all the online content on the pages of Bellevue Publishers.</p>
<p>"Customer," "You," or "Yours" refers to you or any other person submitting an order to Bellevue Publishers on your behalf.</p>
<p>"Company," "We," or "Our" refers to Bellevue Publishers, a company registered under the laws of the State.</p>
<p>"Product or Services" refers to all the services and products provided by Bellevue Publishers to a customer in accordance with their order.</p>
<p>"Order" refers to an order placed by a customer via phone or email to purchase services or products provided by Bellevue Publishers. Orders are confirmed through various payment methods, including checks, credit cards, cash receipts, bank wire transfers, Western Union, or PayPal transfers.</p>
<h3><strong>Our Services</strong></h3>
<p>It is important to carefully read and understand the refund policy to fully understand the rights and limitations governed by Bellevue Publishers' policy. Please review these terms and conditions before submitting any order or payment on this website.</p>
<h3><strong>Returns</strong></h3>
<p>Due to the nature of the products we produce, we do not accept returns on fulfilled orders.</p>
<p>However, if changes are required to meet quality standards, such as editing and proofreading services, and to ensure compliance with provided requirements, we have a revision process in place. This process is designed to assist customers and prioritize their satisfaction.</p>
<p>&nbsp;</p>
<p>If the fulfilled product does not meet the specified requirements stated at the time of order placement, we will conduct an extensive internal review and either revise or recreate the content at no additional cost.</p>
<h3><strong>Revisions</strong></h3>
<p>Each package has its own revision policy, as described in the service description of each specific package. We offer revisions within 5 to 10 days after the order status changes to "Waiting for Customer Approval" in our Order Management Platform. The revision period will depend on the chosen package.</p>
<p>Revisions come into play once the order has been presented for review and the status is updated to "Waiting for Customer Approval." At this stage, customers can request revisions by directly contacting their assigned Project Manager via email, ensuring the original requirements have been met for the entire product.</p>
<p>Customers are always welcome to make personal changes to the content they receive from us after the order has been fulfilled but before publishing. The content is delivered in Word format, and customers retain the rights to it.</p>
<p>Refunds at Bellevue Publishers are handled on a case-by-case basis. We take every measure to ensure that our clients and their needs are effectively addressed.</p>
<p>Our teams will collaborate with you to resolve any issues to the best of our ability.</p>
<p>Conditions for a refund may include but are not limited to, the following clauses.</p>
<h3><strong>Ineligibility of Refund Claims</strong></h3>
<p><strong>Minor Errors or Technicalities:</strong> Refunds will not be issued for late or delayed delivery resulting from minor errors or technicalities, such as grammatical errors, typing errors, word count compensation, or the addition of references.</p>
<p><strong>Client-Related Delays:</strong> We cannot be held responsible for delays caused by clients, and refunds will not be provided in such cases.</p>
<p><strong>Low Word Count:</strong> Refunds will not be accepted solely based on low word count.</p>
<p><strong>Lack of Concern Submission:&nbsp;</strong>Refunds will not be accepted if the client has not submitted a concern regarding the delivered work.</p>
<p><strong>Additional Requirements or Outrageous Demands:</strong> If delays occur due to the client's addition of new requirements or any outrageous demands that were not communicated at the time of order placement, refunds will not be accepted.</p>
<p>&nbsp;</p>
<p><strong>Exclusion of Tips:</strong> No refund claim will be accepted for tips provided to our writers or team members.</p>
<h3><strong>Change of Mind</strong></h3>
<p><strong>Before Work Initiation:</strong> If the client wishes to request a refund before our writers and editors have started working on the project, they may do so within one business day after placing the order. The request must be made in writing via email.</p>
<p><strong>After-Work Initiation:</strong> Refund claims made after the project has started will only be considered if the client has utilized at least three revisions. In such cases, a partial refund may be issued to fairly compensate our internal teams.</p>
<h3><strong>Late Delivery</strong></h3>
<p>At Bellevue Publishers, we guarantee on-time deliveries in all cases. However, if a project is not delivered on time for unforeseen or unfortunate reasons and the client has made at least three attempts to contact our service, a 50% refund will be processed. The client must provide documented evidence that the late delivery was due to a fault on our end.</p>
<p><strong>Client-Related Delays:</strong> Refunds for late deliveries will not be provided if the delay is caused by a fault at the client's end.</p>
<p><strong>Mutual Agreement:</strong> All refund cases will be settled through mutual agreement between the client and Bellevue Publishers.</p>
<p><strong>Partial Refunds:</strong> In certain cases, partial refunds or future discounts may be offered to the client as a form of compensation.</p>
<h3><strong>Inadequate Delivery</strong></h3>
<p>We are dedicated to ensuring that our clients receive high-quality work that meets their goals. We encourage clients to provide feedback, and we assign, reassign, and rewrite the work to achieve complete satisfaction. However, a refund request will only be accepted if the client has requested at least three revisions in documented form and has communicated their concerns to our support/project management team on at least three occasions within a two-week period.</p>
<p>During pre-sales or post-sales stages, all ongoing communication, progress updates, and delivery-related matters should take place through our project management online tool, Teamwork, official email, or by calling the assigned numbers. Please be cautious of any calls or emails received from random numbers or email addresses claiming to be associated with our company. Bellevue Publishers will not be responsible for any scams or fraudulent activities originating from such sources.</p>
             </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default TermsRefund;
