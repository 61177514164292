import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
function LandingPageButtonPrimary({ className = "btnPrimary openPopup", Text = "Request A Quote" }) {
  return (
    <button href="javascript:;" className={className}>
      <span>
        {Text} <FontAwesomeIcon icon={faArrowRight} />
      </span>
    </button>
  );
}

export default LandingPageButtonPrimary;
