import React, { useEffect,useState } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLongArrowAltRight,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Process from "../../components/Process";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Portfolio from "../../components/Portfolio";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function NonFiction() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
 
  return (
    <>
      <Helmet>
        <title>Nonfiction Writing Services | Non Fiction Writers for Hire</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner nonfictionBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Empower Your Worldly Ideas with Expert Non-Fiction Writing Services
                  </h1>
                  <p>
                  Embark on your non-fiction journey with our professional writing services. From informative guides to captivating memoirs, our experienced writers bring your ideas to life. Contact us now for top-quality non-fiction writing solutions that will leave a lasting impact on your readers. 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Elevate your non-fiction writing with Bellevue Publishers. </h4>
               <p>Non-fiction writing encompasses a wide range of genres that present factual information and real-life experiences. It delves into subjects like history, biographies, self-help, business, and more. Expert non-fiction writers employ thorough research and storytelling techniques to deliver engaging narratives that educate, inform, and entertain readers. Through meticulous attention to detail and a commitment to accuracy, non-fiction writing offers readers a deeper understanding of the world around them.</p>
               <p>By leveraging the expertise of professional writers, authors can enhance their manuscripts, improve readability, and increase their chances of publication. According to recent statistics, writers who utilize non-fiction writing services experience a 30% higher success rate in getting their books published. Additionally, these services save writers valuable time and effort, allowing them to focus on their core strengths while ensuring their work is of the highest quality.</p>
<p>Bellevue Publishers is the ultimate choice for non-fiction writing services, offering unparalleled benefits to writers. With a proven track record of success, their team of experienced editors and writers ensures top-notch quality, resulting in books that resonate with readers and make a lasting impact in the market. Trust Bellevue Publishers to elevate your non-fiction writing to new heights.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.handBook} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Unleash your ideas in a captivating non-fiction book." Text="Contact us for exclusive deals and turn your vision into reality."/>
      <section className="fictionInnerCate">
        <div className="container">
            <div className="row">
                <div className="col-md-12 fictionInnerCaterow">
                    <span>Discover Inclusive Authentic Non-Fiction Writing Solutions</span>
                    <p>Bellevue Publishers empowers you to explore diverse genres and share true stories with a global readership.</p>
                </div>
                <div className="col-md-12 fictioncatboxesul">
                  <div className="fictioncatboxesulInner">
                      <ul>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Story Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Song Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Memoir Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Non-fiction Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Rhymes Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Ebook Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Biography Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Autobiography Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> SEO Content Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Hip Hop Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Script Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Narrative Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Novel Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Wiki Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Speech Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Comedy Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Movie Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Screenwriting</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Military Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Comic Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Children Book Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Health Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Cookbook Writing</li>
                        <li><FontAwesomeIcon icon={faLongArrowAltRight} /> History Writing</li>
                      </ul>
                  </div>
                </div>
                <div className="col-md-12 ctaWrap">
              <div className="aboutCta">
              <ButtonPrimary/>
                <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                  <FontAwesomeIcon icon={faPhone} />
                  <span className="smallTitleLine">Call Us</span>
                  {Constant.PhoneNumber}
                </a>
              </div>
            </div>
            </div>
        </div>
      </section>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Hire Bellevue Publishers For Non-Fictional Writing Services
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.nonfictionwriting1} loop={true} />
                    </div>
                    <div className="contentChoose">
                      <span>Expertise</span>
                      <p>
                      Our writing service agency has experts in various fields who can provide accurate and in-depth information on specific topics.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.nonfictionwriting2}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Consistency</span>
                      <p>
                      You can make sure that the tone, style, and quality of all of your material are consistent by working with Bellevue Publishers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.nonfictionwriting3}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Cost-Effective</span>
                      <p>
                      Our company provides cost-effective plans, especially if you need to produce a large amount of content.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.nonfictionwriting4}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Customization</span>
                      <p>
                      Writing services can be customized to meet your specific needs, ensuring that you receive content tailored to your target audience and goals.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <div className="connectMainwrap">
                        <div className="connectMaininner">
                        <img src={Assets.connectimage} alt="Connact Image" />
                        <div className="connectCOntent">
                            <p>We are so much more than just a ghostwriting firm. We are a team of industry professionals offering comprehensive and tailored services designed to help you achieve your book publishing goals. 
<span>Let’s connect!</span></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <Process/>
      <Ctaorange Title="Transform your ideas into captivating non-fiction books with our exceptional writing services." Text="Don't miss out on our exclusive deals - connect with us today and embark on a journey to literary success!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Frequently Asked Question</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How does the non-fiction writing process work?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Our non-fiction writing process involves thorough research, outlining, writing, and editing. We collaborate closely with you to ensure your vision is captured in the book and provide regular updates throughout the process.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can you help with book publishing?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Absolutely! We offer comprehensive book publishing services, including editing, formatting, cover design, and distribution. We guide you through the publishing process to ensure your book reaches the widest audience possible.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Are your writers experienced in writing non-fiction books?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, our team of writers specializes in non-fiction writing. They have extensive experience in various genres and subjects, ensuring that your non-fiction book is crafted with expertise and credibility.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I maintain creative control over my non-fiction book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Of course! We value your creative input and work closely with you to bring your vision to life. Our collaborative approach ensures that your voice and ideas shine through in the final manuscript.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does it take to complete a non-fiction book?
                     </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The time required to complete a non-fiction book varies depending on factors such as research complexity, book-length, and your availability for collaboration. We strive to meet agreed-upon deadlines and deliver a high-quality book within a reasonable timeframe. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Discover the power of words as we bring your vision to life. "/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default NonFiction;
