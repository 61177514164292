import React, { useEffect,useState } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Girlquote from "../../components/Girlquote";
import Testimonials from "../../components/Testimonials";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function Horror() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);

  return (
    <>
      <Helmet>
         <title>Horror Book Writing Services| Horror Writers for Hire - Bellevue</title>
       </Helmet>
      <Header/>
      <section className="sec1 innerBanner horroBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Terrorize Your Audience with Nail-Biting Horror Story Writing Services 
                  </h1>
                  <p>
                  Who can write a paranormal story better than a ghostwriter? Our professional horror book writers can surely give your audience a scare with their exceptional horror story writing skills! Get the right balance of suspense and horror to keep readers on edge! 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Writing a Horror Story is Not for The Faint-Hearted; Choose Our Best Horror Book Writers </h4>
                <p>
                You can either love or loathe the horror genre, but you can't ignore it because each scene in the genre is always suspenseful and intriguing. Our skilled horror book writers at Bellevue Publishers, make sure to provide your audience exactly what they want in the horror genre, whether it's a jumps care or the setup of an unsettling scene. </p>

                <p>In order to create the tone for any horror fiction, our horror book writers work with you to create gripping, realistic settings. These settings can be alien, paranormal, zombie, psychological horror, ghost stories, horror comedy, and much more. With Bellevue Publishers, the options are virtually limitless. We let readers to escape reality by capturing their interest with a variety of twists and turns.
                </p>
                <p>Horror writing services provide you with a scope of tapping into various genres like romance, horror, paranormal or anomaly, and zombie to the three main genres of gross-out, horror and terror. Our horror fiction writers know how to elicit a response from the audience through their craft. Horror is enjoyable for many readers because it portrays negative responses from the audience but in an enjoyable manner, startle and shock your audience with unnatural and eerie horror books! </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.Horror} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Most professional horror story writers are for hire only at Bellevue Publishers! " Text="Connect with us now to get the best deals! "/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why You Should Go for <br></br> Bellevue Publisher’s Horror Story Writing Services
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.horrorbookwriting1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Professional Horror Ghost Writers in the USA </span>
                      <p>
                      Let's say your goal is to become a best-selling horror author and an expert in the genre. You require expert assistance from ghostwriters who have authored several best-selling novels. The best place to find them is to employ them through a firm such as Bellevue Publishers, where you can quickly get in touch with a group of highly skilled professionals!
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.horrorbookwriting2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Leave an Impact with Your Horror Story </span>
                      <p>
                      A bland horror story that doesn't stick with readers is probably the worst fear for a horror writer. When we write a horror book, we strive to give the audience something they can never forget, and a good horror story always lingers on for a few days in a reader's mind. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.horrorbookwriting3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Master The Horror Genre  </span>
                      <p>
                      A stimulating horror story is what is craved by so many fans around the globe! {Constant.CompanyName}, helps close the gap between the global audience and you, so your stories are broadcasted everywhere in the world! Many horror authors stick to this genre after one hit because mastering it is not easy. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.horrorbookwriting4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Continuous Assistance  </span>
                      <p>
                      Our ethos and commitment to serving you with top-quality work and services are what keep our clients satisfied. Hire us for one novel, and we promise you will come back for more! After completing your project, we take your feedback to ensure that our journey together was fruitful. 
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Most professional horror story writers are for hire only at Bellevue Publishers!" Text="Connect with us now to get the best deals! "/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Got a Question? Let's Address Your Queries  </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are horror story writing services?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    A well-structured horror story has all the aspects of character development, eerie atmosphere, scary and startling scenes and the suspense of a dramatic event. All of these aspects are channeled in a manuscript by professional ghostwriters, which you can find at Bellevue Publishers.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can a horror storybook really scare people?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Yes, it is often misunderstood that horror stories can only be enjoyed if you watch them. A jarring professional horror story written by expert horror fiction writers can shake the readers and leave them up for nights! 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much does a horror writing service cost? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on the book's length, any book service can cost anywhere between $20 to $1000 or more. {Constant.CompanyName} have an affordable range where we make sure to provide flexibility on our rates with no compromise on quality. 

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Does Bellevue Publishers provide services other than horror book writing?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, {Constant.CompanyName} is a full-fledged ghost book writing service; from horror story writing to romance novel writers, we are a one-stop shop for all genres and ghostwriters in the USA.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Horror Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Horror;
