import React, { useEffect } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Girlquote from "../../components/Girlquote";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";

import { useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faChevronDown,
  faPhone,
  faLongArrowRight,
  faStar,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Helmet } from "react-helmet";
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";

function MemoireWriting() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);

     
  return (
    <>
      <Helmet>
        <title>Memoir Writing Services | Hire Memoir Writer - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner memoireBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Unveil Your Journey: Craft Extraordinary Memoirs with Bellevue Publishers.
                  </h1>
                  <p>
                  Capture your cherished memories and share your life's journey through our expert memoir writing services. Our experienced writers possess the unique ability to transform your personal anecdotes into a captivating narrative that resonates with readers. Trust Bellevue Publishers to craft a memoir that beautifully encapsulates your life's remarkable moments. </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Illuminate Your Journey with Memoir Writing.</h4>
                <p>A memoir is a compelling narrative that unveils the personal experiences, reflections, and emotions of an individual. It goes beyond mere recollection, delving into the profound aspects of one's life journey. Through vivid storytelling, a memoir offers readers a captivating glimpse into the author's unique perspective, wisdom, and transformative moments.</p>

                <p>Memoir writing services offer professional assistance to individuals who want to share their life stories in a compelling and engaging manner. By collaborating with experienced writers, authors can transform their memories into well-crafted narratives that resonate with readers. These services provide expertise in structuring the memoir, capturing the author's voice, and enhancing the storytelling elements.</p>
                <p>The benefits of memoir writing services are manifold. Not only do they help individuals preserve their personal legacy, but they also offer opportunities for self-reflection and healing. Memoirs allow authors to connect with readers on a deep and emotional level, inspiring, educating, and entertaining them. According to recent studies, memoirs are gaining popularity, with a 400% increase in sales in the past decade. Hiring professional memoir writing services ensures a polished and impactful memoir that can reach a wide audience and leave a lasting impression.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.memorieHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Transform Your Memories into a Lasting Memoir." Text="Connect with us now for the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Choose Bellevue Publishers for Memoir Writing Services? 
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.memoirwriting1} loop={true} />
                    </div>
                    <div className="contentChoose">
                      <span>Beyond Your Story </span>
                      <p>
                      We go beyond storytelling, adding vivid descriptions to evoke genuine emotions in your memoir.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.memoirwriting2}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Effective Marketing Strategies</span>
                      <p>
                      Benefit from our comprehensive publishing and marketing plans for maximum book exposure.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.memoirwriting3}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Captivating Prose </span>
                      <p>
                      Our talented writers create purposeful and immersive narratives that resonate with readers on a personal level.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.memoirwriting4}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Privacy and Security </span>
                      <p>
                      Write your memoir while we handle the professional aspects, ensuring a bestselling and unforgettable masterpiece.xx``
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <section className="sec12 TestiSliderMain">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 whyChooseLeft">
          <div className="abtLeft">
            <span className="smallTitleLine">Testimonials</span>
            <h4>
            What Do Our Clients say About Us?
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-12 blogSlider">
        <Swiper
          slidesPerView={3}
          spaceBetween={120}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          centeredSlides={false}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={false}
          breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">SJ </span>
            <div className="nameStars">
              <span className="clientNameTesti">Sarah Johnson</span>
              <div className="ratingStar">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Working with Bellevue Publishers was like stepping into a time machine. Their skilled team captured the essence of my memories with such authenticity and emotion. My memoir feels like a heartfelt conversation with readers. I couldn't be happier with the final result.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">JA</span>
            <div className="nameStars">
              <span className="clientNameTesti">James Thompson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I never thought my life story could be transformed into a captivating memoir until I found Bellevue Publishers. Their dedication to detail and knack for storytelling brought my experiences to life on the pages. It's a beautiful tribute to my journey, and I highly recommend their memoir writing services.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">EW</span>
            <div className="nameStars">
              <span className="clientNameTesti">Emma Wilson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers turned my jumbled memories into a compelling narrative that readers can't put down. The way they wove together the threads of my life is remarkable. It's like they knew me personally. If you're looking to share your story, these talented writers are the ones to trust.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">AJ</span>
            <div className="nameStars">
              <span className="clientNameTesti">David Adams</span>
              <div className="ratingStar">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>When I read my memoir, I was transported back in time. Bellevue Publishers captured the essence of my experiences and emotions so vividly. It's a testament to their skill and dedication. If you want a memoir that touches hearts, look no further than their exceptional writing services.</p>
          </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">RM</span>
            <div className="nameStars">
              <span className="clientNameTesti">Rebecca Martinez</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers exceeded my expectations with their memoir writing services. They took my fragmented memories and crafted a cohesive narrative that beautifully captures the essence of my life. The professionalism and attention to detail displayed by their team are truly commendable.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">JA</span>
            <div className="nameStars">
              <span className="clientNameTesti">John Anderson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers turned my life story into a powerful memoir that resonates with readers. Their ability to capture the essence of my journey is extraordinary. It's like they crawled into my soul and translated my experiences into words. I am forever grateful for their remarkable work.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">LR</span>
            <div className="nameStars">
              <span className="clientNameTesti">Lisa Roberts</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Working with Bellevue Publishers was a transformative experience. Their talented team listened to my stories with empathy and crafted a memoir that reflects my voice and emotions. The authenticity and depth they brought to my narrative are beyond words. I highly recommend their memoir writing services to anyone who wants to leave a lasting legacy.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">MC</span>
            <div className="nameStars">
              <span className="clientNameTesti">Michael Clark</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers gave my memoir a voice I never thought possible. Their skilled writers took my raw memories and transformed them into a beautifully written masterpiece. The attention to detail and commitment to capturing the essence of my experiences is remarkable. I couldn't be happier with the result.</p>
          </div>
            </div>
          </SwiperSlide>
          
        </Swiper>

        </div>
      </div>
    </div>
  </section>
      <Blogs/>
      <Ctaorange Title="Unlock the Power of Your Memories with Our Memoir Writing Services" Text="Connect with Bellevue Publishers to Transform Your Story into an Unforgettable Memoir."/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">FAQs</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is memoir writing?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Memoir writing is the process of documenting personal memories, experiences, and reflections in a narrative form. It captures significant events, emotions, and life lessons, providing a deep insight into the author's life journey. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Why should I hire a professional for memoir writing?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Hiring a professional for memoir writing ensures that your story is crafted with skill and expertise. Professionals have the ability to structure and present your memories in a compelling way, enhancing the readability and impact of your memoir.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does the memoir writing process take?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The duration of the memoir writing process can vary depending on factors such as the complexity of the story and the level of research required. Typically, it can take several months to complete a memoir, including the writing, editing, and revision stages. </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Will my memoir be kept confidential?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Absolutely. At Bellevue Publishers, we prioritize the confidentiality and privacy of our clients. Your memoir and personal information will be handled with the utmost care and discretion.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I have input in the memoir writing process?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, we highly value your input and involvement in the memoir writing process. Our team works closely with you, conducting interviews and gathering information to ensure that your voice and perspective are authentically represented in the memoir. Your feedback and suggestions are always welcomed and considered.</p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Memoir Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
     <FooterForm/>
      <Footer />
    </>
  );
}
export default MemoireWriting;
