import React, { useEffect } from "react"; 
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";

import { useRef } from 'react';
import { Helmet } from "react-helmet";

function Action() {
  
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  const navigate = useNavigate()

//navigate(0)
  
  return (
    <>
      <Helmet>
        <title>Action & Adventure Writing Services - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner actionBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  High-Intensity Sequences and Thrilling Action Writing Services In the USA 
                  </h1>
                  <p>
                  Feature an action-packed book filled with realistic fight sequences that make the audience go "oooh" with our action writing services. Our professional action writers can shock and entertain the audience with scenes that will pump your adrenaline and make you root for the protagonists! 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Hiring Action Writers Has Never Been Easier!</h4>
                <p>
                You don't have to worry about finding the ideal retribution or synopsis for any of your stories, dangerous characters, antagonists, or potentially fatal events when working with Bellevue Publishers. Our authors create action-adventure masterpieces that allow readers to escape reality and lose themselves in novels with spectacular, life-size settings.
                </p>

                <p>Getting all of the elements of an action storyline is definitely for the experts; getting the fast-paced scenes that can sometimes be gory and violent are hard to get right in a book! The art of giving the exact details without grossing out the readers is a task for our action writers.
                </p>
                <p>Many details can be difficult to get right for rookie writers and can lead to making your book plain and dull, and an action book can be everything but boring! The most demanding part of an action book is giving the audience the gratification they need, be it triumphing over the villain at the end or finally unleashing the beast mode of the main hero! </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.actionBookHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Entertain your audience with heart-pounding action writing services!" Text="Connect with us now to get the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Our Action Writing Services Are Unprecedented 
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.actionwriting1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Crafting Stories, Not Just Words </span>
                      <p>
                      Our goal at {Constant.CompanyName} is to elevate your story to new heights through book writing, not merely for financial gain! With our experience, you can market and publish your books internationally to get the word out!
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.actionwriting2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Be Your Own Boss</span>
                      <p>
                      You can always count on being in charge when working with Bellevue Publishers since we treat our customers like family. Our services are focused on you, from several revisions to enhancing the authenticity and tone of your writing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.actionwriting3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Unleash Your Imagination</span>
                      <p>
                      After a collaborative brainstorming session; through this draft, you can express your thoughts, ideas, character design, and main theme for your book till you are content with it. With the help of our book writing services, we will put it into words and draft a manuscript that is a reflection of your imagination. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.actionwriting4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Multiple Features </span>
                      <p>
                      Through our series of features like ghostwriting, formatting, editing, and a panel of specialists, we not only make the best action writers but combine multiple layers of book writing to produce a masterpiece that can be published on top sites like Amazon. 
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <div className="connectMainwrap">
                        <div className="connectMaininner">
                        <img src={Assets.connectimage} alt="Connact Image" />
                        <div className="connectCOntent">
                            <p>We are so much more than just a ghostwriting firm. We are a team of industry professionals offering comprehensive and tailored services designed to help you achieve your book publishing goals. 
<span>Let’s connect!</span></p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Entertain your audience with heart-pounding action writing services!" Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Frequently Asked Questions</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much do ghostwriters of the Bellevue Publishers charge for a book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on what type of service you select, our expert ghostwriting services have a range of prices. The price would be roughly the same for fiction, non-fiction, memoirs, and biographies. The pricing depends on your project's duration, preferences, and priority level. You can fill out the form above to receive a free estimate and learn the service's precise cost.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is ghostwriting an illegal service?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>No, it's not against the law to ghostwrite. A writer can be hired to ghostwrite, which is a legal service, to produce content for someone else, frequently without giving them credit for their work. Ghostwriters are often used by writers, businesspeople, and public figures to assist them in expressing their ideas or narratives.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                         How to hire a ghostwriter?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on what type of service you select, our expert ghostwriting services have a range of prices. The price would be roughly the same for fiction, non-fiction, memoirs, and biographies. The pricing depends on your project's duration, preferences, and priority level. You can fill out the form above to receive a free estimate and learn the service's precise cost.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do you become a ghostwriter?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on what type of service you select, our expert ghostwriting services have a range of prices. The price would be roughly the same for fiction, non-fiction, memoirs, and biographies. The pricing depends on your project's duration, preferences, and priority level. You can fill out the form above to receive a free estimate and learn the service's precise cost.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Action Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
     <FooterForm/>
      <Footer />
    </>
  );
}
export default Action;
