import React, { useEffect } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import ReactDOM from 'react-dom';
import Countdown,{zeroPad} from 'react-countdown';
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLongArrowAltRight,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import Girlquote from "../../components/Girlquote";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Process from "../../components/Process";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Portfolio from "../../components/Portfolio";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function BookMarketing() {
  useEffect(() => {
    // Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    //return <Completionist />;
  } else {
    // Render a countdown
    return <span className="countHas"><b><span>{zeroPad(hours)}</span></b>:<b><span>{zeroPad(minutes)}</span></b>:<b><span>{zeroPad(seconds)}</span></b></span>;
  }
};
ReactDOM.render(
  <Countdown
        date={Date.now() + 5000000}
        renderer={renderer}
      />,
      document.getElementById('counthas')
    );
  }, []);
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Book Marketing Services | Hire a Book Marketing Company</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner bookmarketingBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Maximize Your Ebook’s Potential with Our Marketing Services
                  </h1>
                  <p>
                  Reach a wider audience, increase sales, and boost your author brand with our strategic ebook marketing solutions. From effective online promotions to targeted advertising campaigns, we provide comprehensive strategies tailored to your unique goals. Connect with us now to take your ebook marketing to the next level!
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-6 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Acquire Bellevue Book Publishers’ Book Marketing Services for Your Maximum Success. </h4>
                <p>
                Book marketing is a strategic process aimed at promoting and increasing the visibility of a book to its target audience. It involves a range of activities, including online and offline promotions, advertising, social media campaigns, book reviews, author events, and more. Effective book marketing helps generate interest, attract readers, and drive book sales. With a well-planned and executed book marketing strategy, authors can effectively showcase their work, build their author brand, and maximize their book’s reach and impact in the market.
                </p>
                <p>Book marketing offers several benefits for authors. According to recent statistics, books that are actively marketed have a 50% higher chance of being discovered by readers. It helps increase book sales, boosts author visibility and credibility, expands the author’s audience reach, and enhances their author brand.  </p>
               <p>Choose Bellevue Publishers for your book marketing needs and experience unparalleled success. With our extensive industry knowledge and innovative marketing strategies, we have helped countless authors achieve remarkable results. Our dedicated team ensures maximum exposure for your book, reaching target audiences and driving sales. Trust us to elevate your author brand and propel your book to new heights.
</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 reliable-agencyRight bookmarketingRightImage">
              <img src={Assets.bookmarketingImage} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <section className="ghostTabs">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostabsrow">
                    <span>Explore Our Comprehensive Book Marketing Services</span>
                </div>
                <div className="col-md-6 ghostabsmain">
                    <div className="custom-tab-list-ghost">
                    <ul>
                    <li data-targetit="box-bookpro" className="active">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookmarketingtab1} alt="Icon"   /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Author Website Creation </span>
                            <p>Gain a competitive edge with an SEO-optimized author website that promotes your book across multiple platforms. Discover effective marketing strategies tailored to your target audience.</p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-authweb" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookmarketingtab2} alt="Icon"   /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">E-Book SEO Marketing </span>
                            <p>Our exceptional SEO technicians offer you assistance with optimizing your online and web content so you can improve your ranking on SERPs. </p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-bookfr" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookmarketingtab3} alt="Icon"   /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Book Distribution and Publication</span>
                            <p>Trust Bellevue Publishers to market and publish your books, transforming them into valuable leads and reaching wider audiences. </p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-proofed" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookmarketingtab4} alt="Icon"   /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Lead-Generating Strategies </span>
                            <p>Leave the book-selling worries to our skilled book marketing experts. We’ll employ proven strategies to generate leads and maximize your book’s success. </p>
                        </div>
                        </a>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="col-md-6 ghostdetails">
                <div className="box-bookpro showfirst">
                <div className="tabCont">
                  <p>
                  Embark on your journey with a professionally designed author’s website. Our experts leverage their expertise to craft tailored marketing strategies, considering demographics and conducting thorough niche-specific market analysis. We provide you with the optimal opportunity to publish and promote your books successfully. 
                  </p>
                  <p> Discover the myriad ways Bellevue Book Publsihers help you establish a robust online presence:</p>
                  <ul>
                    <li>Build a captivating website that showcases your brand and engages readers.</li>
                    <li>Drive sales and connect with your audience through effective online engagement strategies.</li>
                    <li>Comprehensive marketing support and guidance to enhance your book’s visibility.</li>
                    <li>Reveal the person behind the books through engaging website content and multimedia presentations.</li>
                  </ul>
                </div>
              </div>
              <div className="box-authweb">
                <div className="tabCont">
                  <p>
                  Our ebook promotion services include promoting books on global platforms like Amazon with KDP guidelines for worldwide exposure. SEO helps you get the right amount of exposure and digital footprint which helps you market your books to a larger audience and can reach more people. With the correct SEO integration, Google recognises your website as reliable which ultimately leads to a better recognition on the search engines. 
                  </p>
                  <p>Through optimization, you can make your sites more authentic, reliable, and professional. Our ebook SEO marketing experts ensure your books are equipped with the right optimization tools and perform in-depth keyword research. We can incorporate multiple genre-appropriate insights into your websites and social media accounts to reach numerous people and garner organic leads. </p>
                </div>
              </div>
              <div className="box-bookfr">
                <div className="tabCont">
                  <p>
                  From self-publishing on Amazon to other sites, we champion your genre research and find the best platform for you with a wide distribution channel. We help you distribute books to not only your target audience but through right marketing tools and top-of-the-chart publishers, we elevate your book’s recognition.  
                  </p>
                  <p>When we publish your books, we prepare your books for pre-launch, post-launch, and everything in between. We market your books and reach as many people as possible, so it builds the momentum for your final book release. Our services also include marketing through video trailers, online book posts and posters, social media engagement strategies, live streams, and much more! </p>
                </div>
              </div>
              <div className="box-proofed">
                <div className="tabCont">
                  <p>
                  Our experts design strategies to build an audience and techniques that help you sell your books, ultimately making your books bestsellers. 
                  </p>
                  <p>Our book marketing plans do not just last till the book launch; they also revolve heavily around the post-launch. Our book marketing company evaluates your numbers and sales and devises strategies to increase leads. Our sales-oriented plans help you earn revenue and commence your journey as a successful writer. </p>
                  <p>We are fully capable of generating leads for your books, you can analyze real-time sales and visitors through your websites, through our collective digital media strategies you can get organic leads. Our full-cycle strategies are based on reach-oriented marketing. </p>
                </div>
              </div>
                </div>
            </div>
        </div>
      </section>
      <section className="sec13 bookmarketMobile">
        <div className="container">
          <div className="row">
            <div className="col-md-5 bookmarketMobileLeft">
              <h3 className="bookheadingmain">What's New? 
              Stay Updated with Our Latest Audiobook Releases!
</h3>
<p>Experience the finest collection of self-published audiobooks that are high quality, informative, and budget-friendly. </p>
<span className="earnText">Explore our latest additions and embark on an immersive audio journey. </span>
<div id="counthas"></div>
<div className="btnBanner">
                  <ButtonPrimary Text="Know more" className="btnPrimary"/>
                </div>
            </div>
            <div className="col-md-7 bookmarketMobileRight">
              <img src={Assets.bookmarketingmobilescreen} alt="Mobile Images" />
            </div>
          </div>
        </div>
      </section>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Choose Us for Unmatched Book Marketing
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.bookmarketing1} alt="Icon"   />
                    </div>
                    <div className="contentChoose">
                      <span>Premium Marketing Services </span>
                      <p>
                      Choose Us for Unmatched Book Marketing.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookmarketing2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>No Compromise on Quality </span>
                      <p>
                      We prioritize your success, ensuring no compromises in our marketing efforts. From meticulous planning to strategic execution, we go the extra mile for your book’s success.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookmarketing3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Expert Publishing </span>
                      <p>
                      Engage on social media, target relevant book communities, and craft tailored strategies. Our meticulous attention to detail ensures every aspect of your project is handled with excellence.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookmarketing4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Real-Time Results</span>
                      <p>
                      Our company believes in generating sales for you so you can earn and make a profit in real-time. We offer the best pacakges to boost your sales and generate leads!
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Ctafirst Title="Reach Your Target Audience With Our Strategic Book Marketing Plans!" Text="Contact Us Today for Exclusive Deals!"/>
      <section className="sec9 philMain ProContent">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ProContentLeft">
              <div className="abtLeft">
                <h4>Powerful Book Marketing Solutions for Success </h4>
                <p>
                At Bellevue Publishers, we implement a comprehensive range of strategies to ensure the success of your book marketing campaign. Our expert team utilizes social media marketing to engage with your target audience, create compelling content, and build a strong online presence. We also offer professional web development services to design an author website that showcases your work effectively. Additionally, we employ email marketing, influencer collaborations, and targeted advertising to reach a wider audience and maximize your book’s visibility in the market. Trust us to implement the right strategies for your book’s success. Our book marketing packages include:
                </p>
                <div className="makesContent">
                  <ul>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Social media marketing </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Author’s Website </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Book Promotion </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Book reviews </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> SEO-based articles on relevant platforms </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Book trailers </li>
                  </ul>
                </div>
                <div className="ctaWrap">
                  <div className="aboutCta">
                    <ButtonPrimary />
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 proconImageLeft">
              <img src={Assets.proImage} alt="Platform Images" />
            </div>
          </div>
        </div>
      </section>
      <Process/>
      <section className="sec9 philMain ProContent ProContentShip">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ProContentLeft">
              <div className="abtLeft">
                <h4>Expand Your Reach. Anywhere. Anytime</h4>
                <p>
                Unlock custom marketing bundles tailored to your needs. Stay ahead of the curve with our cutting-edge strategies, ensuring your books reach a global audience. Let us help you spread your word and connect with readers worldwide.
                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                    <ButtonPrimary />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 proconImageLeft">
              <img src={Assets.bookmarketingImage2} alt="Platform Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctaorange Title="Reach your audience with our strategic book marketing plans!" Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">FAQs about Book Marketing:</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is book marketing, and why is it important?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Book marketing is the process of promoting and advertising books to reach a wider audience and increase sales. It is important because it helps authors build visibility, engage readers, and generate interest in their books.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How to sell books? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Probably the most frequent question for any author is to figure out how they are going to sell their book. Writing and marketing a book are two different things; you need the expertise of professional ebook marketing specialists who would do a full run-down of your requirements and market your books with the right approach. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How do I get my book to my audience? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our marketing specialists offer you comprehensive plans that help you market your book to the right audience. It is our job to market your book and connect you to your targeted audience; leave it to us!

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What can I expect your book marketing company to do? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    You can acquire multiple services to promote your books through multi-channels and platforms when you hire our book marketers. You can make manuscripts that are download-ready and garner sales and revenue. 

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Book Marketing Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
     <FooterForm/>
      <Footer />
    </>
  );
}
export default BookMarketing;
