import React, { useEffect } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import ReactDOM from 'react-dom';
import Countdown,{zeroPad} from 'react-countdown';
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLongArrowAltRight,
  faStar,
  faArrowRight,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BannerForm from "../../components/BannerForm";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Process from "../../components/Process";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Portfolio from "../../components/Portfolio";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import Testimonials from "../../components/Testimonials";
import { useRef } from 'react';

function BookPrinting() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  const navigate = useNavigate();

//navigate(0)
  return (
    <>
      <Helmet>
        <title>Book Printing Services | Hire a Book Printing Company</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner bookprintingBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Professional Book Printing Services
                  </h1>
                  <p>
                  Bring your words to life with our top-notch book printing solutions. From design to high-quality printing, we ensure your book is a masterpiece. Trust our expertise for exceptional results. Contact us today and experience the excellence of our printing services.  
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-6 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Experience the Superiority of Professional Book Printing </h4>
                <p>
                Professional book printing services elevate your publication to new heights. At Bellevue Publishers, we excel in delivering exceptional quality, precision, and attention to detail. With cutting-edge printing technology and a team of experts, we ensure your book is a true masterpiece. Trust Bellevue Publishers for the highest standards in book printing, making your work shine among the rest.
</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 reliable-agencyRight bookmarketingRightImage">
              <img src={Assets.bookprintingBook} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <section className="sec12 ghostagecnyContent bookprintingSec">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Professional Book Printing Services In the US
                        </span>
                </div>
                <div className="col-md-12 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.printingIcon1} alt="Icon Images" />
                    </div>
                    <div className="contentChoose">
                      <span>Customized On-Demand Printing </span>
                      <p>
                      We recognize that every book deserves special treatment. Our commitment to excellence means following the highest printing standards, resulting in stunning finished products that make your books shine.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.printingIcon2} alt="Icon Images" />
                    </div>
                    <div className="contentChoose">
                      <span>Unparalleled Quality </span>
                      <p>
                      We understand the importance of visuals in book printing. With meticulous attention to detail and the use of premium materials, we ensure your books are printed to perfection with a professional touch.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.printingIcon3} alt="Icon Images" />
                    </div>
                    <div className="contentChoose">
                      <span>Versatile Formats</span>
                      <p>
                      We embrace flexibility and offer a diverse range of services. Collaborate with our knowledgeable support team to create standout books through a guided process tailored to your needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.printingIcon4} alt="Icon Images" />
                    </div>
                    <div className="contentChoose">
                      <span>Unwavering Support</span>
                      <p>
                      Our representatives and agents are always at your service. Whether you have questions or seek post-service reviews, we prioritize open communication to ensure your satisfaction is guaranteed.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.printingIcon5} alt="Icon Images" />
                    </div>
                    <div className="contentChoose">
                      <span>Rapid Turnaround</span>
                      <p>
                      Count on us for prompt deliveries and the ability to handle tight deadlines. Our agile team of expert printing publishers never shies away from commitments, ensuring your projects are completed swiftly.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                    <img src={Assets.printingIcon6} alt="Icon Images" />
                    </div>
                    <div className="contentChoose">
                      <span>Client-Centric Approach</span>
                      <p>
                      Our clients are more than just customers; they are our valued partners. We go the extra mile to fulfill your requirements and foster strong relationships through transparent communication and unwavering dedication.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
            </div>
        </div>
      </section>
      <section className="sec7 processMain whiteCta">
    <div className="container">
      <div className="row">
        <div className="col-md-12 processTitle whiteTitle">
          <span className="smallHeading lineBar">The Roadmap to Our Professional </span>
          <span className="heading2">Book Printing Services</span>
          <p>Elevate your book with Bellevue Publishers' seamless printing process. From design to high-quality printing, we ensure your vision comes to life with exceptional precision and attention to detail.</p>
        </div>
        {/* <div className="col-md-12 Processimage">
          <div className="proImag proDesktop">
          <img src={Assets.proImg} alt="Book Image" />
          </div>
          <div className="proImag proMobile">
          <img src={Assets.proImgRes} alt="Book Image" />
          </div>
        </div> */}
        <div className="col-md-12 ctaWrap">
          <div className="aboutCta">
          <ButtonPrimary/>
            <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
              <FontAwesomeIcon icon={faPhone} />
              <span className="smallTitleLine">Call Us</span>
              {Constant.PhoneNumber}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="sec7 processMain whiteCta greenBG">
    <div className="container">
      <div className="row">
        <div className="col-md-12 processTitle whiteTitle">
          <span className="heading2">Ready to Bring Your Projects to Life?</span>
          <p>Experience our top-notch printing services. Connect with our professionals to explore customizable on-demand printing formats and transform your ideas into reality. Start printing with us today!</p>
        </div>
        <div className="col-md-12 ctaWrap">
          <div className="aboutCta">
          <a href="javascript:;" className="btnPrimary">
              <span>
              Select Printing Details <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="sec12 TestiSliderMain">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 whyChooseLeft">
          <div className="abtLeft">
            <span className="smallTitleLine">Testimonials</span>
            <h4>
            What Our Clients Have To Say About Us?
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-12 blogSlider">
        <Swiper
          slidesPerView={3}
          spaceBetween={120}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          centeredSlides={false}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={false}
          breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">JC </span>
            <div className="nameStars">
              <span className="clientNameTesti">Sarah M.</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I was blown away by the quality of printing Bellevue Publishers provided for my novel. The vibrant colors and crisp text made my book truly come alive. I couldn't be happier with the result!</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">HR</span>
            <div className="nameStars">
              <span className="clientNameTesti">Michael T.</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers made the printing process seamless and stress-free. Their attention to detail and commitment to customer satisfaction ensured that my children's book turned out exactly as I envisioned. Highly recommended!</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">EM</span>
            <div className="nameStars">
              <span className="clientNameTesti">Emily S.</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>The team at Bellevue Publishers went above and beyond to meet my tight deadline. Not only did they deliver my printed books on time, but the quality was exceptional. Their professionalism and efficiency exceeded my expectations.</p>
          </div>
            </div>
          </SwiperSlide>
          
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">PN</span>
            <div className="nameStars">
              <span className="clientNameTesti">John R.</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I had a wonderful experience with Bellevue Publishers for my self-published memoir. The friendly staff guided me through the printing process, offering valuable advice and producing a final product that exceeded my expectations. I am grateful for their expertise and support.</p>
          </div>
            </div>
          </SwiperSlide>
          
        </Swiper>

        </div>
      </div>
    </div>
  </section>
      <Ctaorange Title="Elevate Your Books With Professional Printing - Convenient, Fast, And Exceptional!" Text="Unlock the best deals by contacting us today! Experience the superior quality of our printing services."/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Elevate Your Books With Professional Printing - Convenient, Fast, And Exceptional!</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What file formats do you accept for book printing?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We accept commonly used file formats such as PDF, Adobe InDesign, and Microsoft Word. Our team can guide you on the best format for optimal printing results.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I choose the cover finish for my books?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Absolutely! We offer a range of options and cover finishes to suit your preferences. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does the printing process usually take?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The timeline for printing varies depending on factors like the complexity of the project and the quantity ordered. We strive to provide you with an estimated turnaround time when you place your order.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do you offer proofreading and editing services for book manuscripts?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    While our primary focus is on printing, we can recommend professional proofreading and editing services to ensure your manuscript is polished before printing.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I order a small quantity of books, or is there a minimum order requirement?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We cater to both small and large print runs. Whether you need a few copies or a bulk order, we can accommodate your needs. There is no minimum order requirement.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Embark on your journey to success today!"/>
          </div>
        </div>
      </section>
     <FooterForm/>
      <Footer />
    </>
  );
}
export default BookPrinting;
