import React, { useRef,useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Constant } from "../../Constants";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
function FooterForm() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const url = window.location.href;
  function handleKeyPress(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  function handleChange(event) {
    setPhoneNumber(event.target.value);
  }

  const location = useLocation();
  const currentUrl = location.pathname;
  const form = useRef();
  const RedirectUrl = function(url){
    window.location.href = "/thankyou";
  }
  const sendEmailFooterBottom = (e) => {
    e.preventDefault();
    $("button.btnPrimary"). attr("disabled", true);
    emailjs.sendForm('service_mpjgvt5', 'template_jw2zrfk', form.current, '960vdqz35TukiHxIX')
      .then((result) => {
      
        $("button.btnPrimary"). attr("disabled", false);
        $('.formFields input,.formFields textarea').val('');
        // Swal.fire({
        // icon: "success",
        //  title: "We know the world is full of choices. Thank you for choosing us! ",
        //  text : `Be the author of the next best-selling book! {Constant.CompanyName}, just a click away!`
        // })
        RedirectUrl();
      }, (error) => {
      
        $("button.btnPrimary"). attr("disabled", false);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        })
      });
  };
  return (
    <section className="sec13 contactFormBottom">
    <div className="container">
      <div className="row">
        <div className="col-md-6 contactFormContentLeft">
          <div className="contactContentLeft">
          <div className="abtLeft">
              <h4>
              Begin Your Road To Success 
              </h4>
              <span>Contact us now and consult our experts</span>
              <p>We will be happy to assist you </p>
            </div>
          </div>
          <div className="ctaWrap">
            <div className="aboutCta">
              <a href={Constant.PhoneNumberLink} className="ctaLinePhone">
                <FontAwesomeIcon icon={faPhone} />
                <span className="smallTitleLine">We will be happy to assist you</span>
                {Constant.PhoneNumber}
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 contactFormRight">
          <div className="contactFormFields">
          <form ref={form} onSubmit={sendEmailFooterBottom}>
            <div className="formFields">
              <input type="text" placeholder="Name (required)" name='user_name'  required/>
            </div>
            <div className="formFields">
              <input type="text" placeholder="Email (required)"  name='user_email' required/>
            </div>
            <div className="formFields">
              <input type="text" placeholder="Phone (required)" name='user_phone' value={phoneNumber}
      onKeyPress={handleKeyPress}
      onChange={handleChange} required/>
            </div>
            <div className="formFields">
              <textarea placeholder="Message" name='user_message'></textarea>                 
            </div>
            <div className="formFields FieldButton">
              <button type="submit" className="btnPrimary">
                <span>
                  Submit <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>      
            </div>
            <div className="hidden-fields">
									<input type="hidden" name="fullpageurl" value={url} />
                  <input type="hidden" name="companyinfo" value={Constant.CompanyName} />
								</div>
            </form>
          </div>
        </div>
      </div>
    </div>
</section>
  );
}

export default FooterForm;
