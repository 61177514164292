import React from 'react'
import Assets from '../../assets/images';
function BrandSlider() {
  return (
<section className="sec5 platforms">
<div className="container">
  <div className="row">
    <div className="col-md-12 platformsTitle">
      <span className="heading2 boldheading2">
        Our Publishing Platforms
      </span>
    </div>
    <div className="col-md-12 logoWrapPlatforms">
      <div className="platformsInner proDesktop">
        <img src={Assets.LogoMain} alt="Platform Images" />
      </div>
      <div className="platformsInner proMobile">
        <img src={Assets.LogoMainMobile} alt="Platform Images" />
      </div>
    </div>
  </div>
</div>
</section>
  );
}

export default BrandSlider;
