import React, { useEffect } from "react"; 

import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import Girlquote from "../../components/Girlquote";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Testimonials from "../../components/Testimonials";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";

function Suspense() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  
    
  return (
    <>
      <Helmet>
        <title>Suspense & Thriller Book Writing Services - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner suspenseBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
  E                <h1>
                  We Leave The Readers Captivated With Our Suspense Book Writing Service
                  </h1>
                  <p>
                  Our goal is to impress you by turning your ideas into excellent content that truly absorbs readers. Our mystery writers have a strong desire to write captivating novels, and they are excellent at it thanks to inspiration from the most renowned suspense authors
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Get the Most Professional Mystery Writers in the USA </h4>
                <p>
                Few people have the talent to create intricate mysteries, with relatively sound alibis being exposed, clues placed so deftly in front of the reader's eyes that they go unnoticed, and motives so compelling yet plausible that the killers are more than just a necessary piece of the puzzle but also an integral part of a satisfying and well-rounded narrative.</p>
                <p>Our suspense fiction writers use heightened emotion to keep the readers hooked, as it requires making sure that the protagonist is faced with huge issues and that failing to find solutions to those problems would clearly have the worst consequences. Our thriller writing is one of the most exciting and dynamic genres available to authors. Our goal for the reader is that they never want to put the book down.</p>
                <p>Despite the fact that there is a vast list of mystery authors, our writers get extra credit for being able to produce not just a work of art but also something that the reader can connect with and hold onto.

                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.suspenseBookHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Get attention-grabbing suspense & thriller writing services with Bellevue Publishers!" Text="Connect with us now to get the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Our Creative Thriller Writers <br></br>are on The Top-Notch Game
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.suspensethrillerwriting1}
                        alt="suspensethrillerwriting1"
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Skilled ghostwriters </span>
                      <p>
                      We deliver premium ghostwriting services to our clients. Leave the writing to our expert ghostwriters. With their creativity, they ensure an exceptional manuscript and ensure that the quality never suffers.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.suspensethrillerwriting2}
                        alt="suspensethrillerwriting2"
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Customer service </span>
                      <p>
                      We will work with you to make the necessary changes if you are dissatisfied with the final draft. On the other hand, we are here for you if you require additional time to complete or prefer our expert opinion over specific elements of your fiction novel.
                      </p>
                    </div>
                  </div>

                </div>
                <div className="col-md-12 whychooeIconBox">

                <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.suspensethrillerwriting3} alt="suspensethrillerwriting3"   />
                    </div>
                    <div className="contentChoose">
                      <span>Creating a good story flow</span>
                      <p>
                      Not sure about how your book is coming along? Our writers make sure to keep the reader updated about what is going on in the story. We understand that the readers want to know more than just a hero and through our unique thriller writing skills, we drive the curiosity of the readers throughout the novel.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.suspensethrillerwriting4}
                        alt="suspensethrillerwriting4"
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Abrupt surprises in the thriller </span>
                      <p>
                      Suspense and surprise differ from each other in a little but significant way. You can manipulate your readers' perceptions of time by using suspense. They're aware that information is on the way, but they're not sure when. Our thriller writers make sure that both of them are entertaining and useful.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Get attention-grabbing suspense & thriller writing services with Bellevue Publishers!" Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Have a Question? Ask away </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is a thriller/suspense novel genre?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Setting reader expectations through information control—how much you reveal, when and how you tell it—is key to generating suspense. Reader experiences suspense when they do not know what will come next in a work of literature. A writer builds suspense by revealing information to readers in a manner that raises different questions and fills them with fear and curiosity at the same time.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What would be the next step after creating a story plot?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Many writers let their ideas wander freely and create an amazing story, but they frequently lack the abilities needed to pen a fascinating piece of fiction. The first step will be to draft an outline. Depending on your structure or plot, this outline may be shorter or longer. In our thriller novels, a chapter outline explains what will be covered and how it will be covered. To assist you maintain track of your topic headings throughout each chapter, you might want to utilize a table of contents.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are the specific characteristics of a thriller?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Thrillers focusing on crime and justice frequently feature themes like murder, kidnapping, narcotics, robbery, mistaken identity, etc. Typically, the protagonist is a fighter for justice, such as a police officer, attorney, special agent, or even a superhero.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Suspense Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Suspense;
