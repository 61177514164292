(function() {
    var po = document.createElement('script');
    po.type = 'text/javascript';
    po.async = true;
    po.src = "https://www.googletagmanager.com/gtag/js?id=UA-266814008-1";
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(po, s);
    setTimeout(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){
            window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
    
        gtag('config', 'G-258XLJTDLJ');
    }, 100);    
   
  
})();

