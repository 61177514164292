export const Constant = {
  CompanyName: "Bellevue Publishers",
  CompanyUrl: "bellevuepublishers.com",
  CompanyUrlWithWWW: "www.bellevuepublishers.com",
  PhoneNumber: "(877) 251-5759",
  PhoneNumberLink: "tel:8772515759",
  Email: "info@bellevuepublishers.com",
  EmailLink: "mailto:info@bellevuepublishers.com",
  Address: "157 Church st 19th floor, New haven, CT 06510",
  FacebookLink: "https://www.facebook.com/BellevuePublisher",
  InstagramLink: "https://www.instagram.com/bellevuepublishers/ ",
  LinkedinLink: "https://www.linkedin.com/company/bellevue-publishers/",
  TwitterLink: "https://twitter.com/BellevuePublish",
  TrustPilotLink: "https://www.trustpilot.com/review/nydhub.com",
  WhatsAppLink: "tel:8772515759",
  GoogleReviewsLink: "#",
  REACT_APP_reCAPTCHA_SITE_KEY: '6LeLj3InAAAAAKu0MpTsQtHhDM2SRjY26CLxPDCG',
  REACT_APP_reCAPTCHA_SECRET_KEY: '6LeLj3InAAAAAJDHGPz7h1TknZq77KbI9AzY1yq9',
};
