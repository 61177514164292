import React, { useEffect,useState } from "react"; 

import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import Girlquote from "../../components/Girlquote";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Testimonials from "../../components/Testimonials";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { useRef } from 'react';
import { Helmet } from "react-helmet";
function Scifi() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Science Fiction Writing Services | Hire Science Fiction Writers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner scifiBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Unleash Your Inner Geek with Extraordinary Sci-Fi Writing Services 
                  </h1>
                  <p>
                  Science fiction writing services at Bellevue Publishers are carefully chosen to hone the science realism and fiction writing skills necessary to make your works appear extraordinary. Our science fiction writers are experts in all genres, whether they are writing about the inner workings of the universe or the human brain!
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Enter the Realm of Intriguing Sci-Fi Writing and Leave Your Audience in Awe! </h4>
                <p>
                Sci-fi books can be just as fascinating to read as they are to see on a huge screen! Whether they are writing an intriguing futuristic novel or an interplanetary mystery, our gifted science fiction writers never cease to astound readers. Bellevue Publishers guarantees that your book will undoubtedly stimulate their minds!</p>

                <p>Exceptional science fiction writing services have no bounds and research their work through authentic sources to ensure that no detail is missed to spoil the fun! At the same time, our talented team of science fiction ghostwriters keep the fun alive by engaging the readers with mysterious and suspense-filled scenes! 
                </p>
                <p>We offer an array of services that are designed to help you become the next best-selling author, {Constant.CompanyName} make no compromises on providing professional science fiction services for all the geeks of science fiction genre! </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.SciFi} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Can’t find a good sci-fi writer? Explore the themes of sci-fi writing with us!  " Text="Connect with us now to get the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Our Science Fiction Writing Services <br></br>Outshine Other Ghostwriting Services 
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.sciencefictionwriting1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Top-Notch Sci-Fi Writers in USA</span>
                      <p>
                      {Constant.CompanyName} is a hub of expert writers who specialize in specific genres as per our clients’ requirements. We only provide our clients with a team which has mastered and garnered experience from writing multiple books that are best-sellers! We hire academically sound and advanced writers who are familiar with cutting-edge technology and trends! 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.sciencefictionwriting2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>It's All in The Details </span>
                      <p>
                      We understand the importance of details in creating a science fiction book, our science fiction writing services are carefully designed to scrutinize and eliminate any elements of loopholes, scattered flow and out of context details. Through proofreading and QA, we do not take any chances of delivering a manuscript that is ruined by major faults like grammar errors or formatting issues. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.sciencefictionwriting3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>On-Time Deliveries </span>
                      <p>
                      {Constant.CompanyName} know the importance and value of your time, we make no hesitations in communicating and designing a time-frame that gives our writers ample amount of time to craft your manuscript without regretting delays. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.sciencefictionwriting4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Give Readers Something to Remember </span>
                      <p>
                      Through our collaboration, we can guarantee that your ideas do not end up being anti-climactic and plan accordingly to craft, polish and design a manuscript that is nothing short of a masterpiece. 
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Can’t find a good sci-fi writer? Explore the themes of sci-fi writing with us! " Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Got a Question? Let's Address Your Queries </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is science fiction writing services?  
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Sci-Fi is specified for people who want to explore this genre, our science fiction writing services cater to audiences who are fans of science fiction. Sci-fi is a harmony of nature and human connection to push the envelope of how we as humans think about life.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Are sci-fi books hard to read?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>If written correctly and by professionals a sci-fi book can be just as interesting as any other genre such as action or romance. Sci-Fi is increasingly becoming the most read and followed genre because of the fans who love to explore and read innovative stories.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much does a science fiction writing service cost?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on the book's length, any book service can cost anywhere between $20 to $1000 or more. {Constant.CompanyName} have an affordable range where we make sure to provide flexibility on our rates with no compromise on quality.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Does Bellevue Publishers provide self-publishing services for books?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, {Constant.CompanyName} is a full-fledged ghost book writing service, so we offer self-publishing services to market and promote your books globally. We promote your books through social media marketing and multi-channel platforms to give you a global audience.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Sci-fi Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Scifi;
