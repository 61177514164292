import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import "./styles.css";
import Assets from "../../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import { Navigation, Scrollbar, A11y, Autoplay, EffectFade, Pagination } from "swiper";
import $ from "jquery";

import fb from '../../firebaseconfig'
const db = fb.firestore()
const BlogsData = db.collection("blogs")
.orderBy("modifieddate", "desc");

function Blogs() {
  const [blogslist, setblogs] = useState([]);

  const DeleteBlog = (id)=> {
    BlogsData.doc(id).delete().then(() => {
          alert("Document successfully deleted!");
      }).catch((error) => {
          console.error("Error removing document: ", error);
      });
  };
  useEffect(() => {
    // Subscribe to query with onSnapshot
    const unsubscribe = BlogsData.limit(10).onSnapshot(querySnapshot => {
      // Get all documents from collection - with IDs
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      
      // Update state
      setblogs(data);
    });

    // Detach listener
    return unsubscribe;
  }, []);

  return (
    <section className="sec12 BlogMain">
    <div className="container">
      <div className="row">
        <div className="col-md-4 whyChooseLeft">
          <div className="abtLeft">
            <span className="smallTitleLine">OUR BLOG</span>
            <h4>
            Stories & Latest News
            </h4>
            <p>Dive into the World of Creativity</p>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
      <div className="col-md-12 blogSlider">
        <Swiper
          slidesPerView={3}
          spaceBetween={10}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          centeredSlides={false}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation
          modules={[Autoplay,Navigation]}
          className="mySwiper"
        >

{blogslist.map(blog=> (
          <SwiperSlide>
          <div className="blogMainBox">
            <div className="blogImage">
            <Link to={"/blog/"+blog.Slug}><img src={blog.Image} alt="Book Image" /></Link>
          </div>
          <div className="blogContent">
            <span className="cateReadTime"><span><b>{blog.Category}</b></span><span className="blogDate"></span></span>
            <span className="blogTitle"><Link to={"/blog/"+blog.Slug}
        className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 border border-blue-500 rounded"
        >{blog.Title}</Link></span>
            <p>{blog.body}</p>
          </div>
            </div>
          </SwiperSlide>
            ))}
        </Swiper>

        </div>
      </div>
    </div>
  </section>
  );
}

export default Blogs;
