import React, { useEffect } from "react"; 
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLongArrowAltRight,
  faStar,
  faComment,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import { Link, useLocation,useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Girlquote from "../../components/Girlquote";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function BusinessBook() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  const navigate = useNavigate();

//navigate(0)
     
  return (
    <>
      <Helmet>
        <title>Business Book Writing Services - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner businessBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Unlock Your Business Potential with Expert Book Writing Services.
                  </h1>
                  <p>Engage your audience, establish thought leadership, and drive growth with our tailored business books. Contact us today for high-quality content that captures your unique vision and resonates with your target market. Let your business story inspire success.</p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
        <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Claim Your Success With Professional Business Book Writing Services</h4>
                <p>Business books are written publications that provide valuable insights, strategies, and knowledge to help entrepreneurs, professionals, and businesses thrive in their respective industries. These books cover a wide range of topics, including leadership, management, marketing, finance, and entrepreneurship. Business book writing services involve the expertise of professional writers who specialize in creating compelling and informative content specifically tailored for the business book genre. These services help entrepreneurs and business professionals transform their ideas, experiences, and expertise into well-crafted books that engage readers, establish credibility, and enhance their brand reputation.</p>

                <p>According to a survey, 82% of business professionals believe that authoring a book positively impacts their career. Additionally, companies that utilize business book writing services see a boost in lead generation, credibility, and thought leadership, leading to higher conversions and business growth. Therefore, Bellevue has a resource pool of experts who are specialized in working on the genre. From conceptualization to research, writing, and editing, Bellevue business book writing services ensure the delivery of high-quality, insightful, and marketable books that resonate with the target audience, ultimately helping authors achieve their publishing and business goals.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.businessHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Embark on your corporate journey with our specialized business book services. " Text="Connect with us now to avail the finest deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why choose Bellevue Business Book Writing Services?
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.businessbookwriting1} loop={true} />
                    </div>
                    <div className="contentChoose">
                      <span>Expert corporate book writing services</span>
                      <p>
                      Our skilled ghostwriters can tackle any business topic, from starting a business to financial guidance. Just share your plot ideas, and we'll handle the rest.</p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.businessbookwriting2}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Authentic and professional approach</span>
                      <p>
                      We simplify the complexities of business book writing, ensuring a seamless experience for you. Our talented writers bring authenticity and professionalism to every project.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.businessbookwriting3}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                    <span>Specialized real estate ghostwriters</span>
                    <p>
                    Our team includes real estate experts who excel at writing about intricate subjects. Trust us to create best-selling business books that captivate readers worldwide.</p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.businessbookwriting4}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Eye-catching book cover designs</span>
                      <p>
                      At Bellevue Publishers, we believe in visually striking book covers that pique readers' curiosity. Our creative designs and color schemes ensure your book stands out.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <section className="sec12 TestiSliderMain">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 whyChooseLeft">
          <div className="abtLeft">
            <span className="smallTitleLine">Testimonials</span>
            <h4>
            What Do Our Clients say About Us?
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-12 blogSlider">
        <Swiper
          slidesPerView={3}
          spaceBetween={120}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          centeredSlides={false}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={false}
          breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">JA </span>
            <div className="nameStars">
              <span className="clientNameTesti">John Anderson</span>
              <div className="ratingStar">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Book Publishersexceeded my expectations with their business book writing services. Their team of professionals understood my vision and transformed it into a compelling book that resonated with my audience.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">ST</span>
            <div className="nameStars">
              <span className="clientNameTesti">Sarah Thompson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I highly recommend Bellevue Publishers for business book ghostwriting. Their expertise in the industry and attention to detail resulted in a well-researched and engaging book that has received rave reviews.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">MJ</span>
            <div className="nameStars">
              <span className="clientNameTesti">Mark Johnson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Working with Bellevue Publishers was a game-changer for my business. Their business book writing services helped me establish credibility and position myself as an industry expert. The quality of their work is unmatched.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">ER</span>
            <div className="nameStars">
              <span className="clientNameTesti">Emily Roberts</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I couldn't be happier with the business book written by Bellevue Publishers. Their team captured my ideas perfectly and brought them to life. The book has been instrumental in attracting new clients and growing my business.</p>
          </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">MT</span>
            <div className="nameStars">
              <span className="clientNameTesti">Michael Turner</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers truly understands the art of storytelling. Their business book ghostwriting services transformed my ideas into a captivating narrative that has resonated with readers and helped me establish my brand.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">LD</span>
            <div className="nameStars">
              <span className="clientNameTesti">Laura Davis</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I was impressed by the professionalism and dedication of Bellevue Publishers. They took the time to understand my goals and delivered a business book that exceeded my expectations. I highly recommend their services to anyone looking to elevate their brand.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">RP</span>
            <div className="nameStars">
              <span className="clientNameTesti">Robert Peterson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers brought my business book to life with their exceptional writing skills and industry knowledge. Their attention to detail and commitment to delivering a high-quality product is unparalleled.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">JM</span>
            <div className="nameStars">
              <span className="clientNameTesti">Jennifer Miller</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Choosing Bellevue Publishers for my business book was the best decision I made. Their team of talented writers created a book that not only showcased my expertise but also resonated with readers. I am grateful for their expertise and professionalism.</p>
          </div>
            </div>
          </SwiperSlide>
          
        </Swiper>

        </div>
      </div>
    </div>
  </section>
      <Blogs/>
      <Ctaorange Title="Explore The Depths Of Corporate Knowledge With Our Specialized Business Book Services." Text="Take the first step towards success by connecting with us today!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">FAQs</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What types of business books can you write?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our experienced ghostwriters can write various types of business books, including guides for starting a business, industry-specific insights, leadership and management books, financial strategies, and more. Just let us know your specific requirements, and we'll tailor our writing services accordingly.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does it take to complete a business book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>The time required to complete a business book depends on various factors, such as the length and complexity of the book, research requirements, and your specific needs. We strive to deliver high-quality work within mutually agreed-upon timelines, ensuring that you receive your business book in a timely manner.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can you help with the book cover design for my business book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, absolutely! We understand the importance of a visually appealing book cover. Our team includes talented designers who can create professional and eye-catching book covers that align with the genre and theme of your business book, enhancing its overall presentation and marketability.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I retain the rights to my business book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, you retain the rights to your business book. Once the writing and editing process is complete, the copyright and ownership of the book belong to you. We respect your intellectual property and ensure that you have complete control over your work. </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl  pageTitle = "Embark on the path to success."/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default BusinessBook;
