import React, { useEffect,useState } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Girlquote from "../../components/Girlquote";
import Testimonials from "../../components/Testimonials";
import Blogs from "../../components/Blogs";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function Fantasy() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  
  return (
    <>
      <Helmet>
        <title>Fantasy Writing Services | Fantasy Writers for Hire - Bellevue</title>
        </Helmet>
      <Header/>
      <section className="sec1 innerBanner fantasyBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Emerge Your Audience in Our World of Captivating Fantasy Writing Services
                  </h1>
                  <p>
                  We have an excellent range of wizards of words that can fill your books with magic and immerse readers in a whimsical creation of fantasies. Hire our universal writers to take you on a journey filled with excitement and thrills through our fantasy writing services. 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Hire Our Leading Fantasy Book Writers and Build Extraordinary Tales!</h4>
                <p>
                Building our own universe is not easy, especially when it has multiple characters, universes, and places and when everything has to be made within your sense of imagination. Fantasy is a genre known and liked by many. However, writing a fantasy book that grabs the reader’s attention and makes them feel connected to characters that don’t even exist in real life is a task mastered by our fantasy book writers. </p>

                <p>Our fantasy writing services revolve around a multi-layered process where we brainstorm with our clients to ensure that we jot down the unique aspects of any book and polish it to professional standards. The main objective of our authors is to create an immersive timeline of whatever era, either Victorian or futuristic, and write with such scrutiny that no plot holes exist. We at {Constant.CompanyName} ensure that your imagination is put into words in the best way possible; in the fantasy world, we make all your dreams come. The continuous process of tapping into our client’s imagination gives us the blueprint for the content and enriches the experience of writing. We infuse your unique ideas into stories that unleash various possibilities of you becoming the next best-seller! 
                </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.fantasyBook} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Have a plot in your mind but
need words to refine your story? " Text="We are here to help you with our Expert Writers!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why is {Constant.CompanyName} Best <br></br>for you? Let us Explain
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.fantasywriting1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Market Your Fantasy Books All Over the Globe</span>
                      <p>
                      Have an idea? Why just keep it to yourself? Hire us, and let us ensure the publicity and marketing of your book. Fantasy book writing is a favorite niche for many readers, and new unique stories always rile up fantasy fanatics! 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.fantasywriting2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Excite your Readers with Immersive Plots </span>
                      <p>
                      Feature captivating cliffhangers, unexpected finales, and interesting fictitious characters to immerse your readers in a storyline that tops all charts! Go for a descriptive novel or build your universe with a series; we have got you covered in all niches and genres. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.fantasywriting3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Personalized Assistance  </span>
                      <p>
                      With {Constant.CompanyName}, you can trust us for continuous support throughout your journey, from writing the first draft to completing the book; we take you on a step-by-step journey through the whole process. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.fantasywriting4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Complete Encryption</span>
                      <p>
                      Gaining our clients' trust is the first and most important aim for every ghostwriting company. Being one of the top ghostwriter services providers in the USA, Bellevue Publishers abides by stringent policies to protect your name and personal information, unless you specifically request that we share it. 
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/> 
      <Ctaorange Title="Have a plot in your mind 
but need words to refine 
your story? " Text="We are here to help you with our 
Expert Writers!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Have a creative block? Let us fuel your imaginative engines</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is fantasy writing difficult? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Fantasy writing is a genre not many have the expertise to write. It’s one thing to have a unique idea and another to build it. In fantasy writing, many times, writers have to dive deep into an imaginative world that is not impractical and does not feel peculiar for readers to imagine. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How can I hire the best fantasy writers for my book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Choosing a fantasy writer is not as simple as it sounds. You need to know that the writer has a creative imagination and has some experience in writing engaging books. When you hire an expert service provider like {Constant.CompanyName}, we ensure you match with the best lot of fantasy writers to work with you. 

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What can make my fantasy story special? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Because this is a saturated genre, you might need a little extra something for your novel. Of course, we can help you with it, but a unique aspect or quirk is necessary to build the interest of readers and help them pick your book to read instead of others. 

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Fantasy Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Fantasy;
