import React from "react";
import "./styles.css";
import Assets from "../../assets/images";
function Clientreviewlinks() {
  return (
    <div className="reviewManinHeader">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div className="reviewLogos">
                    <ul>
                        <li> <a href="#"><img src={Assets.rev1} alt="Review Images" /></a></li>
                        <li> <a href="#"><img src={Assets.rev2} alt="Review Images" /></a></li>
                        <li> <a href="#"><img src={Assets.rev3} alt="Review Images" /></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
  );
}

export default Clientreviewlinks;
