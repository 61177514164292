import React, { useRef,useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Assets from "../../assets/images";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import $ from "jquery";
import { Constant } from "../../Constants";

function LandingPageBannerForm() {
    const [phoneNumber, setPhoneNumber] = useState('');
  
    function handleKeyPress(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    }
  
    function handleChange(event) {
      setPhoneNumber(event.target.value);
    }
  const location = useLocation();
  const url = window.location.href;
  const currentUrl = location.pathname;
const form = useRef();
const RedirectUrl = function(url){
  window.location.href = "/thankyou";
}
const sendEmail = (e) => {
  e.preventDefault();
  $("button.subbtn"). attr("disabled", true);
  emailjs.sendForm('service_mpjgvt5', 'template_jw2zrfk', form.current, '960vdqz35TukiHxIX')
    .then((result) => {
     
      $("button.subbtn"). attr("disabled", false);
      $('.fields_wrap input,.fields_wrap textarea').val('');
      RedirectUrl()
      // Swal.fire({
      //  icon: "success",
      //  title: "We know the world is full of choices. Thank you for choosing us! ",
      //  text : `Be the author of the next best-selling book! {Constant.CompanyName}, just a click away!`
      // })
    }, (error) => {
     
      $("button.subbtn"). attr("disabled", false);
      Swal.fire({
        icon: "error",
        title: "Ooops, something went wrong",
        text: error.text,
      })
    });
};
  return (
    <>
         <form ref={form} onSubmit={sendEmail}>
         <h2>Limited Time <br />OFFER <span className="global_color">50% OFF</span> </h2>
         <div className="fields_wrap">
              <input type="text" placeholder="Enter Your Name" name='user_name' required/>
            
      
              <input type="email" placeholder="Enter Your Email" name='user_email' required/>
            
          
              <input type="text" placeholder="Your Phone Number" name='user_phone' value={phoneNumber}
                onKeyPress={handleKeyPress}
                onChange={handleChange} required/>
          
              <input className="subbtn" type="submit" name="" value="Submit" />
              {/* <button type="submit" className="subbtn">
                <span>
                  Submit <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>       */}
          </div>
            <div className="hidden-fields">
									<input type="hidden" name="fullpageurl" value={url} />
                  <input type="hidden" name="companyinfo" value={Constant.CompanyName} />
								</div>
             </form>
    </>
  );
}

export default LandingPageBannerForm;
