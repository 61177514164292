import React, { useEffect,useState } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import Girlquote from "../../components/Girlquote";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Testimonials from "../../components/Testimonials";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

function Drama() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Drama Writing Services| Drama Writing for Hire - Bellevue</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner dramaBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Create History With Our Fascinating Drama Writing Services!
                  </h1>
                  <p>
                  {Constant.CompanyName} help you create exceptional dramas through our top-tier ghostwriting services. We help you create masterpieces that can be turned into plays and movies; get our drama writing services and start your journey with us! 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Share your engaging stories through our drama writing services.</h4>
                <p>
                The method of fictional representation through dialogue and performance is called drama. It is one of the genres of literature that is an imitation of some action. The creative drama writers that we have on board are capable of composing amazing classics for you because you are only a thought away from being an outstanding author. Many authors need a single push to their story, which our drama writers can efficiently provide. We also provide audiobook services to the clients as some readers might find it time-consuming to read the book. This also helps readers to easily picture the thoughts and characters in your story.</p>

                <p>Writing for the drama genre can be challenging, and people feel lost when writing a story. Sometimes authors go through a creative block and get stuck between writing their story. Sometimes it's just you don’t know where to begin and where to conclude. When all feel lost, we come to rescue you and your story writing.
                </p>
                <p>Our drama writers are capable of transforming your small idea into a mind-blowing story. Through the help of our drama writing services, you can artistically present dialogues making the story look impactful and more engaging to the readers. The writers love to shape characters along with a natural and credible aspect. The speciality of our drama writers is that they can comprehend the story's characters, plot, setting as well as symbolism, as this makes the story more appealing.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.dramaBookHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Looking to feature intense drama writing in your books? " Text="Connect with us now to get the best deals!"/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Choose Our Drama Writing Services? 
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.dramawriting1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Captivating Writing Style</span>
                      <p>
                      Through the accurate description of characters, places, circumstances, and time our drama writers are capable of delivering a captivating story that will make the readers hooked till the end. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.dramawriting2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Your Ideas; Our Words </span>
                      <p>
                     With our writing experts, you can easily depict your idea in the story. Our ghostwriters create an initial draft per your feedback, and through clear communication. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.dramawriting3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Timely Deliveries </span>
                      <p>
                      Following up on your project has never been so easy. With our prompt communication, we can ultimately work on your project to deliver it on time. Every chapter is revised and edited as per your feedback! 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.dramawriting4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Cost-free Revisions </span>
                      <p>
                      We acknowledge that the content cannot be perfect every time hence to make it excellent, we offer cost-free revisions to our clients. Our writers are always available for unlimited revisions with zero cost. 
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Looking to feature intense drama writing in your books? " Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Frequently Asked Questions</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the drama genre of a novel? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Drama is a type of play that combines aspects of both comedy and tragedy but is not exactly either one. It might even have music, like in an opera. Contrarily, a novel is a story that is meant to be read rather than acted. 
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How is the feedback process carried out? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>For every project to be successful, timely feedback-giving and feedback-receiving are important. The process of ghostwriting is managed according to the same project management rule. Once we are done completing the chapter according to the provided timeline, we share the files with the clients for review so that the client knows how the book is coming together. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is the target date for completing the project?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    As an author, you have a different view about what information the reader wants to be answered. The desired target is set as per the customer's demand depending upon the length and idea of the book. Our experts provide the deadline based on the word count and pages of the book. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is it legal to hire a ghostwriter? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Ghostwriters are absolutely legal. They are experts whose time and knowledge you can hire. Due to the fact that they are not seeking author acknowledgment, it is legal. Furthermore, the relationship is entirely legal for all purposes because the author is the source of the original concept and controls what gets included in the book and what doesn't.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Drama Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Drama;
