
import React, { useEffect } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faChevronDown,
  faPhone,
  faLongArrowRight,
  faStar,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import Girlquote from "../../components/Girlquote";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Clientreviewlinks from "../../components/Clientreviewlinks";
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function EBookWriting() {
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);

     
  return (
    <>
      <Helmet>
        <title>eBook Writing Services | eBook Ghostwriters for Hire</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner ebookBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Elevate Your E-book With Expert e-Book Writers Near You.
                  </h1>
                  <p>
                  Amplify Your E-book’s Impact: Our expert writers enhance your content with captivating storytelling, in-depth research, and compelling insights.</p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Take Your Content to the New Heights With Bellevue e-Book Writing Services.</h4>
                <p>In the digital era, e-books have emerged as a potent tool for expanding the reach of content to a wider audience. The accessibility and convenience of e-books have revolutionized the publishing landscape, enabling authors and businesses to connect with readers worldwide. According to recent statistics, e-book sales have witnessed a significant surge, with a projected global revenue of $23.9 billion by the end of 2023. This rise can be attributed to the increasing adoption of e-readers, tablets, and smartphones. E-books offer unparalleled advantages for content distribution. They transcend geographical boundaries, allowing authors to tap into global markets effortlessly. Moreover, e-books are easily shareable, empowering readers to recommend and disseminate content to their networks.</p>

                <p>Hiring skilled e-book writers brings numerous benefits to writers and their content. These experts possess the expertise to craft compelling narratives, conduct thorough research, and ensure the highest quality. By collaborating with e-book writers, writers can save time and focus on their strengths while entrusting the content creation process to experienced professionals. Bellevue stands out as the premier choice for acquiring e-book writing services. With a team of talented writers well-versed in various genres and topics, Bellevue offers a seamless experience from start to finish. Their commitment to excellence, attention to detail, and dedication to client satisfaction make them the ideal partner to bring your vision to life. Choose Bellevue for exceptional e-book writing services that elevate your content and captivate your readers.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.ebookHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Discover the Finest E-book Writers to Craft Your Story." Text="Connect with us today for exceptional deals and services."/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Choose Bellevue E-Book Writing Services for Your Content</span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.ebookwriting1} />
                    </div>
                    <div className="contentChoose">
                      <span>Expert Writers</span>
                      <p>
                      Our experienced team is fueled by a passion for storytelling and possesses a deep understanding of crafting outstanding e-books. Whether you seek an informative guide or a captivating novel, our writers possess the skills and expertise to deliver the content you desire.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.ebookwriting2}
                       
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Customized Approach </span>
                      <p>
                      At “Bellevue Book Publishers,” we recognize the uniqueness of every e-book. We collaborate closely with you to comprehend your goals, target audience, and brand voice, ensuring content that is tailor-made to your specific requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.ebookwriting3}
                       
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Affordable Rates </span>
                      <p>
                      Crafting a high-quality e-book need not drain your budget. “Bellevue Book Publishers” offers competitive rates, making top-notch content accessible to businesses of all sizes. We believe every brand deserves access to exceptional content, regardless of financial constraints.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.ebookwriting4}
                       
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Fast Turnaround</span>
                      <p>
                      We value your time as much as you do. With a strong commitment to timeliness, we prioritize efficient delivery without compromising on quality. Count on us to meet your deadlines and provide content that you’ll proudly share with your audience.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <section className="sec12 TestiSliderMain">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 whyChooseLeft">
          <div className="abtLeft">
            <span className="smallTitleLine">Testimonials</span>
            <h4>
            What Do Our Clients say About Us?
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid">
      <div className="row">
      <div className="col-md-12 blogSlider">
        <Swiper
          slidesPerView={3}
          spaceBetween={120}
          autoplay={{
            delay: 6000,
            disableOnInteraction: false,
          }}
          centeredSlides={false}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={false}
          breakpoints={{
                320: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 3,
                },
              }}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">ES </span>
            <div className="nameStars">
              <span className="clientNameTesti">Emily Stone</span>
              <div className="ratingStar">
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>1.	I was blown away by the expertise of Bellevue Publishers. Their team crafted an e-book that exceeded my expectations. The captivating storytelling and insightful content truly engaged my readers. I highly recommend their services.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">JA</span>
            <div className="nameStars">
              <span className="clientNameTesti">Jonathan Adams</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers turned my ideas into a masterpiece. Their customized approach and attention to detail brought my e-book to life. Working with them was a breeze, and the end result was a polished and professional piece. Thank you!</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">OT</span>
            <div className="nameStars">
              <span className="clientNameTesti">Olivia Thompson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I am amazed by the affordability of Bellevue Publishers. I thought hiring professionals would cost a fortune, but their competitive rates fit perfectly within my budget. They delivered exceptional content without compromising on quality.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">AJ</span>
            <div className="nameStars">
              <span className="clientNameTesti">Alex Johnson</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers worked wonders for my business. Their fast turnaround allowed me to meet my deadlines, and the content they provided was top-notch. I’m proud to share their e-book with my audience. They truly understand the value of time and quality.</p>
          </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">SR</span>
            <div className="nameStars">
              <span className="clientNameTesti">Samantha Roberts</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I can’t thank Bellevue Publishers enough for their exceptional work. Their team of expert writers brought a unique perspective to my e-book, making it stand out from the crowd. The personalized approach and dedication to my vision made the collaboration a true success.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">LD</span>
            <div className="nameStars">
              <span className="clientNameTesti">Laura Davis</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I was hesitant about hiring an e-book writer, but Bellevue Publishers proved to be the best choice. Their team’s expertise and attention to detail ensured that my content was informative and entertaining. The results surpassed my expectations.</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">ST</span>
            <div className="nameStars">
              <span className="clientNameTesti">Sophia Turner</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>Bellevue Publishers transformed my ideas into an engaging e-book. Their professionalism and commitment to excellence were evident throughout the process. The content they created captured the essence of my brand and resonated with my target audience. Highly recommended!</p>
          </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
          <div className="testiMainBox">
            <div className="testinameImage">
            <span className="nameIco">BC</span>
            <div className="nameStars">
              <span className="clientNameTesti">Benjamin Collins</span>
              <div className="ratingStar">
                                                   <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                        <FontAwesomeIcon icon={faStar} />
                      </div>
            </div>
          </div>
          <div className="blogContent">
            <p>I am grateful for Bellevue Book Publishers’ exceptional service. They took the time to understand my goals and crafted an e-book that perfectly aligned with my vision. The quality of their work and their dedication to customer satisfaction are unparalleled.</p>
          </div>
            </div>
          </SwiperSlide>
          
        </Swiper>

        </div>
      </div>
    </div>
  </section>
      <Blogs/>
      <Ctaorange Title="Searching for Top-Notch E-book Writers to Bring Your Story to Life?" Text="Contact us today and unlock the best deals in the market!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">FAQs</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does it take to complete an e-book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The timeline for completing an e-book depends on various factors, such as the length, complexity, and research involved. However, on average, our team at Bellevue Publishers strives to deliver the final e-book within 4-6 weeks.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I provide my own ideas and outline for the e-book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Absolutely! We encourage collaboration and value your input. You can share your ideas, outline, and any specific requirements you have for the e-book. Our expert writers will work closely with you to incorporate your vision and create a customized e-book that reflects your ideas.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Will I own the rights to the e-book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, once the project is completed and payment is made in full, the rights to the e-book will be transferred to you. You will have full ownership and can use the e-book as you deem fit, whether it’s for marketing purposes, sales, or distribution.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I request revisions to the e-book?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Absolutely! We strive to ensure your complete satisfaction. After delivering the initial draft, you can review the content and request any revisions or adjustments. We offer a certain number of revisions to ensure the e-book meets your expectations and requirements. Your satisfaction is our priority.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl  pageTitle = "EBook Writing Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default EBookWriting;
