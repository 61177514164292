import logo from './logo.webp';
import logowhite from './logo-white.png';
import BannerHome from './bannerBgHome.webp';
import aboutcup from './cup.webp';
//import serviceIcon from './serviceIcon1.webp';
import serviceIcon from './book1.webp';
import LogoMain from './logoMain.webp';
import LogoMainMobile from './logoMainMobile.webp';
import bookSlider from './book1.webp';
//import processImage from './processImage.webp';
import processImage from './book1.webp';
import port1 from './port1.webp';
import port2 from './port2.webp';
import port3 from './port3.webp';
import port4 from './port4.webp';
import port5 from './port5.webp';
import portArrow from './portArrow.webp';
import philLeft from './leftImageBook.webp';
import trustpilot from './trustpilot.webp';
import google from './google.webp';
import whychooseicon1 from './whychooseIcon1.webp';
import test1 from './test1.webp';
import test2 from './test2.webp';
import test3 from './test3.webp';
import testarrow from './testarrowRight.webp';
import trustColor from './trustColor.webp';
import blog1 from './blog1.webp';
import blog2 from './blog2.webp';
import blog3 from './blog3.webp';
import dmca from './dmca.webp';
import ghosBanner from './ghostBanner.webp';
import rev1 from './rev1.webp';
import rev2 from './rev2.webp';
import rev3 from './rev3.webp';
import nameicon from './nameicon.svg';
import emailicon from './emailicon.svg';
import phoneicon from './phoneicon.svg';
import handBook from './handBook.webp';
import connectimage from './connectimage.webp';
import proImage from './proImage.webp';
import proImg from './landingPageImages/ebooklp/proImage.svg';
import proImgRes from './landingPageImages/ebooklp/proImage-res.svg';
import faqimageGirl from './faqImage.webp';
import fictionimg1 from './fictionimg1.webp';
//import fictionimg2 from './fictionimg2.webp';
import fictionimg2 from './fictionimg3.webp';
import fictionimg3 from './fictionimg3.webp';
import fictionimg4 from './fictionimg4.webp';
import fictionimg5 from './fictionimg5.webp';
import fictionimg6 from './fictionimg6.webp';
import fictionimg7 from './fictionimg7.webp';
import fictionimg8 from './fictionimg8.webp';
import fictionimg9 from './fictionimg9.webp';
//import fictionimg10 from './fictionimg10.webp';
import fictionimg10 from './suspenseBookHand.webp';
//import dramaBookHand from './dramaBookHand.webp';
import dramaBookHand from './dramaBookHand.webp';
//import childernBookHand from './childernBookHand.webp';
import childernBookHand from './suspenseBookHand.webp';
//import crimeBookHand from './crimeBookHand.webp';
import crimeBookHand from './crimeBookHand.webp';
import suspenseBookHand from './suspenseBookHand.webp';
import actionBookHand from './actionBookHand.webp';
import Adventure from './Adventure.webp';
import Romance from './Romance.webp';
import SciFi from './Sci-Fi.webp';
import Horror from './Horror.webp';
import nonfictionimg1 from './nonfictionimg1.webp';
import nonfictionimg2 from './nonfictionimg2.webp';
import nonfictionimg3 from './nonfictionimg3.webp';
import nonfictionimg4 from './nonfictionimg4.webp';
import authorHand from './author.webp';
import ebookHand from './ebook.webp';
import memorieHand from './memorie.webp';
import biobanner from './biobanner.webp';
import bioHand from './bio.webp';
import businessHand from './business.webp';
import audiobookHand from './audiobook.webp';
import bookmarketingImage from './bookmarketingImage.webp';
//import bookmarketingmobilescreen from './bookmarketingmobilescreen.webp';
import bookmarketingmobilescreen from './bookmarketingImage.webp';
import bookmarketingImage2 from './bookmarketingImage2.webp';
import bookformattingHand from './bookformattingHand.webp';
//import girlBgCircle from './girlBgCircle.webp';
import girlBgCircle from './aboutSec1.webp';
import aboutSec1 from './aboutSec1.webp';
import aboutSec2 from './aboutSec2.webp';
import menuIcon from './menuIcon.svg';
import fantasyBook from './memorie.webp';
import popupClose from './popupClose.svg';
//import closeMenu from './closeMenu.svg';
import closeMenu from './popupClose.svg';
import ctaFormfixed from './popupClose.svg';
import ctaChatfixed from './popupClose.svg';
import ctaCallfixed from './popupClose.svg';
//import ctaFormfixed from './ctaFormfixed.png';
//import ctaChatfixed from './ctaChatfixed.png';
//import ctaCallfixed from './ctaCallfixed.png';
import whatsapp from './whatsapp.png';
// landingPage images Ebook
import lpserviceicon1 from './landingPageImages/ebooklp/serviceIcon.svg';
import lpserviceicon2 from './landingPageImages/ebooklp/serviceIcon2.svg';
import lpserviceicon3 from './landingPageImages/ebooklp/serviceIcon3.svg';
import lpserviceicon4 from './landingPageImages/ebooklp/serviceIcon4.svg';
import lpserviceicon5 from './landingPageImages/ebooklp/serviceIcon5.svg';
import lpserviceicon6 from './landingPageImages/ebooklp/serviceIcon6.svg';

import genresaction from './landingPageImages/ebooklp/Genres/action.svg';
import genresadventure from './landingPageImages/ebooklp/Genres/adventure.svg';
import genresdrama from './landingPageImages/ebooklp/Genres/drama.svg';
import genreshorror from './landingPageImages/ebooklp/Genres/horror.svg';
import genresnonfantacy from './landingPageImages/ebooklp/Genres/fantacy.svg';
import genresnonfiction from './landingPageImages/ebooklp/Genres/nonfiction.svg';
import genresromance from './landingPageImages/ebooklp/Genres/romance.svg';
import genresscifi from './landingPageImages/ebooklp/Genres/scifi.svg';
import genressuspense from './landingPageImages/ebooklp/Genres/suspense.svg';
import trustLogo from './landingPageImages/ebooklp/trustLogo.svg';
import testBook1 from './landingPageImages/ebooklp/testBook/Book1.webp';
import testBook2 from './landingPageImages/ebooklp/testBook/Book2.webp';
import testBook3 from './landingPageImages/ebooklp/testBook/Book3.webp';
import testBook4 from './landingPageImages/ebooklp/testBook/Book4.webp';
import testBook5 from './landingPageImages/ebooklp/testBook/Book5.webp';
import genresactionBook from './landingPageImages/ebooklp/GenresBook/action.webp';
import genresAdventureBook from './landingPageImages/ebooklp/GenresBook/Adventure.webp';
import genresDramaBook from './landingPageImages/ebooklp/GenresBook/Drama.webp';
import genresFantasyBook from './landingPageImages/ebooklp/GenresBook/Fantasy.webp';
import genresaHorrorBook from './landingPageImages/ebooklp/GenresBook/Horror.webp';
import genresNonFictionBook from './landingPageImages/ebooklp/GenresBook/NonFiction.webp';
import genresRomanceBook from './landingPageImages/ebooklp/GenresBook/Romance.webp';
import genresSciFiBook from './landingPageImages/ebooklp/GenresBook/SciFi.webp';
import genresSuspenseBook from './landingPageImages/ebooklp/GenresBook/Suspense.webp';
import notfound from './404.svg';
//import easterimage from './easterimage.webp';
import easterimage from './landingPageImages/ebooklp/Genres/drama.svg';
//import cards from './cards.png';
import cards from './landingPageImages/ebooklp/Genres/drama.svg';
import popupimage from './popupimage.webp';
import bookprinting from './book-printing-bg.webp';
//import bookprintingBook from './bookprintingBook.webp';
import bookprintingBook from './landingPageImages/ebooklp/Genres/drama.svg';
import printingIcon1 from './printingIcon1.svg';
import printingIcon2 from './printingIcon2.svg';
import printingIcon3 from './printingIcon3.svg';
import printingIcon4 from './printingIcon4.svg';
import printingIcon5 from './printingIcon5.svg';
import printingIcon6 from './printingIcon6.svg';
//portfolio
//import newportfolio1 from './portfolio/worldwide-racism-pandemic-project.webp';
import newportfolio1 from './landingPageImages/ebooklp/Genres/drama.svg';
import newportfolio2 from './portfolio/a-boys-dream.webp';
import newportfolio3 from './portfolio/book-portfolio.webp';
import newportfolio4 from './portfolio/delivered.webp';
import newportfolio5 from './portfolio/died-ten-times-yet-lived.webp';
import newportfolio6 from './portfolio/fae-born-Inure.webp';
import newportfolio7 from './portfolio/nikitas-federation.webp';
import newportfolio8 from './portfolio/the-absolutely.webp';
import newportfolio9 from './portfolio/the-artfully-lived-life.webp';
import newportfolio10 from './portfolio/the-asylum-frineds.webp';
import newportfolio11 from './portfolio/the-triumph-of-the-human-spirit.webp';
import newportfolio12 from './portfolio/tromp-tapa-blanda.webp';
import newportfolio13 from './portfolio/tromp-tapa-blanda.webp';
import newportfolio14 from './portfolio/tromp-tapa-blanda.webp';


import serviceBoxinner1 from './landingPageImages/ebooklp/serviceBoxinner1.svg';
import serviceBoxinner2 from './landingPageImages/ebooklp/serviceBoxinner2.svg';
import serviceBoxinner3 from './landingPageImages/ebooklp/serviceBoxinner3.svg';
import serviceBoxinner4 from './landingPageImages/ebooklp/serviceBoxinner4.svg';
import serviceBoxinner5 from './landingPageImages/ebooklp/serviceBoxinner5.svg';
import serviceBoxinner6 from './landingPageImages/ebooklp/serviceBoxinner6.svg';

import homewhychooseicon1 from './landingPageImages/whychooseicon/icon1.svg';
import homewhychooseicon2 from './landingPageImages/whychooseicon/icon2.svg';
import homewhychooseicon3 from './landingPageImages/whychooseicon/icon3.svg';
import homewhychooseicon4 from './landingPageImages/whychooseicon/icon4.svg';

import CreateAuthorWebsite from './landingPageImages/ghostwriting/CreateAuthorWebsite.svg';
import CompleteManuscriptFormatting from './landingPageImages/ghostwriting/CompleteManuscriptFormatting.svg';
import EditingandProofreading from './landingPageImages/ghostwriting/EditingandProofreading.svg';
import PromotionsMarketing from './landingPageImages/ghostwriting/PromotionsMarketing.svg';
import QuickDeliveries from './landingPageImages/ghostwriting/QuickDeliveries.svg';
import ContinuousAssistance from './landingPageImages/ghostwriting/ContinuousAssistance.svg';
import AffordableGhostwriting from './landingPageImages/ghostwriting/AffordableGhostwriting.svg';
import StayIncognito from './landingPageImages/ghostwriting/StayIncognito.svg';


import GridFictionSecFiction from './landingPageImages/gridfictionsec/Fiction.svg';
import GridFictionSecNonFiction from './landingPageImages/gridfictionsec/NonFiction.svg';
import GridFictionSecbiography from './landingPageImages/gridfictionsec/biography.svg';
import GridFictionSecInformative from './landingPageImages/gridfictionsec/Informative.svg';
import GridFictionSecAutobiography from './landingPageImages/gridfictionsec/Autobiography.svg';
import GridFictionSecMemoir from './landingPageImages/gridfictionsec/Memoir.svg';

import ebookwriting1 from './landingPageImages/ebookwriting/icon1.svg';
import ebookwriting2 from './landingPageImages/ebookwriting/icon2.svg';
import ebookwriting3 from './landingPageImages/ebookwriting/icon3.svg';
import ebookwriting4 from './landingPageImages/ebookwriting/icon4.svg';

import memoirwriting1 from './landingPageImages/memoirwriting/icon1.svg';
import memoirwriting2 from './landingPageImages/memoirwriting/icon2.svg';
import memoirwriting3 from './landingPageImages/memoirwriting/icon3.svg';
import memoirwriting4 from './landingPageImages/memoirwriting/icon4.svg';

import biowriting1 from './landingPageImages/biographywriting/icon1.svg';
import biowriting2 from './landingPageImages/biographywriting/icon2.svg';
import biowriting3 from './landingPageImages/biographywriting/icon3.svg';
import biowriting4 from './landingPageImages/biographywriting/icon4.svg';

import businessbookwriting1 from './landingPageImages/businessbookwriting/icon1.svg';
import businessbookwriting2 from './landingPageImages/businessbookwriting/icon2.svg';
import businessbookwriting3 from './landingPageImages/businessbookwriting/icon3.svg';
import businessbookwriting4 from './landingPageImages/businessbookwriting/icon4.svg';

import nonfictionwriting1 from './landingPageImages/nonfictionwriting/icon1.svg';
import nonfictionwriting2 from './landingPageImages/nonfictionwriting/icon2.svg';
import nonfictionwriting3 from './landingPageImages/nonfictionwriting/icon3.svg';
import nonfictionwriting4 from './landingPageImages/nonfictionwriting/icon4.svg';


import fictionwriting1 from './landingPageImages/fictionwriting/icon1.svg';
import fictionwriting2 from './landingPageImages/fictionwriting/icon2.svg';
import fictionwriting3 from './landingPageImages/fictionwriting/icon3.svg';
import fictionwriting4 from './landingPageImages/fictionwriting/icon4.svg';


import actionwriting1 from './landingPageImages/actionwriting/icon1.svg';
import actionwriting2 from './landingPageImages/actionwriting/icon2.svg';
import actionwriting3 from './landingPageImages/actionwriting/icon3.svg';
import actionwriting4 from './landingPageImages/actionwriting/icon4.svg';

import fantasywriting1 from './landingPageImages/fantasywriting/icon1.svg';
import fantasywriting2 from './landingPageImages/fantasywriting/icon2.svg';
import fantasywriting3 from './landingPageImages/fantasywriting/icon3.svg';
import fantasywriting4 from './landingPageImages/fantasywriting/icon4.svg';

import sciencefictionwriting1 from './landingPageImages/sciencefictionwriting/icon1.svg';
import sciencefictionwriting2 from './landingPageImages/sciencefictionwriting/icon2.svg';
import sciencefictionwriting3 from './landingPageImages/sciencefictionwriting/icon3.svg';
import sciencefictionwriting4 from './landingPageImages/sciencefictionwriting/icon4.svg';

import horrorbookwriting1 from './landingPageImages/horrorbookwriting/icon1.svg';
import horrorbookwriting2 from './landingPageImages/horrorbookwriting/icon2.svg';
import horrorbookwriting3 from './landingPageImages/horrorbookwriting/icon3.svg';
import horrorbookwriting4 from './landingPageImages/horrorbookwriting/icon4.svg';

import dramawriting1 from './landingPageImages/dramawriting/icon1.svg';
import dramawriting2 from './landingPageImages/dramawriting/icon2.svg';
import dramawriting3 from './landingPageImages/dramawriting/icon3.svg';
import dramawriting4 from './landingPageImages/dramawriting/icon4.svg';

import adventurewriting1 from './landingPageImages/adventurewriting/icon1.svg';
import adventurewriting2 from './landingPageImages/adventurewriting/icon2.svg';
import adventurewriting3 from './landingPageImages/adventurewriting/icon3.svg';
import adventurewriting4 from './landingPageImages/adventurewriting/icon4.svg';

import suspensethrillerwriting1 from './landingPageImages/suspensethrillerwriting/icon1.svg';
import suspensethrillerwriting2 from './landingPageImages/suspensethrillerwriting/icon2.svg';
import suspensethrillerwriting3 from './landingPageImages/suspensethrillerwriting/icon3.svg';
import suspensethrillerwriting4 from './landingPageImages/suspensethrillerwriting/icon4.svg';

import romancewriting1 from './landingPageImages/romancewriting/icon1.svg';
import romancewriting2 from './landingPageImages/romancewriting/icon2.svg';
import romancewriting3 from './landingPageImages/romancewriting/icon3.svg';
import romancewriting4 from './landingPageImages/romancewriting/icon4.svg';


import mysterywriting1 from './landingPageImages/mysterywriting/icon1.svg';
import mysterywriting2 from './landingPageImages/mysterywriting/icon2.svg';
import mysterywriting3 from './landingPageImages/mysterywriting/icon3.svg';
import mysterywriting4 from './landingPageImages/mysterywriting/icon4.svg';

import bookeditingservices1 from './landingPageImages/bookeditingservices/icon1.svg';
import bookeditingservices2 from './landingPageImages/bookeditingservices/icon2.svg';
import bookeditingservices3 from './landingPageImages/bookeditingservices/icon3.svg';
import bookeditingservices4 from './landingPageImages/bookeditingservices/icon4.svg';

import bookeditingservicestab1 from './landingPageImages/bookeditingservices/tabsicon1.svg';
import bookeditingservicestab2 from './landingPageImages/bookeditingservices/tabsicon2.svg';
import bookeditingservicestab3 from './landingPageImages/bookeditingservices/tabsicon3.svg';
import bookeditingservicestab4 from './landingPageImages/bookeditingservices/tabsicon4.svg';


import bookformattingservices1 from './landingPageImages/bookformattingservices/icon1.svg';
import bookformattingservices2 from './landingPageImages/bookformattingservices/icon2.svg';
import bookformattingservices3 from './landingPageImages/bookformattingservices/icon3.svg';
import bookformattingservices4 from './landingPageImages/bookformattingservices/icon4.svg';

import bookmarketing1 from './landingPageImages/bookmarketing/icon1.svg';
import bookmarketing2 from './landingPageImages/bookmarketing/icon2.svg';
import bookmarketing3 from './landingPageImages/bookmarketing/icon3.svg';
import bookmarketing4 from './landingPageImages/bookmarketing/icon4.svg';

import bookmarketingtab1 from './landingPageImages/bookmarketing/tabsicon1.svg';
import bookmarketingtab2 from './landingPageImages/bookmarketing/tabsicon2.svg';
import bookmarketingtab3 from './landingPageImages/bookmarketing/tabsicon3.svg';
import bookmarketingtab4 from './landingPageImages/bookmarketing/tabsicon4.svg';

import bookpublishing1 from './landingPageImages/bookpublishing/icon1.svg';
import bookpublishing2 from './landingPageImages/bookpublishing/icon2.svg';
import bookpublishing3 from './landingPageImages/bookpublishing/icon3.svg';
import bookpublishing4 from './landingPageImages/bookpublishing/icon4.svg';

import booksectionanimation from './landingPageImages/bookpublishing/booksectionanimation.svg';


import audiobooknarrators1 from './landingPageImages/audiobooknarrators/icon1.svg';
import audiobooknarrators2 from './landingPageImages/audiobooknarrators/icon2.svg';
import audiobooknarrators3 from './landingPageImages/audiobooknarrators/icon3.svg';
import audiobooknarrators4 from './landingPageImages/audiobooknarrators/icon4.svg';

import authorwebsitedesign1 from './landingPageImages/authorwebsitedesign/icon1.svg';
import authorwebsitedesign2 from './landingPageImages/authorwebsitedesign/icon2.svg';
import authorwebsitedesign3 from './landingPageImages/authorwebsitedesign/icon3.svg';
import authorwebsitedesign4 from './landingPageImages/authorwebsitedesign/icon4.svg';

import childern1 from './landingPageImages/childern/icon1.svg';
import childern2 from './landingPageImages/childern/icon2.svg';
import childern3 from './landingPageImages/childern/icon3.svg';
import childern4 from './landingPageImages/childern/icon4.svg';

import BookPrinting1 from './landingPageImages/BookPrinting/icon1.svg';
import BookPrinting2 from './landingPageImages/BookPrinting/icon2.svg';
import BookPrinting3 from './landingPageImages/BookPrinting/icon3.svg';
import BookPrinting4 from './landingPageImages/BookPrinting/icon4.svg';

import landingPage_cookbookp1 from './landingpage1/cookbook/p1.webp';
import landingPage_cookbookp2 from './landingpage1/cookbook/p2.webp';
import landingPage_cookbookp3 from './landingpage1/cookbook/p3.webp';
import landingPage_cookbookp4 from './landingpage1/cookbook/p4.webp';
import landingPage_cookbookp5 from './landingpage1/cookbook/p5.webp';
import landingPage_cookbookp6 from './landingpage1/cookbook/p6.webp';


import landingPage_cookbookserviceIcon1 from './landingpage1/cookbook/01.webp';
import landingPage_cookbookserviceIcon2 from './landingpage1/cookbook/02.webp';
import landingPage_cookbookserviceIcon3 from './landingpage1/cookbook/03.webp';
import landingPage_cookbookserviceIcon4 from './landingpage1/cookbook/04.webp';
import landingPage_cookbookwhy from './landingpage1/cookbook/why.webp';
import nlogo from './nlogo.png';
import image01 from './01.png';
import image02 from './02.png';
import image03 from './03.png';
import image04  from './04.png';
import image1 from './1.png';
import image11 from './11.png';
import image2 from './2.png';
import image22 from './22.png';
import image3 from './3.png';
import image33 from './33.png';
import image4 from './4.png';
import image44 from './44.png';
import imageBG from './BG.png';
import imageChildLP from './Child-LP.jpg';
import imageLayer13 from './Layer 13.png';
import imageLayer14 from './Layer 14.png';
import imageLayer15 from './Layer 15.png';
import imageLayer16 from './Layer 16.png';
import imageLayer18 from './Layer 18.png';
import imageLayer5 from './Layer 5.png';
import imageLayer91 from './Layer 91.png';
import imageLayer from './Layer.png';
import imagePen from './Pen.png';
import imageAbookAfter from './abook-after.webp';
import imageBook from './abook1.webp';
import abookAfter from './abook-after.webp';
import childpopup from "./childpopup.png";
import abook1webp from './abook1.webp';
import abook2webp from './abook2.webp';
import abook3webp from './abook3.webp';
import abook4webp from './abook4.webp';
import abook5webp from './abook5.webp';
import abook6webp from './abook6.webp';
import about11webp from './about-11.webp';
import about22webp from './about-22.webp';
import about33webp from './about-33.webp';
import about44webp from './about-44.webp';
import aboutafter from './aboutafter.png';
import nform1 from './nform1.png';

import newsec from './newsec.png';
import sectionTalkSec from './section.talk_sec.png';
import books from './books.png';
import sectionbook from './sectionbook.png';
import pro from './new/pro.png';
import testi from './testi.png';
import newbg from './new/newbg.png';
import serviceIcon1 from './new/service-icon.png';
import serviceIcon2 from './new/service-icon2.png';
import serviceIcon3 from './new/service-icon3.png';
import serviceIcon4 from './new/service-icon4.png';
import serviceIcon5 from './new/service-icon5.png';
import serviceIcon6 from './new/service-icon6.png';
import starwebp from './star.webp';
import courtny from './landingpage1/cookbook/courtny.png';

//Landingpage 1

import landingpage1_thankyou from './landingpage1/thank-you.png';

//import newportfolio13 from './portfolio/un-trentino-in-america.webp';
//import newportfolio14 from './portfolio/worldwide-domestic-violence-pandemic-project.webp';
// end portfolio
// landingPage images Ebook End
const Assets={logo,logowhite,BannerHome,aboutcup,serviceIcon,LogoMain,bookSlider,processImage,port1,port2,port3,port4,port5,portArrow,philLeft,trustpilot,google
    ,whychooseicon1,test1,test2,test3,testarrow,trustColor,blog1,blog2,blog3,dmca,ghosBanner,rev1,rev2,rev3,nameicon,emailicon,phoneicon,handBook,connectimage,proImgRes,proImg,proImage,faqimageGirl,
    fictionimg1,fictionimg2,fictionimg3,fictionimg4,fictionimg5,fictionimg6,fictionimg7,fictionimg8,fictionimg9,dramaBookHand,childernBookHand,crimeBookHand,suspenseBookHand,actionBookHand,Adventure,Romance,SciFi,Horror,nonfictionimg1,nonfictionimg2,nonfictionimg3,nonfictionimg4,authorHand,ebookHand,memorieHand,biobanner,bioHand,businessHand,audiobookHand,bookmarketingImage,bookmarketingmobilescreen,bookmarketingImage2,bookformattingHand,girlBgCircle,aboutSec1,aboutSec2,menuIcon,fantasyBook,fictionimg10,LogoMainMobile,popupClose,closeMenu,lpserviceicon1,lpserviceicon2,lpserviceicon3,lpserviceicon4,lpserviceicon5,lpserviceicon6,genresaction,genresadventure,genresdrama,genreshorror,genresnonfiction,genresnonfiction,genresromance,genresscifi,genressuspense,genresnonfantacy,genresactionBook,trustLogo,testBook1,testBook2,testBook3,testBook4,testBook5,ctaCallfixed,ctaChatfixed,ctaFormfixed,genresAdventureBook,genresAdventureBook,genresDramaBook,genresFantasyBook,genresaHorrorBook,genresNonFictionBook,genresRomanceBook,genresSciFiBook,genresSciFiBook,genresSuspenseBook,easterimage,notfound,whatsapp,cards,popupimage,newportfolio2,newportfolio3,newportfolio4,newportfolio5,newportfolio6,newportfolio7,newportfolio8,newportfolio9,newportfolio10,newportfolio11,newportfolio12,bookprinting,bookprintingBook,
    printingIcon1,
    printingIcon2,
    printingIcon3,
    printingIcon4,
    printingIcon5,
    printingIcon6,
    serviceBoxinner1,
    serviceBoxinner2,
    serviceBoxinner3,
    serviceBoxinner4,
    serviceBoxinner5,
    serviceBoxinner6,
    homewhychooseicon1,
    homewhychooseicon2,
    homewhychooseicon3,
    homewhychooseicon4,
    CreateAuthorWebsite,
    CompleteManuscriptFormatting,
    EditingandProofreading,
    PromotionsMarketing,
    QuickDeliveries,
    ContinuousAssistance,
    AffordableGhostwriting,
    StayIncognito,
    GridFictionSecFiction,
    GridFictionSecNonFiction,
    GridFictionSecbiography,
    GridFictionSecInformative,
    GridFictionSecAutobiography,
    GridFictionSecMemoir,
    ebookwriting1,
    ebookwriting2,
    ebookwriting3,
    ebookwriting4,
    memoirwriting1,
    memoirwriting2,
    memoirwriting3,
    memoirwriting4,
    biowriting1,
    biowriting2,
    biowriting3,
    biowriting4,
    businessbookwriting1,
    businessbookwriting2,
    businessbookwriting3,
    businessbookwriting4,
    nonfictionwriting1,
    nonfictionwriting2,
    nonfictionwriting3,
    nonfictionwriting4,
    fictionwriting1,
    fictionwriting2,
    fictionwriting3,
    fictionwriting4,
    actionwriting1,
    actionwriting2,
    actionwriting3,
    actionwriting4,
    fantasywriting1,
    fantasywriting2,
    fantasywriting3,
    fantasywriting4,
    sciencefictionwriting1,
    sciencefictionwriting2,
    sciencefictionwriting3,
    sciencefictionwriting4,
    horrorbookwriting1,
    horrorbookwriting2,
    horrorbookwriting3,
    horrorbookwriting4,
    dramawriting1,
    dramawriting2,
    dramawriting3,
    dramawriting4,
    adventurewriting1,
    adventurewriting2,
    adventurewriting3,
    adventurewriting4,
    suspensethrillerwriting1,
    suspensethrillerwriting2,
    suspensethrillerwriting3,
    suspensethrillerwriting4,
    romancewriting1,
    romancewriting2,
    romancewriting3,
    romancewriting4,
    mysterywriting1,
    mysterywriting2,
    mysterywriting3,
    mysterywriting4,
    bookeditingservices1,
    bookeditingservices2,
    bookeditingservices3,
    bookeditingservices4,
    bookeditingservicestab1,
    bookeditingservicestab2,
    bookeditingservicestab3,
    bookeditingservicestab4,
    bookformattingservices1,
    bookformattingservices2,
    bookformattingservices3,
    bookformattingservices4,
    bookmarketing1,
    bookmarketing2,
    bookmarketing3,
    bookmarketing4,
    bookmarketingtab1,
    bookmarketingtab2,
    bookmarketingtab3,
    bookmarketingtab4,
    bookpublishing1,
    bookpublishing2,
    bookpublishing3,
    bookpublishing4,
    booksectionanimation,
    audiobooknarrators1,
    audiobooknarrators2,
    audiobooknarrators3,
    audiobooknarrators4,
    authorwebsitedesign1,
    authorwebsitedesign2,
    authorwebsitedesign3,
    authorwebsitedesign4,
    childern1,
    childern2,
    childern3,
    childern4,
    nlogo,
    image01,
    image02,
    image03,
    image04,
    image1,
    image11,
    image2,
    image22,
    image3,
    image33,
    image4,
    image44,
    imageBG,
    imageChildLP,
    imageLayer13,
    imageLayer14,
    imageLayer15,
    imageLayer16,
    imageLayer18,
    imageLayer5,
    imageLayer91,
    imageLayer,
    imagePen,
    imageAbookAfter,
    imageBook,
    abookAfter,
    abook1webp,
    abook2webp,
    abook3webp,
    abook4webp,
    abook5webp,
    abook6webp,
    about11webp,
    about22webp,
    about33webp,
    about44webp,
    aboutafter,
    nform1,
    newsec,
    sectionTalkSec,
    books,
    sectionbook,
    pro,
    testi,
    newbg,
    serviceIcon1,
    serviceIcon2,
    serviceIcon3,
    serviceIcon4,
    serviceIcon5,
    serviceIcon6,
    starwebp,
    courtny,
    landingPage_cookbookp1,
    landingPage_cookbookp2,
    landingPage_cookbookp3,
    landingPage_cookbookp4,
    landingPage_cookbookp5,
    landingPage_cookbookp6,
    landingPage_cookbookserviceIcon1,
    landingPage_cookbookserviceIcon2,
    landingPage_cookbookserviceIcon3,
    landingPage_cookbookserviceIcon4,
    landingPage_cookbookwhy,
}
export default Assets;
