import React, { useEffect } from "react"; 
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Girlquote from "../../components/Girlquote";
import Testimonials from "../../components/Testimonials";
import Blogs from "../../components/Blogs";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function Adventure() {
  
  useEffect(() => {
    $("[data-targetit]").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      var target = $(this).data("targetit");
      $("." + target)
        .siblings('[class^="box-"]')
        .hide();
      $("." + target).fadeIn(100);
    });
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
    $(".swiper-button-prev-has").on("click", function () {
      $(".swiper-button-prev").click();
    });
    $(".swiper-button-next-has").on("click", function () {
      $(".swiper-button-next").click();
    });
    $(".prevArrow").on("click", function () {
      $(".testLeftImage .swiper-button-prev").click();
    });
    $(".nextArrow").on("click", function () {
      $(".testLeftImage .swiper-button-next").click();
    });
  }, []);
  const navigate = useNavigate()

//navigate(0)
   


    
  
  return (
    <>
    <Helmet>
      <title>Adventure Book Writing Services | Hire Adventure Writers</title>
    </Helmet>
      <Header/>
      <section className="sec1 innerBanner adventureBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Unfold the Map of Your Imagination With Our Adventure Book Writing Services
                  </h1>
                  <p>
                  Forget the same old, same old boring routines of everyday life. From traffic jams to treadmills, life is too short to miss out on an adventure, be it in real life or in the pages of a book that transports you into the story itself. Our adventure book writing services will pump the adrenalin and make the heart beat faster with their plots and twists into a wild adventure unlike any other. 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Best Adventure Ghost Writers At Your Service </h4>
                <p>
                Your audience is craving an adventure that ignites their sense of wonder. Are you ready to deliver? At Bellevue Publishers, we compose adventurous tales that that make your readers bounce up and down as if riding on the back of a horse like the protagonist in your book. Our expert ghostwriters, masters of the art of storytelling, craft compelling narratives that are brimming with excitement. Rest assured, the sense of adventure for your readers will be satiated to the fullest. </p>

                <p>It is noteworthy that, unlike other genres, adventure ghostwriting isn’t confined to sunshine and happy endings. It combines all flavors of life, thrillers, dangers, fighting evil, surviving the treacherous landscapes that come to eat you out… it’s all about blending all the scenarios into a tale that keeps the readers on their tails. Our writers are experts in creating unforgettable characters, heart-pounding plots, and narratives that grip you tight and leave you yearning for more.
                </p>
                <p>So, leave the ordinary and opt for the best in the industry. Bellevue Publishers is your gateway to the world of adventure writing services that make your book into a best seller. </p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.Adventure} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <Ctafirst Title="Start an epic adventure book with our adventure writing services" Text="Connect with us now to get the best deals! "/>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why {Constant.CompanyName} Offer <br></br>Adventure Writing Services That Are A Class Apart!
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.adventurewriting1}  />
                    </div>
                    <div className="contentChoose">
                      <span>We Ace The Pace Of Epic Adventure Tales </span>
                      <p>
                      At Bellevue Publishers, we understand adventure isn't just a genre; it's a rollercoaster ride that makes your pulse rush with every page. Our stories are crafted to keep your readers glued to the edge of your seat.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.adventurewriting2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Innovative Adventure Book Writing </span>
                      <p>
                      Say goodbye to mundane, boring plots and say hello to adventures that are spellbinding. Our writers can transform rough drafts into spellbinding tales, creating amazing stories with dynamic writing styles that elevate your story to new heights.
                      </p>
                    </div>
                  </div>

                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.adventurewriting3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Quick Deliveries</span>
                      <p>
                      Time is of the essence, 24/7! At Bellevue Publishers, we understand this fully well, and it is our priority to ensure all deliveries make it to their clients before deadlines. If you are short on time and a book to publish, you have come to the right place!
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.adventurewriting4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Specialized In All Genres</span>
                      <p>
                      Whether you want a protagonist who takes the world by himself or an exhilarating tale that takes the readers on an adventure unlike any other, our writers can work on all genres out there. No matter the niche, we create possibilities that tantalize the mind and make it crave more.
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Testimonials/>
      <Blogs/>
      <Ctaorange Title="Start an epic adventure book with our adventure writing services " Text="Connect with us now to get the best deals! "/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">Got a Question? Let's Address Your Queries  </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What are adventure writing services?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our services spread like branches of a tree; we provide multi-dimensional ghostwriting services, which also include adventure writing; this is specified for people who want to explore this genre and tell their stories in an epic, exciting adventure story.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Do people read adventure stories?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Yes, from Little Red Riding Hood to epic adventures like Life of Pie and Harry Potter, they were best-selling novels. Even though the list is too long to put here, adventure stories are widely enjoyed and read by people; often, action-filled adventure books are taken for the big screen and turn into memorable movies later on.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How much does an adventure writing service cost? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Depending on the book's length, any book service can cost anywhere between $20 to $1000 or more. Bellevue Publishers has an affordable range where we make sure to provide flexibility on our rates with no compromise on quality.


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Does Bellevue Publishers provide self-publishing services for books?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, Bellevue Publishers is a full-fledged ghostbook writing service, so we offer self-publishing services to market and promote your books globally. We promote your books through social media marketing and multi-channel platforms to give you a global audience. 


                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Who makes a good adventure writer?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    A ghostwriter is a jack of all trades and knows all genres. However, adventure is a genre that can be categorized into multiple sub-genres like action-adventure, adventure-romance, and adventure-comedy, so you need an expert who knows all the elements of crafting a manuscript that ticks all the boxes for your specific needs.

                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle = "Action Services!"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Adventure;
