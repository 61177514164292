import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Assets from '../../assets/images';
import './styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { Constant } from "../../Constants";

function Popup({className = "StickeyCtaInner" }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const url = window.location.href;
  useEffect(() => {
    function handleScroll() {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 500) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  function handleKeyPress(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  function handleChange(event) {
    setPhoneNumber(event.target.value);
  }
  const location = useLocation();
  const currentUrl = location.pathname;
  const form = useRef();
  const RedirectUrl = function(url){
    window.location.href = "/thankyou";
  }
const sendEmailPopup = (e) => {
  e.preventDefault();
  $("button.btnPrimary"). attr("disabled", true);
  emailjs.sendForm('service_mpjgvt5', 'template_jw2zrfk', form.current, '960vdqz35TukiHxIX')
    .then((result) => {
    
     $('.popupMain').removeClass("show");
     $('.popupBox').removeClass("show");
      $("button.btnPrimary"). attr("disabled", false);
      $('.formFields input,.formFields textarea').val('');
      // Swal.fire({
      //  icon: "success",
      //   title: "We know the world is full of choices. Thank you for choosing us! ",
      //   text : `Be the author of the next best-selling book! {Constant.CompanyName}, just a click away!`
      // })
      RedirectUrl();
    }, (error) => {
    
      $("button.btnPrimary"). attr("disabled", false);
      Swal.fire({
        icon: "error",
        title: "Ooops, something went wrong",
        text: error.text,
      })
    });
};
  const [navClick, setNavClick] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navClick]);
  useEffect(() => {
    $(".openPopup").on("click", function () {
      $('.popupMain').addClass("show");
      setTimeout(function(){ 
        $('.popupBox').addClass('show');
        }, 10);
    });
    $(".popupClose").on("click", function () {
      $('.popupMain').removeClass("show");
      $('.popupBox').removeClass("show");
    });
  }, []);
  return (
    <>
    <div className={`StickyCta ${isScrolled ? 'scrolled' : ''}`}>
      <div className={className}>
        <ul>
          <li><a href="javascript:;" className="openPopup"><img src={Assets.ctaFormfixed} alt="Sticky Cta" /></a></li>
          <li><a href= {Constant.PhoneNumberLink}><img src={Assets.ctaCallfixed} alt="Sticky Cta" /></a></li>
          <li><a href="javascript:;"  className="chat"><img src={Assets.ctaChatfixed} alt="Sticky Cta" /></a></li>
        </ul>
       
      </div>
    </div>
   <div className='popupMain'>
      <div className='popuInner'>
        <div className='popupBox'>
        <div className='popupClose'>
         <img src={Assets.popupClose} alt="Popup Close" />
        </div>
          <div className='popuptitle'>
          <span className='popTitMain'>50% Discount!</span>
            <span className='popTitMainup'>Your Bestselling book is just a click away</span>
            <p>Schedule a call and talk to our book writing experts</p>
          </div>
          <div className='popupForm BannerFields'>
          <form ref={form} onSubmit={sendEmailPopup}>
          <div className="formFields">
                    <img src={Assets.nameicon} alt="Icon" />
                      <input type="text" placeholder="Enter Your Name" name='user_name'  required/>
                    </div> 
                    <div className="formFields">
                    <img src={Assets.emailicon} alt="Icon" />
                      <input type="email" placeholder="Enter Your Email"  name='user_email' required/>
                    </div>
                    <div className="formFields">
                    <img src={Assets.phoneicon} alt="Icon" />
                      <input type="text" placeholder="Your Phone Number" name='user_phone' value={phoneNumber}
      onKeyPress={handleKeyPress}
      onChange={handleChange} required/>
                    </div>
                    <div className="formFields textareapopup">
                    <textarea placeholder="Type your message here" name='user_message'></textarea>
                    </div>
            <div className="formFields FieldButton">
              <button type="submit" className="btnPrimary">
                <span>
                  Submit <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>      
            </div>
            <div className="hidden-fields">
									<input type="hidden" name="fullpageurl" value={url} />
                  <input type="hidden" name="companyinfo" value={Constant.CompanyName} />
								</div>
            </form>
          </div>
        </div>
      </div>
   </div>
   <a className="contact-whatsapp" href={Constant.WhatsAppLink} target="_blank">
   <img src={Assets.whatsapp} alt="whatsapp Images" />
  </a>
   </>
  );
}

export default Popup;
