import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import FooterForm from "../../components/FooterForm";
import { Constant } from "../../Constants";
import { Helmet } from "react-helmet";
function AboutUs() {
  
  return (
    <>
    <Helmet>
      <title>About Us - Bellevue Publishers</title>
    </Helmet>
      <Header />
      <section className="sec1 innerBanner AboutUsBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bannertextLeft">
              <div className="bannerTextInner">
                <h1>About us</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec2 bookContent Aboutsec1Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bookContentmain">
              <h3 className="heading2">From ideation to publication, vision to reality</h3>
              <p>
              Publish your masterpiece with confidence. Our end-to-end publishing services ensure your story is in the hands of the world.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="sec3 aboutSec2Content">
        <div className="container">
          <div className="row">
            <div className="col-md-6 bookContentmain">
              <h3 className="heading2">
                {" "}
                Our story{" "}
              </h3>
              <p>
              Our journey started with a mission to provide a platform for new voices in literature. With a focus on personalized service and quality, we quickly gained a reputation as a trusted partner in publishing. Today, we are proud to be a top player in the industry while still staying true to our roots.{" "}
              </p>
            </div>
            <div className="col-md-6 AboutSecimage">
              <img src={Assets.aboutSec1} alt="Trustpilot Images" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 bookContentmain">
              <h3 className="heading2">
                {" "}
                Our team is our strength.{" "}
              </h3>
              <p>
              At our core, we're masters of the written word. With expertise in a variety of genres, we're confident in our ability to bring any story to life. You won't find any jacks of all trades here - just seasoned experts who live and breathe literature.
              </p>
       
              <ul className="twoCol">
                <li>Mystery</li>
                <li>Horror</li>
                <li>Romance</li>
                <li>Children’s books</li>
                <li>Fantasy</li>
                <li>How-To Guides</li>
                <li>Drama</li>
                <li>Crime</li>
                <li>Biography</li>
                <li>Health</li>
                <li>Travel</li>
                <li>Philosophy</li>
                <li>Science Fiction</li>
                <li>Trilogy</li>
                <li>Adventure</li>
                <li>Art</li>
                <li>Business</li>
              </ul>
              <p>
                <b>
                  And More...
                </b>
              </p>
            </div>
            <div className="col-md-6 AboutSecimage">
              <img src={Assets.aboutSec2} alt="Trustpilot Images" />
            </div>
          </div>
        </div>
      </section>
      <section className="sec2 bookContent Aboutsec1Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bookContentmain">
              <h3 className="heading2">Our mission</h3>
              <p>
              At the heart of our company is a mission to simplify the publishing process while captivating our clients with top-notch editing, proofreading, and publishing services. We strive to make every step of the journey as seamless and enjoyable as possible for our authors.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="sec2 bookContent Aboutsec1Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bookContentmain">
              <h3 className="heading2">Our achievements</h3>
              <p>
              Our reputation in the literary world is largely due to our team of renowned ghostwriters, editors, publishers, and marketers. We guarantee our clients the very best with our team’s unmatched creativity and skill. Moreover, we ensure the highest quality of work by implementing strict layers of quality checks across our team.</p>
              <ul className="twoCol">
                <li><b>268</b><br />Writers</li>
                <li><b>1500+</b><br />Projects Completed</li>
                <li><b>500+</b><br />Active Clients</li>
                <li><b>280+</b><br />Best Sellers</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <FooterForm />
      <Footer />
    </>
  );
}
export default AboutUs;
