import React from "react";
import "./styles.css";
import Assets from "../../assets/images";
function Girlquote() {
  return (
    <div className="connectMainwrap">
    <div className="connectMaininner">
    <img src={Assets.connectimage} alt="Connact Image" />
    <div className="connectCOntent">
        <p>We provide tailored services to accomplish your goals, ensuring your contentment and gratification. We do not just write stories; we bring life to them.
<span>Let’s connect!</span></p>
    </div>
    </div>
</div>
  );
}

export default Girlquote;
