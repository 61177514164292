import React, { useRef,useState,useEffect } from "react"; 
import { useLocation, useNavigate } from 'react-router-dom';
import { Constant } from "../../Constants";
import "./styles.css";
import Header from "../../components/Header";
import $ from "jquery";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import Footer from "../../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faPhone,
  faArrowRight,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

function ContactUs() {
  const [phoneNumber, setPhoneNumber] = useState('');
  
  function handleKeyPress(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  function handleChange(event) {
    setPhoneNumber(event.target.value);
  }
  const location = useLocation();
  const url = window.location.href;
  const currentUrl = location.pathname;

  const form = useRef();
  const RedirectUrl = function(url){
    window.location.href = "/thankyou";
  }
  const sendEmailContact = (e) => {
    e.preventDefault();
    $("button.btnPrimary"). attr("disabled", true);
    emailjs.sendForm('service_mpjgvt5', 'template_jw2zrfk', form.current, '960vdqz35TukiHxIX')
      .then((result) => {
      
        $("button.btnPrimary"). attr("disabled", false);
        $('.formField input,.formField textarea').val('');
        // Swal.fire({
        //  icon: "success",
        //  title: "We know the world is full of choices. Thank you for choosing us! ",
        //  text : `Be the author of the next best-selling book! {Constant.CompanyName}, just a click away!`
        // })
        RedirectUrl();
      }, (error) => {
       
        $("button.btnPrimary"). attr("disabled", false);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        })
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Bellevue Publishers</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner contactUsBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-12 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Contact us
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec2 bookContent Aboutsec1Content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 bookContentmain">
              <h3 className="heading2">
              Need Help? Say Hello
              </h3>
              <p>
              Our support team is ever ready to provide answers to all your <br></br>
questions! Let's tell your story to the world!</p>
            </div>
            <div className="col-md-7 contactLeft">
              <div className="contactFormleftwrp">
              <form ref={form} onSubmit={sendEmailContact}>
                  <div className="formfieldmain halfField">
                    <div className="formField">
                      <input type="text" placeholder="Your Name*" name='user_name'  required/>
                    </div>
                    <div className="formField">
                      <input type="email" placeholder="Email Address*"  name='user_email' required/>
                    </div>
                  </div>
                  <div className="formfieldmain halfField">
                    <div className="formField">
                      <input type="text" placeholder="Phone*" name='user_phone' value={phoneNumber}
      onKeyPress={handleKeyPress}
      onChange={handleChange} required/>
                    </div>
                    <div className="formField">
                      <input type="text" placeholder="What’s this about?"/>
                    </div>
                  </div>
                  <div className="formField">
                  <textarea placeholder="Message" name='user_message'></textarea>  
                    </div>
                    <div className="formFields FieldButton">
                    <button type="submit" className="btnPrimary">
                      <span>
                        Submit <FontAwesomeIcon icon={faArrowRight} />
                      </span>
                    </button>      
                  </div>
                  <div className="hidden-fields">
									<input type="hidden" name="fullpageurl" value={url} />
                  <input type="hidden" name="companyinfo" value={Constant.CompanyName} />
								</div>
                </form>
              </div>
            </div>
            <div className="col-md-5 contactDetRight">
              <div className="conRightDetails">
                <span className="contitle"><FontAwesomeIcon icon={faMapMarker} /> Location</span>
                <ul>
                  <li>{Constant.Address}</li>
                </ul>
              </div>
              <div className="conRightDetails">
                <span className="contitle"><FontAwesomeIcon icon={faPhone} /> Contact info</span>
                <ul>
                  <li>Phone: {Constant.PhoneNumber}</li>
                  <li><a href={Constant.EmailLink}>Email : {Constant.Email}</a></li>
                </ul>
              </div>
              <div className="conRightDetails">
                <span className="contitle"><FontAwesomeIcon icon={faClock} /> Working Hours</span>
                <ul>
                  <li>Mon – Fri: 9:00 AM - 7:00 PM</li>
                  <li>Saturday: 9:00 AM - 6:00 PM</li>
                  <li>Sunday: Closed</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      <div class="contactdetails">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <h4 class="heading2 align-left"><span>Our Nation Wide Locations</span></h4><br />
         </div>
      </div>
   </div>
   <div class="container">
      <div class="row">
         <div class="col-md-4">
            <h3><span class="contitle">Texas</span></h3>
            <p>13785 Research blvd, Suite 125, Austin, TX 78750</p>
         </div>
         <div class="col-md-4">
            <h3><span class="contitle">Connecticut (Head Office)</span></h3>
            <p>157 Church st 19th floor, New haven, CT 06510</p>
         </div>
         <div class="col-md-4">
            <h3><span class="contitle">Florida</span></h3>
            <p>360 Central Avenue Suite 800, St.petersburg, FL 33701</p>
         </div>
      </div>
   </div>
   <div class="container">
      <div class="row">
         <div class="col-md-4">
            <h3><span class="contitle">New York</span></h3>
            <p>14 Wall St, 20th floor, Manhattan, NY 10005</p>
         </div>
         <div class="col-md-4">
            <h3><span class="contitle">New Jersey</span></h3>
            <p>923 Haddonfield Rd Suite 300, Cherry Hill, NJ 08002</p>
         </div>
         <div class="col-md-4">
            <h3><span class="contitle">Pennsylvania</span></h3>
            <p>1650 market street Suite 3600, Philadelphia, PA 19103</p>
         </div>
      </div>
   </div>
</div>
      </section>
      <Footer />
    </>
  );
}
export default ContactUs;
