import React, { useEffect } from "react"; 
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLongArrowAltRight,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Process from "../../components/Process";
import Girlquote from "../../components/Girlquote";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import GridFictionSec from "../../components/GridFictionSec";
import Portfolio from "../../components/Portfolio";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function BookEditing() {
  useEffect(() => {
    $("[data-targetit]").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      var target = $(this).data("targetit");
      $("." + target)
        .siblings('[class^="box-"]')
        .hide();
      $("." + target).fadeIn(100);
    });
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
  const navigate = useNavigate();

//navigate(0)
     
  return (
    <>
      <Helmet>
        <title>Book Editing Services | Book Editors for Hire - Bellevue</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner bookmarketingBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Refine Your Manuscript with Professional Book Editing Services
                  </h1>
                  <p>
                  Enhance your writing with our meticulous editing process that ensures clarity, coherence, and grammatical accuracy. Our expert editors polish your work, elevating it to professional standards. Connect with us now to transform your manuscript into a polished masterpiece! 
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-6 reliable-agencyLeft">
              <div className="abtLeft">
                <h6>See your book on</h6>
                <h4>Your favorite platforms</h4>
                <p>Hiring a professional editor for your book can make all the difference in its success. In fact, studies show that readers are more likely to abandon a book due to poor editing than any other factor. A survey by BookBaby found that 70% of readers expect books to be error-free, and 96% said they would be less likely to read a book with obvious typos and errors. Bellevue Editors are a team of highly skilled professionals who can help take your manuscript to the next level. Our editors have years of experience and have helped authors achieve the maximum potential of their books.


Live Chat Now

(203) 920-4659</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 reliable-agencyRight">
              <img src={Assets.dramaBookHand} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <section className="ghostTabs">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostabsrow">
                    <span>Our Book Editing Services: Setting the Standard</span>
                </div>
                <div className="col-md-6 ghostabsmain">
                    <div className="custom-tab-list-ghost">
                    <ul>
                    <li data-targetit="box-bookpro" className="active">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookeditingservicestab1}  /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Unparalleled Book Editing Expertise </span>
                            <p>Enhance Your Book with Proofreading and Editing</p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-authweb" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookeditingservicestab2}  /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Enhance Your Book with Proofreading and Editing </span>
                            <p>Acquire our high-end editing services for impeccable results and impactful writing. </p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-bookfr" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookeditingservicestab3}  /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Immaculate Copy Editing Services</span>
                            <p>Discover and correct spelling, punctuation, and grammar errors with our diligent book editors.</p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-proofed" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.bookeditingservicestab4}  /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Elevate Your Writing with Line-by-Line Editing</span>
                            <p>Craft a masterpiece that captivates readers with our precision-based editing services.</p>
                        </div>
                        </a>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="col-md-6 ghostdetails">
                <div className="box-bookpro showfirst">
                <div className="tabCont">
                <p>
                Developmental editing is a comprehensive process that focuses on the overall structure, content, and coherence of your book. Our skilled editors provide valuable feedback and suggestions to refine your manuscript, ensuring it flows smoothly and engages readers. It's like having a trusted partner who helps you shape your ideas and bring out the best in your writing.</p>
                  <p>Line editing is the fine-tuning stage of the editing process. Our experienced editors pay close attention to the details, focusing on sentence structure, grammar, punctuation, and word choice. They enhance the flow, clarity, and readability of your writing, making it polished and professional. Line editing gives your book that extra shine, ensuring a seamless reading experience.</p>
                  <p>Copy editing is like giving your manuscript a thorough check-up. Our skilled editors meticulously examine your work, identifying and correcting grammar, spelling, punctuation, and consistency errors. They ensure that your writing is clear, concise, and error-free. Copy editing ensures that your book is polished and professional, leaving a lasting impression on readers.</p>
                  <p>Proofreading is the final polish that your manuscript deserves. Our expert proofreaders carefully review your work, catching any lingering typos, grammar mistakes, or punctuation errors. They ensure that your writing flows smoothly and is free from distractions. With our proofreading service, you can be confident that your book will be error-free and ready for publication.</p>
                </div>
              </div>
              <div className="box-authweb">
                <div className="tabCont">
                <p>
                  When you finish a book, even if you self-edit, you can miss many things, such as; plotholes, character development, loopholes, and syntax errors.     </p>
                  <p>You always need a second opinion or a reader's perspective to point out these errors. As a book proofreading and editing service provider, we help you curate a perfect manuscript with high readability and draws in readers' attention rather than taking it away. Following are some of the key tasks that a copy editor may perform during the copy editing process:    </p>
                  <p><b>Correcting grammar, spelling, and punctuation errors: </b>The copy editor checks the text for grammar, spelling, and punctuation errors and makes necessary corrections.    </p>
                  <p><b>Checking for consistency:</b> The copy editor ensures that the text's language and formatting are consistent throughout, with no discrepancies or contradictions.

                  </p>
                </div>
              </div>
              <div className="box-bookfr">
                <div className="tabCont">
                
                <p>
                When you complete your manuscript, no matter how much you think your manuscripts are error-free, you will find mistakes. A professional editor knows what needs to be corrected right off the bat in the creative cycle. Frequently, writers need assistance uniting all the puzzle pieces when they have a thought for a book or a rough outline. 
Copyeditors take measures to find errors in your manuscript and ensure your manuscripts follow the style guide. If you are an amateur writer, copyediting makes your books seem professional. The editors focus on the following things;  </p>
<p><b>Checking for clarity and readability:</b> The copy editor ensures that the text is clear and easy to read, and makes suggestions for revisions to improve the text's flow and coherence.</p>
<p><b>Adherence to style guides:</b> The copy editor follows the style guide of the publisher or author to ensure that the text adheres to specific guidelines and conventions.
                  </p>
                </div>
              </div>
              <div className="box-proofed">
                <div className="tabCont">
                  <p>Our book editors are top-of-the-line, and their expertise surpasses basic editing mistakes. We use premium tools to identify problems in your content so no human error occurs. </p>
                  <p>Our book editors nit-pick every paragraph, sentence, and punctuation mark to provide you with a manuscript that can be published on all leading platforms and recognized by certified authorities. Our editors do not just proofread manuscripts but they have a structural way of scrutinizing content. They ensure your content is easy to follow and read and does not continue any loopholes in the story. 
</p>
                </div>
              </div>
                </div>
            </div>
        </div>
      </section>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Choose Our Book Editing Services
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.bookeditingservices1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Handpicked Editors</span>
                      <p>
                      At Bellevue Publishers, we value collaboration. You have the freedom to choose from a diverse pool of editors, ensuring the perfect match for your genre and style. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookeditingservices2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Unlimited Revisions</span>
                      <p>
                      Client satisfaction is our priority. We provide multiple revisions until you are delighted with the final result, ensuring a polished manuscript. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookeditingservices3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Proofreading Services </span>
                      <p>
                      Our editors enhance your manuscript's readability while maintaining its credibility. They expertly cite, index, and proofread your book, distinguishing it from amateur work. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.bookeditingservices4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Affordable Book Editing</span>
                      <p>
                      Don't fret about high costs. We offer cost-effective plans, making professional book editing accessible and budget-friendly for our clients. 
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Ctafirst Title="Elevate Your Book with Our Editing Services" Text="Connect with us today to take your book to the next level and explore our unbeatable deals!"/>
      <section className="sec9 philMain ProContent">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ProContentLeft">
              <div className="abtLeft">
                <h4>Discover the Excellence of our End-to-End Editing Service</h4>
                <p>
                Bellevue Publishers offers a comprehensive end-to-end editing service that caters to every aspect of your manuscript. Our team of experienced editors meticulously evaluates your work, focusing on grammar, structure, clarity, and consistency. We polish your prose, enhance readability, and ensure seamless flow throughout the entire book. From developmental editing to line editing, copy editing, and proofreading, our experts leave no stone unturned in perfecting your manuscript. With a keen eye for detail and a commitment to excellence, we transform your raw ideas into refined literary masterpiece. Our collaborative approach allows you to actively participate in the editing process, ensuring that your unique voice and vision shine through. Trust in our expertise, and let us elevate your book to new heights of professionalism and impact. Experience the difference of our end-to-end editing service and embark on a journey towards publishing success. Our services include:
                </p>
                <div className="makesContent">
                  <ul>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Developmental Editing </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Content editing </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Copyediting </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Editing and formatting</li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Line Editing </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Proofreading</li>
                  </ul>
                </div>
                <div className="ctaWrap">
                  <div className="aboutCta">
                    <ButtonPrimary />
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 proconImageLeft">
              <img src={Assets.proImage} alt="Platform Images" />
            </div>
          </div>
        </div>
      </section>
      <GridFictionSec/>
      <Process />
      <Ctaorange Title="Unleash The Full Potential Of Your Manuscript With Our Expert Editing Services. " Text="Reach out to our team of skilled editors."/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">FAQs</span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What types of manuscripts do you accept for editing?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We accept a wide range of manuscripts, including novels, non-fiction books, memoirs, self-help guides, academic papers, and more. Our experienced editors have expertise in various genres and disciplines
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does the editing process usually take?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>The editing timeline depends on the complexity of the project and the specific requirements. Typically, we provide an estimated turnaround time based on the length and scope of the manuscript. Rest assured. We strive to deliver high-quality edits within a reasonable timeframe.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Will my edited manuscript be confidential and secure?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Absolutely. We prioritize the confidentiality and security of our clients' work. Our team members sign non-disclosure agreements, and we have strict protocols in place to safeguard your manuscript. You can trust us to handle your project with the utmost professionalism and respect for your privacy.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl  pageTitle = "Embark on Your Journey to Success"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default BookEditing;
