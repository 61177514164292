import React from "react";
import "./styles.css";
import Assets from "../../assets/images";
import ButtonPrimary from "../../components/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../../Constants";
import { useRef } from 'react';

function Ctaorange({Title = "Discuss Your Project With Our Expert", Text = "Don't Hesitate to connect with us", className = "sec9 ctaOrange" }) {
  

  return (
    <section className={className}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 ctaTitleLeft">
            <span className="heading2 boldheading2">
            {Title}
            </span>
            <p>{Text}</p>
          </div>
          <div className="col-md-6 ctaTitleRight">
            <div className="ctaTitleRightInnerLeft">
              <div className="ctaWrap">
                <div className="aboutCta">
                <a href="javascript:;"  className="ctaLinePhone chat">
                    <FontAwesomeIcon icon={faComment} />
                    <span>Chat With US</span>
                  </a>
                 
                  <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                    <FontAwesomeIcon icon={faPhone} />
                    <span className="smallTitleLine">We will be happy to assist you</span>
                    {Constant.PhoneNumber}
                  </a>
                  <ButtonPrimary />
                </div>
                <div className="ctabtnrightsocial">
                <div className="trustpilotGoogleBox">
                  <div className="reviewBTn">
                  <a href={Constant.TrustPilotLink} target="_blank">
                      <img src={Assets.trustpilot} alt="Trustpilot Images" />
                    </a>
                  </div>
                  <div className="reviewBTn">
                  <a href={Constant.GoogleReviewsLink} target="_blank">
                      <img src={Assets.google} alt="Google Images" />
                    </a>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Ctaorange;
