import React, { useEffect,useState } from "react"; import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLongArrowAltRight,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctafirst from "../../components/Ctafirst";
import Ctaorange from "../../components/Ctaorange";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Process from "../../components/Process";
import Girlquote from "../../components/Girlquote";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import GridFictionSec from "../../components/GridFictionSec";
import Portfolio from "../../components/Portfolio";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";

import { useRef } from 'react';
import { Helmet } from "react-helmet";

function GhostWriting() {
  useEffect(() => {
    $("[data-targetit]").click(function () {
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      var target = $(this).data("targetit");
      $("." + target)
        .siblings('[class^="box-"]')
        .hide();
      $("." + target).fadeIn(100);
    });
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);
     
  return (
    <>
      <Helmet>
        <title>Ghostwriting Services | Best Ghost Writers for Hire</title>
     </Helmet>
      <Header showInnerBanner>
        <div className="container">
          <div className="row">
            <div className="col-md-7 bannertextLeft">
              <div className="bannerTextInner">
                <h1>
                Bellevue Publishers: The Home to Best Wordsmiths for Ghostwriting in the US
                </h1>
                <p>
                Experience the art of storytelling with Bellevue Book Publishers’ exceptional ghostwriting services. Our talented team of writers, with their impeccable literary prowess and creative finesse, will bring your vision to life. From captivating novels to compelling memoirs, trust our expertise to craft masterpieces that resonate with readers. Unlock your story today.
                </p>
                <div className="btnBanner">
                <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                <a href="javascript:;"  className="btnSecondary chat">
                  <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                </a>
               
              </div>
              </div>
            </div>
            <div className="col-md-4 BannerFormRight">
                <div className="bannerFormRightMain">
                    <BannerForm/>
                </div>
            </div>
          </div>
        </div>
        <Clientreviewlinks/>
      </Header>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-6 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Feature Talented Ghost Writers and Enter the Powerful Universe of Words!</h4>
                <p>Discover the hidden power of ghostwriting and its undeniable benefits for authors. Did you know that over 60% of best-selling books use the services of Ghostwriters? With Bellevue Publishers, you gain access to a skilled team of wordsmiths who possess the art of crafting compelling stories. Our ghostwriting services allow you to save valuable time and energy while still having your unique voice shine through.</p>
               <p>Choose Bellevue for our track record of success – we have helped countless authors achieve literary greatness. Join the ranks of celebrated authors, and let us turn your ideas into a masterpiece that resonates with readers worldwide.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 reliable-agencyRight">
              <img src={Assets.handBook} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <section className="ghostTabs">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostabsrow">
                    <span>End-to-End Services in Our Customizable Ghostwriting Package</span>
                </div>
                <div className="col-md-6 ghostabsmain">
                    <div className="custom-tab-list-ghost">
                    <ul>
                    <li data-targetit="box-bookpro" className="active">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.CreateAuthorWebsite} alt="Author Website Creation" /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Building Author’s Website </span>
                            <p>Get a professionally designed, SEO-optimized website to showcase your book across multiple platforms. Our tailored marketing strategies connect you with your target readership.</p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-authweb" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.CompleteManuscriptFormatting} alt="Complete Manuscript Formatting" /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Flawless Manuscript Formatting </span>
                            <p>Formatting made effortless; Bellevue Publishers adhere to industry standards, precisely formatting your book for Amazon KDP, Barnes & Noble, and more.</p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-bookfr" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.EditingandProofreading} alt="Editing and Proofreading" />
                       </div>
                        <div className="tabsContent">
                            <span className="tabTitle">Impeccable Editing and Proofreading</span>
                            <p>Capture readers’ attention with error-free content. Our meticulous editing ensures a polished manuscript, free from grammatical errors and spelling mistakes. </p>
                        </div>
                        </a>
                    </li>
                    <li data-targetit="box-proofed" className="">
                        <a href="javascript:;">
                        <div className="tabsicon"> <img src={Assets.PromotionsMarketing} loop={true} /></div>
                        <div className="tabsContent">
                            <span className="tabTitle">Strategic Promotions & Marketing </span>
                            <p>Unlock the full potential of your book with our expert marketing techniques. We deploy omnichannel strategies to maximize exposure across platforms.</p>
                        </div>
                        </a>
                    </li>
                    </ul>
                </div>
                </div>
                <div className="col-md-6 ghostdetails">
                <div className="box-bookpro showfirst">
                <div className="tabCont">
                  <p>
                  <b>At 10:00 PMT, Cierra and her family arrived in Jamaica.</b>
                  "Wow!" exclaimed Steven, "Jamaica changed so much over the years!" As the family walked outside of the tropical airport, they saw beautiful beaches and hotels outside, they felt the warm air against their skin, and the town was full of people walking and cars honking. As they started walking down the sidewalk, they could see Cierra's mother in the distance.</p>

<p><b>The tears that have fallen out of my eyes surprises even me</b>
...to be truthful. I cried, imagining a past-time soul mate flying to a new European way of life forever, in Denmark... I cried after seeing a beautiful photo of Boomerang while she was someone else’s 3D pleasure. I cried for all the visions I’ve ever contrived in my own mind about the ones I believed in.  </p>

<p><b>But for a psalm to say that God has a record of all of our tears???</b>
I thought to myself that a lot of my life would never make sense to my soul until I am done being mortal. I have been very baffled and bewildered about life because of all the extraordinary experiences I lived through. So, I have thought of death, and my thought of it is that our whole life flashes before our eyes in a way that chronicles everything we’ve felt. But this event (in itself) has no bearing on our idea of time.
                  </p>
                </div>
              </div>
              <div className="box-authweb">
                <div className="tabCont">
                  <p>
                  When we receive your books, they might not look cohesive, and an unformatted manuscript takes the attention of the story away. No matter how well-written a story is, readers are drawn toward how visually pleasing a book is.  </p>

<p>We take care of choosing the best fonts, aesthetic and correct picture allocation, cohesive paragraphs, and an overall organized manuscript. Be it a cookbook or novel; we originate a completely formatted book;  </p>

<p><ul>
  <li>Page Size</li>
  <li>Margins & Spacing </li>
  <li>Font Selection</li>
  <li>Alignment </li>
  <li>Page Bleeding </li>
</ul>
                  </p>
                </div>
              </div>
              <div className="box-bookfr">
                <div className="tabCont">
                  <p>
                  Our ghostwriting services are designed to provide an experience that is on par with the world’s bestsellers.  </p>

<p>Our team of professional ghost writers devises stellar editing and proofreading strategies to make your books look the most professional. Book editing and proofreading is the most crucial part of publishing a manuscript.  </p>

<p>We offer dexterous ghost writers to hire who solve all your editorial errors and make your books publishing-ready and error-free. 
                  </p>
                </div>
              </div>
              <div className="box-proofed">
                <div className="tabCont">
                  <p>
                  We have a few marketing tricks up our sleeves to ensure your books are evenly marketed through omnichannel platforms.  </p>

<p>Spreading your word is our mission; we open global audiences to your stories with our book marketing services.   </p>

<p>Promotion and marketing of your book is a ladder, building your journey of becoming a successful author. When we talk about affordable ghostwriting services, it can often lead to concerns about quality. With us, you do not have to worry about book promotions. 
                  </p>
                  <p>Our marketing experts know how to generate leads and increase organic sales through digital advertising, campaigns, and content strategies. </p>
                </div>
              </div>
                </div>
            </div>
        </div>
      </section>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Why Choose Bellevue Publishers
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.QuickDeliveries} loop={true} />
                    </div>
                    <div className="contentChoose">
                      <span>Timely Deliveries</span>
                      <p>
                      At Bellevue Publishers, we value your time. Our commitment to completing projects ahead of schedule ensures smooth project management, even with tight deadlines or busy schedules.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.ContinuousAssistance}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Unwavering Support  </span>
                      <p>
                      Client satisfaction is our driving force. With Bellevue Bok Publishers, you receive continuous assistance and top-quality work. Your feedback shapes our journey together, guaranteeing a fruitful collaboration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.AffordableGhostwriting}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Accessible Magnificence</span>
                      <p>
                      Reputable publishing and ghostwriting services don't have to be expensive. Bellevue Publishers makes great services affordable for everyone while maintaining the quality of the craft we love.
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.StayIncognito}
                        loop={true}
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Preserve Your Privacy</span>
                      <p>
                      At Bellevue Publishers, your trust is our first priority. Unless you specify differently, we, as a top ghostwriting company in the USA, carefully follow confidentiality policies and protect your personal data. 
                      </p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                  <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <Ctafirst Title="In search of a skilled ghostwriter? Look no further than Bellevue Publishers!" Text="Experience our exceptional services and seize the best deals today!"/>
      <section className="sec9 philMain ProContent">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ProContentLeft">
              <div className="abtLeft">
                <h4>Hire Reasonably Priced Ghostwriters to Leave a Mark.</h4>
                <p>At Bellevue Publishers, protecting your privacy is our top priority. Your name will always be subtly presented on all publishing platforms thanks to our 100% encryption. We are the intermediaries who bring your ideas to life.</p>
                <p>Take a smooth trip through all of the literary and non-fiction genres with us. The possibilities are endless when working with Bellevue Publishers. Your ideas will be turned into best-selling masterpieces thanks to the depth of knowledge and experience on our staff.</p>
                <div className="makesContent">
                  <ul>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Custom illustrations </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Book Publishing </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Creative Ghostwriting </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Impeccable Book Editing </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Comprehensive Book Marketing </li>
                    <li><FontAwesomeIcon icon={faLongArrowAltRight} /> Through Proofreading </li>
                  </ul>
                </div>
                <div className="ctaWrap">
                  <div className="aboutCta">
                    <ButtonPrimary />
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 proconImageLeft">
              <img src={Assets.proImage} alt="Platform Images" />
            </div>
          </div>
        </div>
      </section>
      <GridFictionSec/>
      <Process/>
      <Ctaorange Title="Looking for a professional ghostwriter? Bellevue Publishers has got you covered! " Text="Connect with us now to get the best deals!"/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">At Bellevue Publishers, we are dedicated to providing assistance and answering all your queries. </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is ghostwriting?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Ghostwriting is a professional service where a skilled writer is hired to create content on behalf of someone else. The ghostwriter remains anonymous, allowing the client to take full credit for the work. </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I maintain the originality and authenticity of my voice in ghostwritten content?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>Absolutely! A reputable ghostwriter will work closely with you to capture your unique voice and style. Through interviews, discussions, and collaboration, the ghostwriter ensures that your personality and vision shine through the written work.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does the ghostwriting process typically take?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    The duration depends on the complexity and length of the project. A novel, for example, may take several months, while a shorter piece could be completed within weeks. The timeline will be discussed and agreed upon during the initial consultation to meet your specific needs and deadlines.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Is ghostwriting confidential?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, confidentiality is a crucial aspect of ghostwriting. Professional ghostwriters prioritize client privacy and adhere to strict confidentiality agreements. Your personal information and the details of your project will be kept strictly confidential throughout the process.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
              <CtaGirl pageTitle={"Ghostwriting Services!"}/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
     <FooterForm/>
      <Footer />
    </>
  );
}
export default GhostWriting;
