import React from "react";
import "./styles.css";
import ButtonPrimary from "../../components/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
function GridFictionSec() {
  return (
    <section className="sec4 servicesMainboxes servicesMainboxesInner">
    <div className="container">
      <div className="row">
        <div className="col-md-12 serviceTitle">
          <span className="heading2">A Complete Spectrum of Ghostwriting Services  </span>
          <p>Ghostwriting services do not only stop at writing; first and foremost, it starts with preliminary research where our talented ghost writers brainstorm with their respective teams to do a complete run-down of how your manuscript should look in the end. 
From comprehensive chapter outlines to designing customized book covers, we discuss, communicate, and are extremely well-versed when it comes to ghost writing. We assist you in meeting all your basic requirements in the most hassle-free way. </p>
        </div>
        <div className="col-md-4 servicebox">
          <div className="serviceBoxinner">
            <span className="servTitle">
            Fiction
            </span>
            <div className="sericoText">
              <img src={Assets.GridFictionSecFiction} alt="Fiction"/>
              <p>
              Dwell in the adventurous universe of fictitious story writing.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 servicebox">
          <div className="serviceBoxinner">
            <span className="servTitle">
            Non-Fiction
            </span>
            <div className="sericoText">
              <img src={Assets.GridFictionSecNonFiction} alt="Non-Fiction" />
              <p>
              We craft stories that are a reflection of your life. We convey your stories to the world!
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 servicebox">
          <div className="serviceBoxinner">
            <span className="servTitle">
            Biography
            </span>
            <div className="sericoText">
              <img src={Assets.GridFictionSecbiography} alt="Biography" />
              <p>
              Get in-depth and informative biographies for your target audience
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 servicebox">
          <div className="serviceBoxinner">
            <span className="servTitle">
            Informative
            </span>
            <div className="sericoText">
              <img src={Assets.GridFictionSecInformative} alt="Informative" />
              <p>
              Features stats, graphs, and infographics that our expert's fact-check. 
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 servicebox">
          <div className="serviceBoxinner">
            <span className="servTitle">
            Autobiography
            </span>
            <div className="sericoText">
              <img src={Assets.GridFictionSecAutobiography} alt="Autobiography" />
              <p>
              Our team deals with providing writing services for real-life, self-narrations.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4 servicebox">
          <div className="serviceBoxinner">
            <span className="servTitle">
            Memoir
            </span>
            <div className="sericoText">
              <img src={Assets.GridFictionSecMemoir} alt="Memoir"  />
              <p>
              Feature a heartfelt collection of your favorite memories for your loved ones. 
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12 ctaWrap">
          <div className="aboutCta">
          <ButtonPrimary/>
            <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
              <FontAwesomeIcon icon={faPhone} />
              <span className="smallTitleLine">Call Us</span>
              {Constant.PhoneNumber}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
}

export default GridFictionSec;
