import React, { useEffect } from "react"; 
import { Constant } from "../../Constants";
import Assets from "../../assets/images";
import "./styles.css";
import Header from "../../components/Header";
import Marquee from "react-fast-marquee";
import { useLocation,useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faChevronDown,
  faPhone,
  faLongArrowRight,
  faStar,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { Navigation, Autoplay, EffectFade, Pagination } from "swiper";

import $ from "jquery";
import Footer from "../../components/Footer";
import ButtonPrimary from "../../components/Buttons";
import BrandSlider from "../../components/Section/Brandslider";
import BannerForm from "../../components/BannerForm";
import Ctaorange from "../../components/Ctaorange";
import Clientreviewlinks from "../../components/Clientreviewlinks";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import ReactDOM from 'react-dom';
import Countdown,{zeroPad} from 'react-countdown';
import Girlquote from "../../components/Girlquote";
import Blogs from "../../components/Blogs";
import Testimonials from "../../components/Testimonials";
import CtaGirl from "../../components/CtaGirl";
import FooterForm from "../../components/FooterForm";
import { Helmet } from "react-helmet";
import { useRef } from 'react';

function Audiobook() {
  
  useEffect(() => {
        // Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    //return <Completionist />;
  } else {
    // Render a countdown
    return <span className="countHas"><b><span>{zeroPad(hours)}</span></b>:<b><span>{zeroPad(minutes)}</span></b>:<b><span>{zeroPad(seconds)}</span></b></span>;
  }
};
ReactDOM.render(
  <Countdown
        date={Date.now() + 5000000}
        renderer={renderer}
      />,
      document.getElementById('counthas')
    );
  }, []);
  useEffect(() => {
    $(".accordion__item:first-child").addClass("active");
    $(".accordion__item").on("click", function () {
      $(this).addClass("active").siblings().removeClass("active");;
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Audiobook Services Company | Audiobook Narrators - Bellevue</title>
      </Helmet>
      <Header/>
      <section className="sec1 innerBanner audiobookBanner">
        <div className="container">
            <div className="row">
              <div className="col-md-7 bannertextLeft">
                <div className="bannerTextInner">
                  <h1>
                  Immerse Yourself in the World of Audio Books
                  </h1>
                  <p>
                  Experience the captivating narratives brought to life by Bellevue Book Publishers’ exceptional audiobook services. Our talented narrators and cutting-edge production techniques ensure a truly immersive experience for listeners. From fiction to non-fiction, let your story resonate through the power of audio. Discover the magic of our audiobook services and elevate your literary journey to new heights.
                  </p>
                  <div className="btnBanner">
                  <ButtonPrimary Text="Request A Quote" className="btnPrimary openPopup"/>
                  <a href="javascript:;"  className="btnSecondary chat">
                    <span>Chat Now  <FontAwesomeIcon icon={faComment} /></span>
                  </a>
                 
                </div>
                </div>
              </div>
              <div className="col-md-4 BannerFormRight">
                  <div className="bannerFormRightMain">
                      <BannerForm/>
                  </div>
              </div>
            </div>
          </div>
          <Clientreviewlinks/>
        </section>
      <BrandSlider/>
      <section className="sec9 reliable-agency">
        <div className="container">
          <div className="row">
          <div className="col-md-7 reliable-agencyLeft">
              <div className="abtLeft">
                <h4>Experience the Future of Storytelling with Audiobooks </h4>
                <p>Audiobooks are the immersive audio versions of books that bring stories to life through narration. With the rise of digital platforms and mobile devices, audiobooks have gained tremendous popularity. According to recent stats, audiobook sales have surged by 40% in the past year alone. Audiobook services offer convenience, allowing you to listen on the go, multitask, and explore a vast library of titles. Immerse yourself in captivating narratives with our cutting-edge audiobook services.</p>

                <p>Experience audiobooks at their finest with Bellevue Publishers. Our team of talented narrators and audio production experts ensures a seamless and captivating listening experience. With our cutting-edge technology and attention to detail, we deliver top-notch quality audiobooks that immerse listeners in the power of storytelling. Trust us to bring your words to life in an extraordinary way.</p>
                <div className="ctaWrap">
                  <div className="aboutCta">
                  <ButtonPrimary/>
                    <a href= {Constant.PhoneNumberLink} className="ctaLinePhone">
                      <FontAwesomeIcon icon={faPhone} />
                      <span className="smallTitleLine">Call Us</span>
                      {Constant.PhoneNumber}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5  reliable-agencyRight">
              <img src={Assets.Adventure} alt="HandBook Images" />
            </div>
          </div>
        </div>
      </section>
      <section className="sec13 bookmarketMobile">
        <div className="container">
          <div className="row">
            <div className="col-md-5 bookmarketMobileLeft">
              <h3 className="bookheadingmain">What's New? 
Get Latest Updates 
On Our Published Audiobooks!
</h3>
<p>We offer high quality, informative and cost-friendly self-published audiobooks. Click down below to </p>
<span className="earnText">Earn <b>50%</b> Royalty Rates. </span>
<div id="counthas"></div>
<div className="btnBanner">
                  <ButtonPrimary/>
                </div>
            </div>
            <div className="col-md-7 bookmarketMobileRight">
              <img src={Assets.bookmarketingmobilescreen} alt="Mobile Images" />
            </div>
          </div>
        </div>
      </section>
      <section className="sec12 ghostagecnyContent">
        <div className="container">
            <div className="row">
                <div className="col-md-12 ghostTitleagency">
                    <span className="ghostTitleheading">Unleash the Power of Words, Dive into the World of Audio!
                        </span>
                </div>
                <div className="col-md-8 whychooseRight ghostContentagency">
                <div className="row">
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img src={Assets.audiobooknarrators1}  />
                    </div>
                    <div className="contentChoose">
                      <span>Industry Leaders in Audiobook Services </span>
                      <p>
                      As industry-leading audiobook experts, Bellevue Publishers houses a skilled team of professional writers dedicated to delivering multifaceted audiobook services. Our highly experienced audiobook writers adhere to the standards of cross-platform apps and audiobook sites, ensuring your books soar to new heights. Our comprehensive services include:
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.audiobooknarrators2}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Premium Quality Audiobooks at Affordable Rates </span>
                      <p>
                      Immerse yourself in the finest US-quality audiobooks meticulously produced by our experts. Equipped with high-resolution sound equipment and collaborating with top-tier studio masters, we deliver crisp and exceptional narrations at budget-friendly prices. Enjoy multiple features and unlock exclusive elements of audiobooks without breaking the bank. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 whychooeIconBox">
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.audiobooknarrators3}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Tech-Savvy Downloadable Digital Formats  </span>
                      <p>
                      Experience effortless access and seamless downloads of our audiobooks across major digital platforms. Our services support popular formats such as FLAC, MP3, and WAV, ensuring your audience can easily convert and enjoy them anytime, anywhere. Trust our expertise in format conversions for a hassle-free listening experience. 
                      </p>
                    </div>
                  </div>
                  <div className="whychooeIconBoxInner">
                    <div className="iconChoose">
                      <img
                        src={Assets.audiobooknarrators4}
                        
                      />
                    </div>
                    <div className="contentChoose">
                      <span>Professional Narration and Storytelling Excellence</span>
                      <p>
                      At Bellevue Publishers, we pride ourselves not only on outstanding storytelling but also on crafting captivating narrations. Our team of experts goes the extra mile to provide top-notch audio performances that bring your novels to life. Experience the pinnacle of audio narrations with our dedicated professionals.
                      </p>
                      <p>Choose Bellevue Publishers for unparalleled audiobook services that combine expertise, excellence, and a commitment to your success.</p>
                    </div>
                  </div>
                </div>

              </div>
                </div>
                <div className="col-md-4 connectMain">
                    <Girlquote/>
                </div>
            </div>
        </div>
      </section>
      <section className="sec12 TestiSliderMain">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 whyChooseLeft">
              <div className="abtLeft">
                <span className="smallTitleLine">Testimonials</span>
                <h4>
                What Clients Have To Say About Us
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
          <div className="col-md-12 blogSlider">
            <Swiper
              slidesPerView={3}
              spaceBetween={120}
              autoplay={{
                delay: 6000,
                disableOnInteraction: false,
              }}
              centeredSlides={false}
              loop={true}
              loopFillGroupWithBlank={true}
              navigation={false}
              breakpoints={{
                    320: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
            >
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">SD </span>
                <div className="nameStars">
                  <span className="clientNameTesti">Sarah D.</span>
                  <div className="ratingStar">
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>I was blown away by the quality of the audiobook produced by Bellevue Publishers. The narration was engaging, and the sound quality was superb. Highly recommended!</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">JM</span>
                <div className="nameStars">
                  <span className="clientNameTesti">John M.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>Bellevue Publishers transformed my book into an incredible audiobook. The narrator captured the essence of the story perfectly, and the production value was top-notch. I couldn’t be happier!</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">RA</span>
                <div className="nameStars">
                  <span className="clientNameTesti">Emily L.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>The team at Bellevue Publishers provided exceptional audiobook services. They were professional and efficient and truly brought my story to life. I’m grateful for their expertise and highly recommend their services.</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">SD</span>
                <div className="nameStars">
                  <span className="clientNameTesti">Michael W.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>I had a wonderful experience working with Bellevue Publishers for my audiobook. The narrator’s voice was captivating, and the overall production quality exceeded my expectations. I’m thrilled with the final result!</p>
              </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">LS</span>
                <div className="nameStars">
                  <span className="clientNameTesti">Lisa S.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>Bellevue Publishers did an outstanding job with my audiobook. The narration was clear and expressive, and the attention to detail in the production was impressive. I would definitely work with them again!</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">DH</span>
                <div className="nameStars">
                  <span className="clientNameTesti">David H.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>I can’t thank Bellevue Publishers enough for creating such a fantastic audiobook for my novel. The narrator’s performance was engaging, and the audio quality was impeccable. I highly recommend their audiobook services.</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">JB</span>
                <div className="nameStars">
                  <span className="clientNameTesti">Jessica B.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>Working with Bellevue Publishers was a breeze. They guided me through the entire process and delivered an audiobook that exceeded my expectations. I’m thrilled with the outcome and grateful for their expertise.</p>
              </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="testiMainBox">
                <div className="testinameImage">
                <span className="nameIco">RG</span>
                <div className="nameStars">
                  <span className="clientNameTesti">Robert G.</span>
                  <div className="ratingStar">
                                                      <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                </div>
              </div>
              <div className="blogContent">
                <p>Bellevue Publishers turned my book into an amazing audiobook. The narrator’s voice was soothing, and the production quality was top-notch. I’m proud to have my story available as an audiobook thanks to their excellent services.</p>
              </div>
                </div>
              </SwiperSlide>
              
            </Swiper>

            </div>
          </div>
        </div>
      </section>
      <Blogs/>
      <Ctaorange Title="Bring Your Story to Life with Captivating Audiobook Services!" Text="Connect with us today for exclusive deals and turn your book into an unforgettable audiobook. "/>
      <section className="sec11 faqsMain">
        <div className="container">
          <div className="row">
              <div className="col-md-7"> 
              <div className="faqTitle">
                <span className="faqtitlemain">FAQs </span>
              </div>
              <Accordion preExpanded={['a']}>
            <AccordionItem uuid="a">
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What is an audiobook and how does it work?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    An audiobook is a recorded version of a book that can be listened to instead of read. It works by using professional voice actors to narrate the text, which is then made available in a digital format for easy listening on various devices.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How long does it take to produce an audiobook?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>The production time for an audiobook can vary depending on the length and complexity of the book. On average, it can take several weeks to a few months to complete the recording, editing, and mastering process.</p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    Can I choose the narrator for my audiobook?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    At Bellevue Publishers, we provide a range of talented narrators with different styles and voices. We work closely with you to understand your preferences and match you with a narrator who best suits the tone and theme of your book.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    What formats are audiobooks available in? 
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Audiobooks are available in various formats, including MP3, M4B, and CD. These formats are compatible with most digital devices and can be easily downloaded or streamed for convenient listening.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    How can I distribute and sell my audiobook?
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Bellevue Publishers offers comprehensive distribution services for your audiobook. We can help you make it available on popular platforms like Audible, iTunes, and Amazon. Additionally, we provide marketing support to maximize your sales and reach a wider audience.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>
              </div>
             <CtaGirl  pageTitle = "Embark on Your Journey to Success"/>
          </div>
        </div>
        <div className="servicesMarque">
          <Marquee gradient={false} speed={140}>
            <ul>
              <li>Ghostwriting</li>
              <li>Editing</li>
              <li>Publishing</li>
              <li>Marketing</li>
              <li>Book Review & Consultation</li>
            </ul>
          </Marquee>
        </div>
      </section>
      <FooterForm/>
      <Footer />
    </>
  );
}
export default Audiobook;
